import {useUserFilters} from '../../../UserFiltersContext';
import {Icon, Tooltip} from '@trustle/component-library';
import {formatDateFromNow, formatFullDate} from '../../../../../../../lib';
import React from 'react';
import {UserCountByItem} from '../../../hooks/useUsersCountBy';

type Props = {
  countBy: 'department' | 'role' | 'title';
};

export function useUserCountByColumns({countBy}: Props) {
  const {setFilter} = useUserFilters();

  const handleSelectItem = (item: string) => {
    setFilter(countBy, item);
  };

  return [
    {
      formatter: () => {
        return <Icon type="group" size="sm" />;
      },
      headerStyle: {width: '4em'},
      style: {width: '4em', paddingTop: '20px', paddingBottom: '20px'},
    },
    {
      text: 'Name',
      formatter: (_: any, userCountByItem: UserCountByItem) => {
        return (
          <div className="tr-cursor-pointer" onClick={() => handleSelectItem(userCountByItem.userDetailsValue)}>
            {userCountByItem.userDetailsValue}
          </div>
        );
      },
    },
    {
      text: 'Users Count',
      dataField: 'usersCount',
      formatter: (usersCount: any) => {
        return <div>{usersCount}</div>;
      },
    },
    {
      text: 'Last Activity',
      dataField: 'lastActivity',
      formatter: (lastActivity: string) => {
        return !lastActivity ? (
          <Icon type="emptyData" title="Never" size="sm" />
        ) : (
          <Tooltip position="top" content={formatFullDate(lastActivity)}>
            {formatDateFromNow(lastActivity)}
          </Tooltip>
        );
      },
    },
    {
      formatter: (_: any, userCountByItem: UserCountByItem) => {
        return (
          <Icon
            className="tr-cursor-pointer"
            onClick={() => handleSelectItem(userCountByItem.userDetailsValue)}
            type="chevronRight"
            size="sm"
          />
        );
      },
      headerStyle: {width: '4em'},
      style: {width: '4em'},
    },
  ] as any;
}
