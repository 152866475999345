import React from 'react';
import Header from '../../common/Header';
import SearchBar from './search-bar/SearchBar';
import GeneralAccessRequest from './general-access-request/GeneralAccessRequest';
import SystemTypeList from './available-systems/SystemTypeList';
import Box from './helpers/Box';
import NoAvailableSystems from './helpers/NoAvailableSystems';
import {IfCondition} from '../../common/IfCondition';
import {DataStatus} from '../../common/DataStatus';

const AvailableAccessPage = () => {
  const systems = [1]; // todo fill systems with api
  const isLoading = false;

  if (isLoading) {
    return <DataStatus status="loading" text="Loading..." />;
  }

  const hasSystems = systems.length > 0;
  return (
    <div className="access-page">
      <Header />

      <div className="mx-auto container tr-mb-10 tr-space-y-10">
        <IfCondition condition={hasSystems}>
          <Box>
            <SearchBar />
            <SystemTypeList />
          </Box>
          <Box className="w-1/2">
            <GeneralAccessRequest />
          </Box>
        </IfCondition>

        <IfCondition condition={!hasSystems}>
          <NoAvailableSystems />
        </IfCondition>
      </div>
    </div>
  );
};

export default AvailableAccessPage;
