import {Button, Icon} from '@trustle/component-library';
import React from 'react';
import {ConnectionServiceE} from '../../../../../../types';

type Props = {
  service: ConnectionServiceE;
};
export function DocumentationAction({service}: Props) {
  const documentationLink = SERVICE_TO_LINK[service];

  if (!documentationLink) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      key="documentation"
      className="tr-text-trustle-link tr-text-sm"
      size="xs"
      onClick={() => {
        window.open(documentationLink, '_blank', 'noopener,noreferrer');
      }}
    >
      <span>Documentation</span>
      <Icon type="external" size="sm" />
    </Button>
  );
}

const SERVICE_TO_LINK = {
  [ConnectionServiceE.AZURE_AD]: '/docs/connectors/azure_ad/configure_azure_ad',
  [ConnectionServiceE.AWS]: '/docs/connectors/aws/configure_aws',
  [ConnectionServiceE.GAPPS]: '/docs/connectors/gapps/configure_gapps',
  [ConnectionServiceE.GITHUB]: null,
  [ConnectionServiceE.GENERIC]: '/docs/connectors/universal/csv_guide',
  [ConnectionServiceE.OKTA]: '/docs/connectors/okta/okta_guide',
  [ConnectionServiceE.SLACK]: null,
  [ConnectionServiceE.PAGERDUTY]: '/docs/connectors/pagerduty/configure_pagerduty',
  [ConnectionServiceE.STRONGDM]: null,
  [ConnectionServiceE.GCP]: '/docs/connectors/gcp/configure_gcp',
  [ConnectionServiceE.CLOUDFLARE]: null,
  [ConnectionServiceE.CUSTOM]: null,
  [ConnectionServiceE.CUSTOM_SYSTEM]: null,
  [ConnectionServiceE.JIRA]: null,
  [ConnectionServiceE.TABLEAU]: '/docs/connectors/tableau_setup',
  [ConnectionServiceE.M365]: '/docs/connectors/m365/configure_m365',
  [ConnectionServiceE.AWS_IDENTITY_CENTER]: '/docs/connectors/aws_identity_center/configure_aws_identity_center',
};
