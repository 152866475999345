import {useHistory} from 'react-router-dom';
import {parseObjectToQueryString} from 'src/views/v2/utils';

type Props<T> = {
  path: string;
  defaultParams?: T;
};

export const usePageNavigator = <T>({path, defaultParams = {} as T}: Props<T>) => {
  const history = useHistory();

  const navigate = (pageParams: T) => {
    const queryString = parseObjectToQueryString({...defaultParams, ...pageParams} as Record<string, string>);
    history.push(`${path}?${queryString}`);
  };

  const to = (pageParams: T) => {
    const queryString = parseObjectToQueryString({...defaultParams, ...pageParams} as Record<string, string>);
    return `${path}?${queryString}`;
  };

  return {navigate, to};
};
