import _ from 'lodash';
import React from 'react';
import {ResourceCategoryEnum, AccessStatusE, ConnectionServiceE, ConnectorsDataT} from 'src/types';
import {formatUserName, createdDaysAgo, formatDateFromNow, formatFullDate} from 'src/lib';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {observer} from 'mobx-react';
import {useRootStore} from 'src/lib/hooks';
import {Link} from 'react-router-dom';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {TableSetupEnum} from 'src/stores/userStore';
import UserAccountColumnContent from 'src/components/design/tables/common/UserAccount';
import {AccessExpiration} from 'src/components/design/tables/common/AccessExpiration';
import {AccessStatusIcon, Tooltip, UserList, UserInfo, Table, UserAvatarAndName} from '@trustle/component-library';
import TeamAccountColumnContent from 'src/components/design/tables/common/TeamAccount';
import PermissionInLinkedResource from './PermissionInLinkedResource';
import AccessActions from './AccessActions';
import ShowAuthorizedAccount from 'src/components/design/tables/common/ShowAuthorizedAccount';
import {LastActivityColumn} from 'src/components/design/tables/SharedColumnDefinitions/AccessColumns';
import {ViewEntities} from 'src/connectors/common/types';
import {Permission} from 'src/stores/domainObjects/Permission';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ColumnArrow} from 'src/components/design/tables/SharedColumnDefinitions/UserColumns';

export type AccessBranchPropsT = {
  template: ViewEntities;
  permission?: Permission;
  resource: Resource;
  filteredRecords: AccessRecord[];
  canEditResource?: boolean;
  manage?: string;
};

const AccessTable = observer(function Overview(props: AccessBranchPropsT) {
  const {manage = 'manage', filteredRecords, canEditResource, resource, permission} = props;
  const {retrieveUsageDataEnabled} = resource.rootResource;

  const {tasksStore, usersStore} = useRootStore();

  if (permission?.loadingAccesses || usersStore.loading) {
    return <></>;
  }
  const isUniversalConnector = resource.type === ConnectionServiceE.GENERIC || resource.connector?.service === ConnectionServiceE.GENERIC;
  const accesses = _.orderBy(filteredRecords, ['statusValue'], ['desc']);
  const checkColumnVisibility = (fieldName: string): boolean => {
    let columns = [
      'id',
      'status',
      'email',
      'user+account',
      'account',
      'parent',
      'permission',
      'access-expiration',
      'actions',
    ];

    if (manage === 'unmanaged') {
      columns = [
        'id',
        'email',
        'parent',
        'account',
        'permission',
        'user+account',
        'created',
        'permission.description',
        'actions',
      ];
    }

    return columns.indexOf(fieldName) < 0;
  };
  const ObservableActivity = observer(({accessRecord}: {accessRecord: AccessRecord}) => {
    const accessConnectorData: ConnectorsDataT[] | undefined = accessRecord.forPermission?.accessesConnectorData;
    if (accessConnectorData) {
      const cd = accessConnectorData.find((a) => a.refId === accessRecord.id);
      const lastActivity = (cd?.data as any)?.lastActivity;
      if (lastActivity) {
        return <Tooltip content={formatFullDate(lastActivity)}>{formatDateFromNow(lastActivity)}</Tooltip>;
      }
    }
    return <>-</>;
  });

  const columns: any = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Permission',
    },
    {
      dataField: 'status',
      sort: true,
      hidden: checkColumnVisibility('status'),
      text: '',
      configTitle: 'Status',
      sortValue: (_cell: any, record: AccessRecord) => {
        return record.statusValue;
      },
      headerStyle: {width: '70px'},
      style: {width: '70px'},
      formatter: (_cell: any, record: AccessRecord) => (
        <AccessStatusIcon size="sm" status={record.statusValue} title={record.status} />
      ),
    },
    {
      dataField: 'user+account',
      sort: true,
      text: 'User/Account',
      configTitle: 'User/Account',
      hidden: checkColumnVisibility('user+account'),
      sortValue: (_cell: any, row: AccessRecord) => {
        const access = row;
        return `${access.forUser ? formatUserName(access.forUser) : ''}${
          access.forAccount && access.forAccount.account ? `${access.forAccount.account}` : ''
        }`;
      },
      formatter: (_cell: any, row: AccessRecord) => {
        const access = row;
        const account = row.forAccount;
        return account?.refId ? (
          <TeamAccountColumnContent access={access} />
        ) : (
          <UserInfo
            account={account?.account}
            type={
              access?.statusValue === AccessStatusE.UnlinkedAccount ? 'unlinked' : account?.forUser?.type ?? 'unlinked'
            }
            accountType={account?.accountType}
            authorizedAccount={account?.account && <ShowAuthorizedAccount account={account} iconSize={'md'} />}
            className="tr-text-trustle-link"
          >
            <UserAccountColumnContent account={account} shortVersion={true} />
          </UserInfo>
        );
      },
    },
    {
      dataField: 'last activity',
      text: 'Last activity',
      hidden: resource.type !== ConnectionServiceE.M365,
      formatter: (_unused: any, accessRecord: AccessRecord) => <ObservableActivity accessRecord={accessRecord} />,
    },
    {
      dataField: 'access-expiration',
      sort: true,
      hidden: checkColumnVisibility('access-expiration'),
      text: 'Access Expiration',
      configTitle: 'Access Expiration',
      headerFormatter: (_column: any, _index: number, {sortElement}: any) => {
        const {order = 'x'} = sortElement.props;
        return (
          isUniversalConnector ? (<div className={`sorting ${order}`}>
            Expiration (Approval) <span className={'subtitle'}></span>
            <ColumnArrow order={order} />
          </div>) : (<div className={`sorting ${order}`}>
            Expiration (Access/Approval) <span className={'subtitle'}></span>
            <ColumnArrow order={order} />
          </div>)
        );
      },
      sortValue: (_cell: any, row: AccessRecord) => {
        return row.accessExpiresTs;
      },
      formatter: (_cell: any, row: AccessRecord) => {
        const task = row.getTaskForRecord();
        const firstUser = _.first(task?.assignees);
        if (firstUser) {
          return (
            <Tooltip
              position="top"
              content={
                <UserAvatarAndName displayName={formatUserName(firstUser)} isOwner={firstUser.isOrgOwner} size="xs" />
              }
            >
              Waiting On: <Link to={`/tasks?accessRequestId=${task?.id}`}>{formatUserName(firstUser)}</Link>
            </Tooltip>
          );
        }
        if (row.statusValue === AccessStatusE.UnlinkedAccount) {
          return <>Unlinked/NA</>; // hide expiration date and tasks for non mapped account <=> user
        }

        return task ? (
          <Link to={`/tasks?accessRequestId=${task?.id}`}>Waiting on task</Link>
        ) : (
          <AccessExpiration access={row} />
        );
      },
    },
    {
      dataField: 'created',
      sort: true,
      hidden: checkColumnVisibility('created'),
      text: 'Imported',
      configTitle: 'Imported',
      sortValue: (cell: any) => {
        return cell;
      },
      formatter: (cell: any) => {
        return (
          <Tooltip content={cell} position="left">
            {createdDaysAgo(cell)}
          </Tooltip>
        );
      },
    },
  ];

  if (retrieveUsageDataEnabled) {
    columns.push(LastActivityColumn);
  }

  if (resource.category === ResourceCategoryEnum.REPOSITORY) {
    columns.push({
      dataField: 'members',
      sort: true,
      text: 'Members',
      configTitle: 'Members',
      hidden: checkColumnVisibility('members'),
      sortValue: (_cell: any, row: AccessRecord) => {
        return _.size(row.referencedResource?.allActiveAccessRecords);
      },
      formatter: (_cell: any, row: AccessRecord) => {
        if (!row?.forAccount?.refId) {
          return <></>;
        }

        return (
          <UserList
            users={_.map(usersStore.users, (user) => {
              return _.pick(user, ['id', 'firstname', 'lastname', 'email']);
            })}
            list={_.map(row.referencedResource?.allActiveAccessRecords, (activeAccess) => {
              return activeAccess.forAccount?.forUser?.email ?? '';
            })}
          />
        );
      },
    });

    columns.push({
      dataField: 'permission',
      sort: true,
      text: '',
      configTitle: 'Permission',
      classes: 'ellipsis-overflow',
      hidden: checkColumnVisibility('permission'),
      sortValue: (_cell: any, row: AccessRecord) => {
        return row.forPermission.label;
      },
      formatter: (_cell: any, row: AccessRecord) => {
        if (!row?.forAccount?.refId) {
          return <></>;
        }
        return <PermissionInLinkedResource resource={row.forResource} access={row} />;
      },
    });
  }

  if (canEditResource) {
    columns.push({
      dataField: 'actions',
      classes: 'show-on-hover',
      hidden: checkColumnVisibility('actions'),
      text: '',
      headerStyle: {width: '120px'},
      style: {width: '120px'},
      formatter: (_cell: any, row: AccessRecord) => {
        return <AccessActions accessRecord={row} />;
      },
    });
  }

  const rowClasses = (row: AccessRecord) => {
    return tasksStore.highlightedTasks.includes(row.id) ? 'highlighted-row tr-animate-pulse' : '';
  };

  return (
    <div className="accesses-table" data-testid={`AccessTable${manage}`}>
      <Table
        data={accesses}
        tableKey={`${TableSetupEnum.OVERVIEW}${manage}`}
        tableRid={resource.id}
        striped={false}
        rowClasses={rowClasses}
        columns={columns}
        noDataIndication={<div> No rows to show </div>}
      />
    </div>
  );
});

export default AccessTable;
