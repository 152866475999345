import React, {useState} from 'react';
import axios from 'axios';
import {SubmitButton} from 'src/components/design';
import './AWSConnector.scss';
import {NextStepT} from '../types';
import {AuthenticationProofT, ConnectionT, DurationUnitT, ProvisionMethodE} from '../../types';

import _ from 'lodash';
import {ParsedTestConnectionT, parseResponse, showTestConnection} from './helper';
import {Formik, FormikForm} from 'src/components/design/formik';
import {Button, DurationInput, Loading, PasswordInput, Select} from '@trustle/component-library';
import {Field} from 'formik';
import {useConnectionCredentials} from '../../lib/hooks';

type ConnectionInfoT = {
  authenticationProofs: AuthenticationProofT[];
  connection: ConnectionT;
  connectionInfo?: ConnectionInfoT;
};

type UAKSetupPropsT = {
  orgName?: string;
  onComplete: NextStepT;
  connectionInfo: ConnectionInfoT;
};

type UAKFieldsT = {
  accessKeyId: string;
  secretAccessKey: string;
  accessKeyRotation: {
    durationValue: number;
    durationUnit: DurationUnitT;
  };
  region: string;
};

function UAKSetup(props: UAKSetupPropsT) {
  const {connection} = props.connectionInfo;
  const [resultTestConnection, setResultTestConnection] = useState<ParsedTestConnectionT>();
  const [testingConnection, setTestingConnection] = useState<boolean>(false);
  const {credentials, testConnection: testConnectionAPI} = useConnectionCredentials();

  async function submitUAK(params: UAKFieldsT) {
    const {accessKeyId, secretAccessKey, accessKeyRotation, region} = params;
    const {data: responseData} = await credentials(connection.id, {
      secretAccessKey,
      accessKeyId,
      region,
    });

    const parsedResponse = await parseResponse(responseData);
    if (parsedResponse.ok) {
      const {data} = await axios.post(`/api/connect/${connection.id}/setup/complete`, {
        provisionMethod: ProvisionMethodE.manual,
        pollDurationUnit: 'hour',
        pollDurationValue: 24,
        accessKeyRotationValue: accessKeyRotation.durationValue,
        accessKeyRotationUnit: accessKeyRotation.durationUnit,
      });
      props.onComplete({path: `/resource/manage/${data.systemId}`});
    }
    setResultTestConnection(parsedResponse);
  }

  async function testConnection(params: {accessKeyId: string; secretAccessKey: string; region: string}) {
    const {accessKeyId, secretAccessKey, region} = params;
    setTestingConnection(true);
    const {data: _responseData} = await testConnectionAPI(connection.id, {
      secretAccessKey,
      accessKeyId,
      region,
    });
    const paredResponse = await parseResponse(_responseData);
    setResultTestConnection(paredResponse);
    setTestingConnection(false);
  }

  const isTestConnectionBtnEnabled = (params: {accessKeyId: string; secretAccessKey: string}) => {
    const {accessKeyId, secretAccessKey} = params;
    return !_.isEmpty(accessKeyId) && !_.isEmpty(secretAccessKey);
  };

  return (
    <div className="tr-py-4">
      <div className="tr-flex tr-justify-between tr-items-center tr-pb-1">
        <div className="tr-flex tr-items-center">
          <div className="tr-p-2">
            <h2>API Key configuration</h2>
            <div className="tr-mb-4">Trustle uses OAuth to import and provision access to your AWS account.</div>
            <small>
              <a href="https://learn.trustle.com/tutorial/installing-aws" rel="noopener noreferrer" target="_blank">
                Guide:Setting up an AWS Connector
              </a>
            </small>
          </div>
        </div>
      </div>
      <hr />
      <div className="tr-ml-3">
        <Formik
          initialValues={{
            accessKeyId: '',
            secretAccessKey: '',
            accessKeyRotation: {
              durationValue: 3,
              durationUnit: 'month',
            },
            region: 'us-east-1',
          }}
          onSubmit={async (values: any) => {
            void submitUAK(values);
          }}
        >
          {({values, isSubmitting, errors}) => (
            <FormikForm>
              <div className="tr-flex tr-flex-col">
                <Field
                  component={PasswordInput}
                  className="tr-w-1/2"
                  label="Enter the access key ID for the Trustle Connector"
                  showreveal={true}
                  placeholder="Enter access key here"
                  name="accessKeyId"
                  required
                />

                <Field
                  component={PasswordInput}
                  label="Secret key"
                  className="tr-w-1/2"
                  showreveal={true}
                  placeholder="Enter the secret access key"
                  name="secretAccessKey"
                  required
                />
                <Field
                  component={Select}
                  label="Partition"
                  options={[
                    {label: 'AWS Public', value: 'us-east-1'},
                    {label: 'AWS GovCloud', value: 'us-gov-east-1'},
                  ]}
                  className="tr-w-1/2 tr-mb-4"
                  defaultValue="us-east-1"
                  name="region"
                  key="region"
                  required
                />
              </div>
              <div className="tr-p-0 tr-w-full">
                {!_.isNil(resultTestConnection) && !resultTestConnection.ok && !testingConnection ? (
                  <div className="text-danger body6 text-md tr-text-red-500 alert alert-danger">
                    <span className="body5">Error - No connection </span>
                    <div className="mr-4 body5">{showTestConnection(resultTestConnection)}</div>
                  </div>
                ) : (
                  <></>
                )}
                {resultTestConnection?.ok && (
                  <div className="body6 text-md text-success alert alert-success">
                    {showTestConnection(resultTestConnection)}
                  </div>
                )}
                {testingConnection && <Loading>Testing Connection...</Loading>}
              </div>
              <hr />
              <div className="tr-flex tr-flex-col">
                <div className="tr-pb-7">
                  <Field
                    component={DurationInput}
                    name="accessKeyRotation"
                    label="Trustle Connector key rotation"
                    required
                    includeNoneOption={false}
                    description="Prompt me to rotate the Trustle access key in:"
                  />
                </div>

                <div>
                  <div className="text-right">
                    <span className="mr-4">
                      <Button
                        name="edit-connection-save"
                        type="button"
                        data-testid="test-connection"
                        disabled={!isTestConnectionBtnEnabled(_.pick(values, ['accessKeyId', 'secretAccessKey']))}
                        onClick={async () =>
                          await testConnection(_.pick(values, ['accessKeyId', 'secretAccessKey', 'region']))
                        }
                      >
                        {'Test Connection'}
                      </Button>
                    </span>
                    <SubmitButton
                      disabled={
                        isSubmitting ||
                        _.isNil(resultTestConnection) ||
                        (resultTestConnection && !resultTestConnection.ok) ||
                        !_.isEmpty(errors)
                      }
                      label="Submit"
                      inSubmit={isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default UAKSetup;
