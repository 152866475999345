import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import _ from 'lodash';
import {AccountTabTypeE} from 'src/views/Resource/Account/ResourceAccounts';
import { DocumentationTable } from '@trustle/component-library';
import {optionalAccountsMappingColumns, requiredAccountsMappingColumns, exampleDataCSVAccountsMapping} from '../../components/UniversalDataUpload/helper';

function AccountsCSVMappingGuide() {

  const tabs: TabsDocsT = [
    {id: 'overview', label: 'Overview', description: overview(), active: true},
    {id: 'considerations', label: 'Considerations and scenarios', description: considerations(), active: false},
  ];

  function overview(): JSX.Element {
    return (
      <div>
        <p className="tr-w-75">
          In the context of a connector, accounts imported from a remote system into Trustle that are not yet linked to a Trustle user can be manually linked using a CSV file.
        </p>
        <div className={'border-bottom pt-3 mb-2'}>
          <p className={'mb-1'}>
            <strong>Column Definitions</strong>
          </p>
        </div>

        <DocumentationTable
          requiredColumns={requiredAccountsMappingColumns}
          optionalColumns={optionalAccountsMappingColumns}
          data={exampleDataCSVAccountsMapping}
        />

        <p className="pb-3 tr-w-75">
          <div className={'border-bottom pt-3 mb-2'}>
            <p className={'mb-1'}>
              <strong>Steps to Link Accounts</strong>
            </p>
          </div>
          <div className="pl-4">
            <li>
              Go to <strong>Manage</strong> {'>'} Select your connector {'>'} Move to the <strong>Accounts</strong> tab.
            </li>
            <li>
              Select the <strong>{_.startCase(AccountTabTypeE.UNASSIGNED)}</strong> accounts tab.
            </li>
            <li>
              Click on <strong>Bulk Linking</strong> button.
            </li>
            <div className="my-4 image-wrap">
              <img src="/docs/csv/map_accounts_screen.png" className="vertical-align mb-4 mt-3 shadow overflow-auto" />
            </div>
            <li>Download the example.</li>
            <li>Review and edit the downloaded file, if needed.</li>
            <li>Upload the file in the current modal.</li>
            <li>Correct the errors, if any, and re-upload it as many times as needed.</li>
            <li>
              Finally, save the changes. Your linked accounts will be moved to the list in the{' '}
              <strong>{_.startCase(AccountTabTypeE.ASSIGNED)}</strong> accounts tab.
            </li>
          </div>
        </p>
      </div>
    );
  }

  function considerations(): JSX.Element {
    return (
      <div>
        <p className="pb-3 tr-w-75">
          The CSV file can be automatically generated based on the unlinked accounts in your connector by downloading the example from the upload modal. You can then review and edit it before uploading.
        </p>
        <ul className="pl-4">
          <li>
            If Trustle can retrieve additional attributes for an account from the remote system, the <strong>First Name</strong> and <strong>Last Name</strong> columns will be pre-filled. Otherwise, placeholder values will be used, and these fields will need to be manually edited.
          </li>
          <li>
            The <strong>Account</strong> column must match one of the names in the Accounts list under the <strong>{AccountTabTypeE.UNASSIGNED}</strong> tab. If not, the entire row will be ignored during file processing.
          </li>
          <li>
            If Trustle identifies a potential match between unlinked accounts and existing Trustle users in your organization, the system will suggest the best match and populate the <strong>Trustle User</strong> column. Otherwise, Trustle will suggest a new username for the user that will be created upon uploading the file.
          </li>
          <li>
            After uploading and processing the file, and before confirming the changes, Trustle will display the number of rows ready to be saved and those that will be ignored, along with the reasons. The system will also notify you of any errors related to the file format or specific rows.
          </li>
        </ul>
      </div>
    );
  }

  return (
    <>
      <DocSection tabs={tabs} title="Guide: How to link accounts to users through CSV file upload" />
    </>
  );
}

export default AccountsCSVMappingGuide;
