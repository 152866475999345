import {Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {Account} from '../../../hooks/useAccounts';
import AWSAuthorizedAccount from './AWSAuthorizedAccount';

type Props = {
  account: Account;
  systemId: string;
};

// copied from `frontend/src/components/design/tables/common/UserAccount.tsx`
function TrustleUserAndTooltip(props: Props) {
  const {account, systemId} = props;

  function getUserAccountPopup(): ReactNode {
    return (
      <div className="pl-2">
        <div className="tr-text-sm">
          <b>User: </b> {formatUserNameAndEmail(account.userName, account.userEmail)}
        </div>
        <div className="tr-text-sm">
          <b>Account: </b>
          {account.account}
          {
            <span className="inline-flex-align ml-1">
              <AWSAuthorizedAccount account={account} systemId={systemId} />
            </span>
          }
        </div>
      </div>
    );
  }

  return (
    <div>
      {!_.isNil(account.userId) && (
        <Tooltip content={getUserAccountPopup()} position="bottom" size="lg">
          <Link to={`/users/${account.userId}`}>
            <p className="tr-truncate tr-m-0">{account.userName} </p>
          </Link>
        </Tooltip>
      )}
    </div>
  );
}

function formatUserNameAndEmail(userName: string, userEmail?: string): string {
  return _.compact([userName, `(${userEmail})`]).join(' ');
}

export default TrustleUserAndTooltip;
