import {useAccountFilters} from '../../AccountsFiltersContext';
import {AccountsTable} from '../accounts-table/AccountsTable';
import React from 'react';
import {LinkedAccountsView} from './LinkedAccountsView';
import {UnlinkedAccountsView} from './UnlinkedAccountsView';

export function AccountsView() {
  const {filters} = useAccountFilters();
  const {linkStatus} = filters;

  return (
    <div>
      {linkStatus === 'linked' && <LinkedAccountsView />}
      {linkStatus === 'unlinked' && <UnlinkedAccountsView />}
      <AccountsTable />
    </div>
  );
}
