import {Button, Modal, TextAreaInput, TextInput} from '@trustle/component-library';
import axios from 'axios';
import {Field, Formik} from 'formik';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import {SubmitButton} from 'src/components/design';
import {FormikForm} from 'src/components/design/formik';
import {formatUserName} from 'src/lib';
import {useRootStore} from 'src/lib/hooks';
import './ContactModal.scss';

type ModalPropsT = {
  onHide: () => void;
  show: boolean;
};

const ContactModal = observer(({onHide, ...rest}: ModalPropsT) => {
  const {addToast} = useToasts();
  const {currentUser, org} = useRootStore();

  if (_.isNil(currentUser) || _.isNil(org)) {
    return <></>;
  }

  const [isValid, setIsValid] = useState<boolean>(true);
  const [submitNow, setSubmitNow] = useState<boolean>(true);

  return (
    <Modal width="md" visible={rest.show} onClose={onHide}>
      <div className="bla tr-flex flex-column">
        <div className="m-4 tr-flex flex-column align-items-center">
          <img src="/plans/contact_us.svg" className="tr-flex my-2" height={'200px'} />
          <span className="h3 font-weight-bold">Contact Us</span>
        </div>
        <div className="contact-form">
          <Formik
            initialValues={{
              fullName: formatUserName(currentUser) || '',
              email: currentUser.email || '',
              company: _.upperFirst(org.name) || '',
              message: "I'm interested in Trustle's Entreprise plan, let's discuss options",
            }}
            validate={(values: any) => {
              const errors: _.Dictionary<string | undefined> = {};

              if (_.isEmpty(values.fullName)) {
                errors.fullName = 'This field is required';
              }
              if (_.isEmpty(values.email)) {
                errors.email = 'This field is required';
              }
              if (_.isEmpty(values.company)) {
                errors.company = 'This field is required';
              }
              if (_.isEmpty(values.message)) {
                errors.message = 'This field is required';
              }

              setIsValid(_.isEmpty(errors));
              return errors;
            }}
            onSubmit={async (values: any) => {
              await axios.post('/api/orgs/contact', values);

              addToast('Your message was successfully sent, we will reply as soon as possible', {
                appearance: 'success',
                autoDismiss: true,
              });
              onHide();
            }}
          >
            {({handleSubmit, isSubmitting, submitForm}) => (
              <FormikForm onSubmit={handleSubmit}>
                <div className="tr-w-full tr-flex flex-column align-items-center justify-content-center">
                  <Field component={TextInput} label="Name" name="fullName" id="fullName" required />
                  <Field component={TextInput} label="Email" name="email" id="email" required />
                  <Field component={TextInput} label="Company" name="company" id="company" required />
                  <Field
                    component={TextAreaInput}
                    style={{height: '107px', width: '390px'}}
                    label="Message"
                    id="message"
                    name="message"
                    placeholder=""
                    required={false}
                  />
                </div>
                <div className="m-3 tr-flex justify-content-end">
                  <Button className="mx-3" variant="secondary" onClick={onHide}>
                    Cancel
                  </Button>
                  <SubmitButton
                    label="Send"
                    disabled={isSubmitting || !isValid}
                    onClick={() => {
                      if (!submitNow) {
                        setSubmitNow(true);
                        void submitForm();
                      }
                    }}
                  />
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
});

export default ContactModal;
