import React, {ReactNode} from 'react';
import {Layout, Responsive, WidthProvider} from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './Dashboard.css';
import {Icon} from '@trustle/component-library';
import {resolveWidget} from './widgets';

const ResponsiveGridLayout = WidthProvider(Responsive);
const DRAG_HANDLE_CLASS_NAME = 'drag-handle';

const DEFAULT_LAYOUT = {
  lg: [
    {i: 'users-widget', x: 0, y: 0, w: 12, h: 2},
    {i: 'teams-widget', x: 0, y: 0, w: 6, h: 4},
  ],
};

export function DashboardCardWidget({children}: {children: ReactNode}) {
  return (
    <div className="tr-w-full tr-h-full dashboard-panel tr-border-1 tr-border-solid tr-border-[#E2E8F0] tr-rounded-lg tr-bg-[#FFFFFF] tr-p-4">
      {children}
    </div>
  );
}

export function Dashboard() {
  const handleDragStart = (
    _layout: Layout[],
    _oldItem: Layout,
    _newItem: Layout,
    _placeholder: Layout,
    _event: MouseEvent,
    element: HTMLElement
  ) => {
    const dragHandle = element.querySelector('.dashboard-panel');
    if (dragHandle) {
      dragHandle.classList.add('dragging');
    }
  };

  const handleDragStop = (
    _layout: Layout[],
    _oldItem: Layout,
    _newItem: Layout,
    _placeholder: Layout,
    _event: MouseEvent,
    element: HTMLElement
  ) => {
    const dragHandle = element.querySelector('.dashboard-panel');
    if (dragHandle) {
      dragHandle.classList.remove('dragging');
    }
  };

  return (
    <main className="container tr-my-8">
      <h1>Dashboard</h1>
      <section>
        <ResponsiveGridLayout
          className="layout"
          layouts={DEFAULT_LAYOUT}
          breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
          draggableHandle={`.${DRAG_HANDLE_CLASS_NAME}`}
          rowHeight={100}
          onDragStart={handleDragStart}
          onDragStop={handleDragStop}
          margin={[20, 20]}
        >
          {DEFAULT_LAYOUT.lg.map((item: any) => (
            <div key={item.i} className="tr-absolute">
              <div className="tr-relative tr-float-right tr-top-2 tr-right-0.5 tr-z-10 hover:tr-cursor-grab active:tr-cursor-grabbing">
                <Icon type="draggable" className={`${DRAG_HANDLE_CLASS_NAME}`} />
              </div>
              <DashboardCardWidget>{resolveWidget(item.i)}</DashboardCardWidget>
            </div>
          ))}
        </ResponsiveGridLayout>
      </section>
    </main>
  );
}
