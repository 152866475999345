import {useState} from 'react';
import axios from 'axios';
import {OrganizationSummaryParams} from '../types';
import {useRootStore} from '../../../../../lib/hooks';
import {parseObjectToQueryString} from 'src/views/v2/utils';
export type UsersSummaryResponse = {
  activeUserCount: number;
  inactiveUserCount: number;
  disabledUserCount: number;
  offboardedUserCount: number;
  employeeCount: number;
  contractorCount: number;
  customerCount: number;
  employeeUserCount?: number;
  contractorUserCount?: number;
  customerUserCount?: number;
  systemUserCount: number;
  unlinkedUserCount: number;
};

export function useUsersSummary() {
  const [data, setData] = useState<UsersSummaryResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {toast} = useRootStore();

  const BASE_URL = '/api/v2/users_summary';

  const getUsersSummary = async (params: OrganizationSummaryParams) => {
    const queryString = parseObjectToQueryString(params);

    setLoading(true);
    try {
      const response = await axios.get<UsersSummaryResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      setError(true);
      toast.error('There was an error trying to get users summary');
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, getUsersSummary};
}
