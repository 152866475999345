import {Icon} from '@trustle/component-library';
import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {ConnectionServiceE} from 'src/types';
import OverviewItem from 'src/views/v2/common/OverviewItem';
import {DashboardCardWidget} from 'src/views/v2/dashboard/Dashboard';
import {WidgetHeader} from 'src/views/v2/dashboard/widgets/shared/WidgetHeader';
import {useAccountsResourcesRecomendations} from '../hooks/useAccountsResourcesRecomendations';
import {SystemSummaryResponse} from '../hooks/useSystemSummary';

type Props = {
  resourceId: string;
  summary: SystemSummaryResponse;
  connectorService?: ConnectionServiceE;
};

export function AccountResourcesOverview({resourceId, summary, connectorService}: Props) {
  const RESOURCE_PATH = `/resource/manage/${resourceId}`;

  const {ACCOUNTS_ITEMS_COUNTS, RESOURCES_ITEMS_COUNTS} = useAccountsResourcesRecomendations(
    resourceId,
    connectorService
  );

  const [activeTab, setActiveTab] = useState<'accounts' | 'resources'>('accounts');

  const items = activeTab === 'accounts' ? ACCOUNTS_ITEMS_COUNTS : RESOURCES_ITEMS_COUNTS;

  const linkTo =
    activeTab === 'accounts'
      ? `${RESOURCE_PATH}/recommendations/accounts/Accounts Detected`
      : `${RESOURCE_PATH}/recommendations/system/System Recommendations`;

  return (
    <div className="tr-flex tr-flex-col tr-gap-4">
      <DashboardCardWidget>
        <div className="tr-flex align-items-center rounded-2xl tr-p-3 tr-gap-4">
          <OverviewItem
            label="Accounts"
            value={summary.system_accounts}
            onClick={() => {
              setActiveTab('accounts');
            }}
            selected={activeTab === 'accounts'}
          />
          <OverviewItem
            label="Resources"
            value={10}
            onClick={() => {
              setActiveTab('resources');
            }}
            selected={activeTab === 'resources'}
          />
        </div>
      </DashboardCardWidget>
      <div className="tr-h-full">
        <DashboardCardWidget>
          <div className="tr-flex tr-flex-col tr-gap-4">
            <WidgetHeader
              title={
                <div className="tr-flex tr-items-center tr-gap-2">
                  <Icon type={activeTab === 'accounts' ? 'groupPeople' : 'resource'} size="lg" />{' '}
                  <span>{activeTab === 'accounts' ? 'Accounts' : 'Resources'}</span>
                </div>
              }
              linkTo={linkTo}
              linkText="See All Recommendations"
              linkAsButton
            />
            <p className="tr-text-sm tr-text-gray-500 tr-mb-0">
              Select detection below to see more information and take action
            </p>
            <table className="tr-w-full tr-text-sm tr-text-left tr-text-gray-500">
              <tbody>
                {items.map(({label, count, navigateFunction, icon}) => (
                  <tr
                    key={label}
                    className="tr-bg-white tr-whitespace-nowrap hover:tr-bg-[#f8f9fc]"
                    style={{borderBottom: '1px solid #e0e0e0'}}
                  >
                    <td className="tr-flex tr-items-center tr-gap-2 tr-p-3">
                      {icon}
                      <span className="tr-whitespace-nowrap">{label}</span>
                    </td>
                    <td className="tr-p-3">{count}</td>
                    <td className="tr-p-3">
                      <Button variant="link" size="sm" onClick={navigateFunction}>
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </DashboardCardWidget>
      </div>
    </div>
  );
}
