import axios from 'axios';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import Import from '../Import';
import {
  CSVContent,
  requiredAccountColumns,
  optionalAccountColumns,
  requiredResPermColumns,
  optionalResPermColumns,
  parseArray,
  parseDate,
  parseMetadata,
  UploadedEntityType,
  exampleDataCSVAccounts,
  exampleDataCSVResourcesAndPermissions,
  requiredAccessColumns,
  optionalAccessColumns,
  exampleDataCSVAccess,
  requiredAccountsMappingColumns,
  optionalAccountsMappingColumns,
  exampleDataCSVAccountsMapping, requiredUsersImportColumns, optionalImportUsersColumns, exampleDataCSVImportUsers,
} from './helper';
import {Alert, Button, Modal, DocumentationTable} from '@trustle/component-library';
import {Resource} from 'src/stores/domainObjects/Resource';
import {CSVProcessedRows} from './CSVProcessedRows';
import {logger} from '../../lib';
import {useRootStore} from '../../lib/hooks';

type UploadProps = {
  csvContent: CSVContent;
  resource: Resource;
  onClose: () => void;
};

export const UploadModal: React.ElementType = (props: UploadProps) => {
  const {addToast} = useToasts();
  const {csvContent, resource, onClose} = props;
  const {accountsStore} = useRootStore();
  const [rows, setRows] = useState<any[]>();
  const [warnings, setWarnings] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [ready, setReady] = useState<boolean>(false);
  const [data, setData] = useState<_.Dictionary<any>>({});
  const [filename, setFilename] = useState<string>('');

  const setError = (message: any) => {
    setReady(false);
    setErrorMessage(message);
  };

  const getRowNum = (message: string) => {
    try {
      const i = _.indexOf(message, '[');
      const j = _.indexOf(message, ']');

      if (i === -1 || j === -1) {
        return undefined;
      }
      return _.toNumber(_.join(_.slice(message, i + 1, j), ''));
    } catch {
      return undefined;
    }
  };

  const getError = (message: string) => {
    try {
      const splitted = _.split(message, '].') || [];
      return _.replace(splitted[1], '"', '');
    } catch {
      return undefined;
    }
  };

  const parseMessage = (message: any) => {
    const defaultMessage = 'An error occurred while uploading';

    if (_.includes(message, '__parsed_extra')) {
      // message is: "entities[n].__parsed_extra" is not allowed
      const n = getRowNum(message);
      return !_.isNil(n) ? `Extra data columns provided ${n ? `on row #${n + 1}.` : '.'}` : defaultMessage;
    } else if (_.isMatch(message, /\[[0-9]\]/)) {
      // e.g. 1: "[0].accountType" must be one of [personal, service]
      // e.g. 2: "[11].trustleUser" is not allowed to be empty
      const n = getRowNum(message);
      const m = getError(message);

      return !_.isNil(n) || !_.isEmpty(m) ? `Error on row ${n ? `#${n + 1}` : ''}${m ? `: ${m}` : ''}` : defaultMessage;
    }
  };

  const onPreUpload = async () => {
    if (csvContent === CSVContent.ACCOUNT_MAPPING) {
      try {
        const {data} = await axios.post(`/api/accounts/csv_mapping/${resource.id}`, rows);
        const [unmapped, mapped] = _.partition(data, (row) => row.error);

        if (!_.isEmpty(mapped)) {
          addToast(`${_.size(mapped) === 1 ? '1 row' : `${_.size(mapped)} rows`} successfully uploaded`, {
            appearance: 'success',
            autoDismiss: true,
          });

          _.each(mapped, (item) => {
            const {uaid: id, updAccount} = item;
            accountsStore.updateAccountFromServer({id, ...updAccount});
          });
        }

        _.each(unmapped, (item: {account: any; error: boolean; message: string}) => {
          const warning = `${item.account}: ${item.message}`;
          addToast(warning, {appearance: 'warning', autoDismiss: false});
        });

        onClose();
      } catch (err: any) {
        const message = err?.response?.data?.error?.message;

        addToast(parseMessage(message), {appearance: 'error', autoDismiss: false});
        logger.error(err);
        onClose();
      }
    } else {
      try {
        const {data} = await axios.post(`/api/connect/${resource.connectionId}/compare_upload`, {
          entities: rows,
          content: csvContent,
        });
        setData(data);
        if (data.errors && data.errors.length > 0) {
          setError(data.errors.join(', '));
        }
      } catch (err: any) {
        handleUploadError(err);
      }
    }
  };

  const handleUploadError = (err: any) => {
    setReady(false);
    let message = err?.response?.data?.error?.message;

    if (_.includes(message, '__parsed_extra')) {
      let n;
      try {
        n = _.slice(_.split(message, 'entities')[1], 1, 2);
      } catch {
        n = undefined;
      }
      message = `Extra data columns provided ${n ? `on row #${n}.` : '.'}`;
    }

    setError(message || 'There has been an error while uploading the data.');
  };

  const onUpload = async (disableDeletionFor: any) => {
    if (csvContent === CSVContent.ACCOUNT_MAPPING) {
      try {
        const {data} = await axios.post(`/api/accounts/csv_mapping/${resource.id}`, rows);
        const [unmapped, mapped] = _.partition(data, (row) => row.error);

        if (!_.isEmpty(mapped)) {
          addToast(`${_.size(mapped) === 1 ? '1 row' : `${_.size(mapped)} rows`} successfully uploaded`, {
            appearance: 'success',
            autoDismiss: true,
          });

          _.each(mapped, (item) => {
            const {uaid: id, updAccount} = item;
            accountsStore.updateAccountFromServer({id, ...updAccount});
          });
        }

        _.each(unmapped, (item: {account: any; error: boolean; message: string}) => {
          const warning = `${item.account}: ${item.message}`;
          addToast(warning, {appearance: 'warning', autoDismiss: false});
        });

        onClose();
      } catch (err: any) {
        const message = err?.response?.data?.error?.message;

        addToast(parseMessage(message), {appearance: 'error', autoDismiss: false});
        logger.error(err);
        onClose();
      }
    } else {
      if (!ready) {
        return;
      }
      try {
        const {additions = [], updates = [], unchanged = []} = data || {};
        let entities = [...additions, ...unchanged, ...updates];

        if (csvContent === CSVContent.ACCESS_RECORD) {
          entities = _.map(entities, (e: any) => {
            return e.originalData;
          });
        }

        const result = await axios.post(`/api/connect/${resource.connectionId}/upload_data`, {
          entities,
          options: {disableDeletionFor},
        });
        const message = result.status === 200 ? 'Successfully uploaded' : 'An error occurred while uploading';
        const appearance = result.status === 200 ? 'success' : 'error';

        addToast(message, {appearance, autoDismiss: true});
        window.setTimeout(() => location.reload(), 1000);
        onClose();
      } catch (err: any) {
        handleUploadError(err);
      }
    }

  };

  const handleData = async (uploadedData: _.Dictionary<any>, filename: string) => {
    setError(undefined);
    setFilename(filename);

    try {
      setError(undefined);
      setWarnings([]);

      if (_.isEmpty(uploadedData)) {
        setReady(false);
        setError('No data uploaded.');
        return;
      }
      await validateAndSetRows(uploadedData);
      setReady(true);
    } catch (err: any) {
      setReady(false);
    }
  };

  useEffect(() => {
    async function onRowsSet() {
      await onPreUpload();
      setReady(true);
    }

    if (!_.isEmpty(rows)) {
      void onRowsSet();
    }
  }, [rows]);

  async function validateAndSetRows(data: _.Dictionary<any>) {
    const errors: string[] = [];
    const warn: string[] = [];

    // Special handling for ACCOUNT_MAPPING
    if (csvContent === CSVContent.ACCOUNT_MAPPING) {
      const csvKeys = _.map(Object.keys(data[0]), (col) => {
        return _.replace(col, /_/g, '');
      });

      const requiredColumns = ['firstName', 'lastName', 'account', 'trustleUser'];
      const missingRequiredColumns = _.filter(requiredColumns, (col) => !_.includes(csvKeys, col));

      if (!_.isEmpty(missingRequiredColumns)) {
        setError(`Missing required column(s): ${missingRequiredColumns.join(', ')}`);
        throw new Error(`Missing required columns`);
      }

      const validatedData = _.compact(
        _.map(data, (row, index) => {
          const trimmedRow = _.mapValues(row, (v) => _.trim(_.toString(v)));
          const {account, firstName, lastName, trustleUser, accountType} = trimmedRow;

          if (_.isEmpty(account) || _.isEmpty(trustleUser)) {
            errors.push(`Row ${index + 1}: 'account' and 'trustleUser' are required fields`);
            return undefined;
          }

          if (_.isEmpty(firstName) || _.isEmpty(lastName)) {
            errors.push(`Row ${index + 1}: 'firstName' and 'lastName' are required fields`);
            return undefined;
          }

          if (accountType && !_.includes(['personal', 'service'], _.toLower(accountType))) {
            errors.push(`Row ${index + 1}: 'accountType' must be either 'personal' or 'service'`);
            return undefined;
          }

          return {
            firstName,
            lastName,
            account,
            trustleUser,
            accountType: _.toLower(accountType || 'personal'), // Default to personal if not specified
          };
        })
      );

      if (!_.isEmpty(errors)) {
        setError(errors.join('\n'));
        throw new Error('Validation failed');
      }

      if (_.isEmpty(validatedData)) {
        setError('No valid accounts uploaded.');
        throw new Error('No valid accounts uploaded.');
      }

      setRows(validatedData);
      return;
    }

    const eventType = csvContent === CSVContent.ACCESS_RECORD ? 'account' : csvContent?.toString();

    type ValidatedRow = {
      id?: string;
      type: string;
      permissions?: string[];
      resource?: string;
      deletedDate?: string;
      createdDate?: string;
      metadata?: Record<string, any>;
    };

    const validatedData: ValidatedRow[] = _.map(
      _.filter(data, (r) => {
        return !!r.id || r.id === 0;
      }),
      (row: any) => {
        const trimmedRow = _.mapValues(row, (v) => _.trim(v));
        const isAccountOrAccess = eventType === CSVContent.ACCOUNT || eventType === CSVContent.ACCESS_RECORD;
        const isResOrPerm = eventType === CSVContent.RESOURCE_PERMISSION;
        trimmedRow.type = _.toLower(trimmedRow.type || eventType);

        if (csvContent && trimmedRow.type) {
          if (
            (isAccountOrAccess && trimmedRow.type !== UploadedEntityType.ACCOUNT) ||
            (isResOrPerm && !_.includes([UploadedEntityType.RESOURCE, UploadedEntityType.PERMISSION], trimmedRow.type))
          ) {
            setError(`Unallowed operation: '${trimmedRow.type}' entity type cannot be uploaded in this step`);
            throw errors;
          }
        }

        const allowedColumns =
          trimmedRow.type === UploadedEntityType.ACCOUNT
            ? ['id', 'name', 'type', 'subtype', 'firstName', 'lastName', 'email', 'userType', 'status', 'metadata']
            : ['id', 'name', 'type', 'subtype', 'firstName', 'lastName', 'email', 'userType', 'description', 'status', 'resource', 'permissions', 'metadata'];

        _.forEach(trimmedRow, (value, key) => {
          if (!allowedColumns.includes(key)) {
            warn.push(`'${key}' column on ${trimmedRow.type} rows will be ignored`);
            trimmedRow[key] = '';
          }
        });

        return {
          ...trimmedRow,
          id: _.toString(trimmedRow.id),
          createdDate: parseDate(trimmedRow, trimmedRow.createdDate, setError),
          deletedDate: parseDate(trimmedRow, trimmedRow.deletedDate, setError),
          metadata: parseMetadata(trimmedRow, setError),
          type: trimmedRow.type,
          resource: !_.isNil(trimmedRow.resource) ? _.toString(trimmedRow.resource) : undefined,
          permissions: parseArray(trimmedRow, trimmedRow.permissions, setError),
        };
      }
    );

    for (const row of Object.values(data)) {
      if (_.some(row) && !row.id && row.id !== 0) {
        errors.push(`Missing ID for row with name: ${row.name}`);
      }
    }

    const processed = _.groupBy(
      _.map(validatedData, (row) => {
        return { idByType: row.type.concat('#').concat(row.id as string), id: row.id };
      }),
      'idByType'
    );

    for (const grouped of Object.values(processed)) {
      if (_.size(grouped) > 1) {
        setError(`IDs must be set and unique: '${grouped[0].id}' repeated ${_.size(grouped)} times.`);
        throw errors;
      }
    }

    const ignoredColMsg = (columnName: string, type: string) => {
      return `'${columnName}' column on ${type} rows will be ignored`;
    };

    for (const row of validatedData) {
      const isEventOrResource = _.includes([UploadedEntityType.EVENT, UploadedEntityType.RESOURCE], row.type);
      const isEventAccountOrResource = _.includes(
        [UploadedEntityType.EVENT, UploadedEntityType.ACCOUNT, UploadedEntityType.RESOURCE],
        row.type
      );

      if (isEventOrResource) {
        if (!_.isEmpty(row.permissions)) {
          warn.push(ignoredColMsg('permissions', row.type));
          row.permissions = undefined;
        }
      }

      if (isEventAccountOrResource) {
        if (!_.isEmpty(row.resource)) {
          warn.push(ignoredColMsg('resource', row.type));
          row.resource = undefined;
        }
      }

      if (row.type === UploadedEntityType.EVENT) {
        if (!_.isEmpty(row.id)) {
          warn.push(ignoredColMsg('id', row.type));
          row.id = undefined;
        }
        if (!_.isEmpty(row.deletedDate)) {
          warn.push(ignoredColMsg('deletedDate', row.type));
          row.deletedDate = undefined;
        }
      }
    }

    setWarnings(_.uniq(warn));
    setRows(validatedData.concat((rows || []).filter((r) => !_.find(validatedData, { id: r.id }))));
    if (errors.length > 0) {
      setError(errors.join(', '));
    }
  }

  const title =
    csvContent === CSVContent.RESOURCE_PERMISSION
      ? 'Resources and Permissions'
      : csvContent === CSVContent.ACCESS_RECORD
      ? 'Accesses'
      : csvContent === CSVContent.ACCOUNT
      ? 'Accounts'
      : csvContent === CSVContent.ACCOUNT_MAPPING
      ? 'Account Mappings'
      : _.startCase(_.toString(csvContent)).concat('s');

  return (
    <Modal title={!ready ? `Upload ${title}` : 'Ready to Upload'} width={'xl'} onClose={onClose}>
      <>
        {!_.isEmpty(warnings) && (
          <Alert colorVariant={'warning'} onClose={() => setWarnings([])}>
            <div>
              {warnings.map((message) => (
                <div key={message}>{message}</div>
              ))}
            </div>
          </Alert>
        )}
        {errorMessage && (
          <Alert colorVariant={'danger'} onClose={() => setError(undefined)}>
            {errorMessage}
          </Alert>
        )}
        {!ready && (
          <div>
            <a
              href={csvContent === CSVContent.ACCOUNT_MAPPING ? '/docs/accounts/mapping/csv_guide' : CSVContent.IMPORT_USERS ? '/docs/admin/users/csv_guide' : '/docs/connectors/universal/csv_guide'}
              rel="noopener noreferrer"
              target="_blank"
              className="text-underline"
            >
              <p style={{fontSize: 'small'}}>Documentation</p>
            </a>
            <div className={'border-bottom mb-3'}>
              <div className="mb-4">
                <div className={'border-bottom tr-flex tr-justify-between my-2'}>
                  <p className={'mb-1'}>
                    <strong>Example CSV Structure:</strong>
                  </p>
                  <a href={`/api/connect/generic/download_example?content=${csvContent}`} className="link" download>
                    {'Download example'}
                  </a>
                </div>
                {(csvContent === CSVContent.ACCOUNT) && (<DocumentationTable
                  requiredColumns={requiredAccountColumns}
                  optionalColumns={optionalAccountColumns}
                  data={exampleDataCSVAccounts}
                />)}
                {(csvContent === CSVContent.RESOURCE_PERMISSION) && (<DocumentationTable
                  requiredColumns={requiredResPermColumns}
                  optionalColumns={optionalResPermColumns}
                  data={exampleDataCSVResourcesAndPermissions}
                />)}
                {(csvContent === CSVContent.ACCESS_RECORD) && (<DocumentationTable
                  requiredColumns={requiredAccessColumns}
                  optionalColumns={optionalAccessColumns}
                  data={exampleDataCSVAccess}
                />)}
                {(csvContent === CSVContent.ACCOUNT_MAPPING) && (<DocumentationTable
                  requiredColumns={requiredAccountsMappingColumns}
                  optionalColumns={optionalAccountsMappingColumns}
                  data={exampleDataCSVAccountsMapping}
                />)}
                {(csvContent === CSVContent.IMPORT_USERS) && (<DocumentationTable
                  requiredColumns={requiredUsersImportColumns}
                  optionalColumns={optionalImportUsersColumns}
                  data={exampleDataCSVImportUsers}
                />)}
              </div>
            </div>
          </div>
        )}
        <div>
          {ready && !_.isEmpty(data) ? (
            <CSVProcessedRows
              onClose={() => {
                setError(undefined);
                setWarnings([]);
                setRows([]);
                setReady(false);
                setData({});
              }}
              onConfirm={onUpload}
              syncInProgress={false}
              csvContent={csvContent}
              data={data}
              filename={filename}
            />
          ) : (
            <div>
              <Import handleUpload={handleData} handleError={setError} headerTransform={'camelcase'} />
              <div className="d-flex justify-content-between mt-5">
                <Button
                  className="btn btn-secondary font-weight-bold"
                  onClick={() => {
                    setRows([]);
                    setWarnings([]);
                    setError(undefined);
                    setData({});
                    setFilename('');
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </>
    </Modal>
  );
};
