import React, {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import {observer} from 'mobx-react';
import './EditConnectionAWS.scss';
import ConnectorStatus from 'src/components/StatusIndicator';
import axios from 'axios';
import _ from 'lodash';
import FontAwesomeIcon, {faCircleNotch} from 'src/components/FontAwesomeIcon';
import {ParsedTestConnectionT, parseResponse} from 'src/connectors/aws/helper';
import ShowUserAccount from './ShowUserAccount';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Button, PasswordInput, Select} from '@trustle/component-library';
import {Formik, FormikForm} from 'src/components/design/formik';
import {object, string} from 'yup';
import {Field} from 'formik';
import {useToasts} from 'react-toast-notifications';
import {useConnectionCredentials} from '../../../lib/hooks';

/**
 * Form for update the access key and secret key of AWS connection
 * Allow to test the connection and show errors
 *
 * @param props
 * @returns
 */
export default observer(function EditConnectionAWS(props: {resource: Resource}) {
  const {resource} = props;
  const {addToast} = useToasts();

  const [resultTestConnection, setResultTestConnection] = useState<ParsedTestConnectionT>();
  const [testingConnection, setTestingConnection] = useState<boolean>(false);
  const [updatingKeys, setUpdatingKeys] = useState<boolean>(false);

  const lastImport = resource.connector?.lastImport;
  const [connectorStatus, setConnectorStatus] = useState<string | undefined>(lastImport?.status);
  const {credentials, testConnection: testConnectionAPI} = useConnectionCredentials();

  async function testConnection(values: {accessKeyId: string; secretKey: string; region: string}) {
    setTestingConnection(true);
    setConnectorStatus('pending');
    const {data: responseData} = await testConnectionAPI(resource.connectionId, {
      accessKeyId: values.accessKeyId,
      secretAccessKey: values.secretKey,
      region: values.region,
    });
    if (!responseData.ok) {
      setConnectorStatus('failed');
      addToast(responseData.error.message ?? 'Connection failed', {appearance: 'error', autoDismiss: false});
    } else {
      setConnectorStatus('finished');
      addToast('Succesfully connected', {appearance: 'success', autoDismiss: true});
    }

    setResultTestConnection(await parseResponse(responseData));

    await axios.post(`/api/connect/${resource.connectionId}/start_import`);
    setTestingConnection(false);
  }

  async function submitConnection(values: {accessKeyId: string; secretKey: string; region: string}, formikArgs: any) {
    const {data: responseData} = await credentials(resource.connectionId, {
      secretAccessKey: values.secretKey,
      accessKeyId: values.accessKeyId,
      region: values.region,
    });

    if (resource.connector && responseData && responseData.authzOwner) {
      resource.connector.authzOwner = responseData.authzOwner;
      const parsedResponseData = await parseResponse(responseData);
      setResultTestConnection(parsedResponseData);
    }
    setUpdatingKeys(false);
    formikArgs.resetForm();
  }
  return (
    <>
      <div className="edit-connection-aws pb-7 ">
        <Formik
          key={`newResourceaccount`}
          initialValues={{accessKeyId: '', secretKey: '', region: 'us-east-1'}}
          onSubmit={submitConnection}
          validationSchema={object().shape({
            accessKeyId: string().required(),
            secretKey: string().required(),
            region: string().required(),
          })}
        >
          {({values, isSubmitting, dirty}) => {
            return (
              <FormikForm>
                <div className="tr-flex tr-flex-wrap">
                  <div className="tr-pl-0 tr-flex tr-justify-between">
                    <div className="tr-my-2">
                      <div className="test-box tr-flex tr-items-center">
                        <div className="tr-grid tr-grid-cols-3 tr-px-4 tr-items-center tr-pb-2">
                          <div className="tr-col-span-2">
                            <div className="tr-my-2 tr-inline-flex">
                              <ConnectorStatus className="tr-mr-3 tr-ml-0" status={connectorStatus}></ConnectorStatus>
                              <span className="body4">{resource.name}</span>
                            </div>
                            <div>
                              <ShowUserAccount authzOwner={resource.connector?.authzOwner} className="body6" />
                            </div>
                          </div>

                          <div className="tr-justify-self-end">
                            <Button
                              variant="secondary"
                              data-testid="updatekeys"
                              disabled={testingConnection || updatingKeys}
                              onClick={async () => {
                                setUpdatingKeys(true);
                                setResultTestConnection(undefined);
                              }}
                            >
                              Update keys &gt;
                            </Button>
                            <Button
                              variant="secondary"
                              data-testid="test-id-button"
                              disabled={!(values.accessKeyId && values.secretKey) || testingConnection}
                              onClick={async () => {
                                await testConnection(values);
                              }}
                            >
                              {testingConnection ? (
                                <>
                                  Testing <FontAwesomeIcon icon={faCircleNotch} spin size="lg" />
                                </>
                              ) : (
                                'Test'
                              )}
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="item tr-flex tr-justify-between tr-mt-4 tr-mx-4">
                        {!updatingKeys ? (
                          <></>
                        ) : (
                          <>
                            <h3>Access key for Trustle connector</h3>
                            <Button
                              variant="ternary"
                              onClick={() => {
                                setUpdatingKeys(false);
                              }}
                            >
                              Close
                            </Button>
                          </>
                        )}
                      </div>
                      {updatingKeys && (
                        <div className="no-padding tr-mx-4">
                          <Field
                            component={PasswordInput}
                            label="Access Key"
                            placeholder="Enter access key here"
                            showreveal={true}
                            name="accessKeyId"
                            key="accessKey"
                            required
                            className="tr-w-full"
                          />
                          <Field
                            component={PasswordInput}
                            label="Secret Key"
                            showreveal={true}
                            placeholder="Enter secret key here"
                            name="secretKey"
                            key="secretKey"
                            required
                            className="tr-w-full"
                          />
                          <Field
                            component={Select}
                            label="Partition"
                            options={[
                              {label: 'AWS Public', value: 'us-east-1'},
                              {label: 'AWS GovCloud', value: 'us-gov-east-1'},
                            ]}
                            className="tr-mr-6"
                            defaultValue="us-east-1"
                            name="region"
                            key="region"
                            required
                          />

                          <div className="body5 tr-mt-4">
                            <a
                              href="https://docs.aws.amazon.com/en_en/general/latest/gr/aws-sec-cred-types.html#access-keys-and-secret-access-keys"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="tr-text-underline">Learn how to generate new keys</span>
                            </a>
                          </div>
                          <div className="tr-text-right">
                            <SubmitButton
                              name="edit-connection-save"
                              data-testid="edit-connection-save"
                              disabled={
                                !dirty ||
                                isSubmitting ||
                                _.isNil(resultTestConnection) ||
                                (resultTestConnection && !resultTestConnection.ok)
                              }
                              label="Save Keys"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </>
  );
});
