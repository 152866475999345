import React, {useEffect, useState} from 'react';
import {Button, Icon, IconButton, Loading, Modal, TileButton, UserAvatarAndName} from '@trustle/component-library';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {TeamMembersPanel} from './MemberSection';
import {formatFullDate, formatUserName} from 'src/lib';
import TeamForm from './TeamForm';
import {useRootStore} from 'src/lib/hooks';
import axios from 'axios';
import {TeamT, TeamTypeEnum, UserT} from 'src/types';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import {groupBy} from 'lodash';
import _ from 'lodash';

export function TeamDetails(props: {team?: TeamT}) {
  const history = useHistory();
  const {id} = useParams() as {id: string};
  const {usersStore, teamStore} = useRootStore();
  const [teamSummary, setTeamSummary] = useState<any>(props.team);
  const [editingTeam, setEditingTeam] = useState<boolean>(false);

  async function loadSummary() {
    const {data} = await axios.get(`/api/v2/teams/${id}/summary`);
    const team = {...data, id};
    setTeamSummary(team);
    teamStore.persistTeamInStore(team);
  }

  useEffect(() => {
    void loadSummary();
  }, []);

  if (!teamSummary) {
    return <Loading />;
  }

  const groupedByType = groupBy(teamSummary.systems, 'service');
  const teamColor = teamSummary?.color ?? '';

  return (
    <div className="tr-mb-8">
      <section id="header">
        <div className="tr-bg-trustle-royal tr-pt-4 tr-flex  align-items-center tr-text-white tr-h-32">
          <div className="container tr-flex align-items-center">
            <div className="tr-flex">
              <Icon type="team" size="xl" className="tr-opacity-100" />
              <div className="tr-flex tr-my-0 tr-ml-4 tr-flex-col ">
                <h1 className="trustle-header-text">Teams</h1>
                <p className="body6 tr-max-w-[450px]">Team management page</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="tr-flex tr-items-center tr-gap-3 tr-bg-[#F8F9FC] tr-py-3 tr-px-2.5 tr-my-3">
          <Icon type={'team'} size="md" />
          <h2 className="tr-mb-0">{'Manage Teams'}</h2>
          {teamSummary && (
            <Button
              onClick={() => {
                history.push('/teams');
              }}
              variant="ternary"
            >
              <span className="tr-flex tr-items-center tr-gap-1">
                <Icon type="chevronLeft" size="sm" />
                Go back to teams
              </span>
            </Button>
          )}
        </div>
        <div className="tr-flex tr-wrap tr-my-5">
          <section className="tr-h-full tr-basis-1/4 tr-bg-[#F8F9FC] tr-py-3  tr-px-2.5">
            <div className="tr-flex tr-my-3 tr-justify-between">
              <div className="tr-flex tr-items-center">
                <div
                  className={`tr-w-[40px] tr-h-[40px] tr-flex tr-items-center tr-justify-center tr-rounded-lg  team-gradient-${teamColor}`}
                  style={teamColor.includes('#') ? {backgroundColor: teamColor} : {}}
                ></div>
                <div className="tr-flex tr-items-center">
                  <div className="tr-font-bold tr-pl-2 tr-max-w-[200px] tr-truncate">{teamSummary.name}</div>
                </div>
              </div>
              <IconButton
                icon="edit"
                variant="ternary"
                onClick={() => {
                  setEditingTeam(true);
                }}
              />
            </div>
            <div className="tr-flex tr-my-3 tr-text-sm">{teamSummary.description}</div>
            <div className="tr-flex tr-border-gray-200 tr-border-solid tr-border-b-0 tr-border-l-0  tr-border-r-0  tr-border-t-[1px] tr-py-3">
              <Icon type="user" size="md" title="Managers" />

              <div className="tr-flex tr-items-center">
                <div className="tr-text-sm tr-font-bold tr-pl-2 tr-max-w-[200px] tr-truncate">Managers</div>
              </div>
            </div>
            <div className="tr-flex tr-flex-col tr-items-start">
              {(teamSummary.managers ?? []).map((manager: Partial<UserT>) => {
                return (
                  <UserAvatarAndName wrapper={false} displayName={formatUserName(manager)} subtitle={manager.email} />
                );
              })}
            </div>
            {TeamTypeEnum.ADHOC !== teamSummary?.type && teamSummary.service && (
              <div className="tr-flex tr-border-gray-200 tr-border-solid tr-border-b-0 tr-border-l-0  tr-border-r-0  tr-border-t-[1px] tr-py-3">
                <div className="tr-flex tr-items-center tr-flex-col">
                  <div className="tr-flex tr-items-center">
                    <TileButton
                      size="md"
                      className="tr-my-1 tr-mx-1"
                      name={teamSummary.service as SystemIconsTypesEnum}
                      data-testid={`manage-${teamSummary.service}`}
                      key={teamSummary.service}
                    />
                    <div className="tr-text-sm tr-font-bold tr-pl-2 tr-max-w-[200px] tr-truncate">
                      Team created through {teamSummary.service}
                    </div>
                  </div>
                  <div className="tr-text-xs tr-mt-1 tr-pl-2 tr-max-w-[200px] tr-truncate">
                    {`Last users import: ${formatFullDate(teamSummary.lastImport)}`}
                  </div>
                </div>
              </div>
            )}
            <div className="tr-flex tr-border-gray-200 tr-border-solid tr-border-b-0 tr-border-l-0  tr-border-r-0  tr-border-t-[1px] tr-py-3">
              <Icon type="system" size="md" title="Systems" />
              <div className="tr-flex tr-items-center">
                <div className="tr-text-sm tr-font-bold tr-pl-2 tr-max-w-[200px] tr-truncate">Systems</div>
              </div>
            </div>
            <div className="tr-flex tr-flex-col tr-items-start">
              <div className="tr-mt-3 flex-wrap tr-px-2 tr-flex tr-items-center">
                {_.keys(groupedByType).map((type) => {
                  return (
                    <TileButton
                      size="lg"
                      className="tr-my-1 tr-mx-1"
                      name={type as SystemIconsTypesEnum}
                      data-testid={`manage-${type}`}
                      key={type}
                    />
                  );
                })}
              </div>
            </div>
          </section>
          <section className="tr-items-center tr-basis-3/4  tr-py-3 tr-px-2.5">
            <TeamMembersPanel team={teamSummary} users={usersStore.users} summary={teamSummary} />
            <Modal
              width="lg"
              onClose={() => {
                setEditingTeam(false);
              }}
              visible={editingTeam}
              title={
                <div>
                  <Icon type="edit" className="tr-mr-2" />
                  {'Edit Team'}
                </div>
              }
            >
              <TeamForm
                team={teamSummary}
                users={usersStore.users}
                members={[]}
                onClose={() => {
                  setEditingTeam(false);
                }}
                onAfterSubmit={(data) => {
                  setTeamSummary({...teamSummary, ...data});
                  setEditingTeam(false);
                }}
              />
            </Modal>
          </section>
        </div>
      </div>
    </div>
  );
}

export function TeamPanel() {
  const location = useLocation();
  const props = location.state as {team?: TeamT};
  return <TeamDetails team={props?.team} />;
}
