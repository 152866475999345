import {Button, Icon, TextInput} from '@trustle/component-library';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';
import React from 'react';

type Props = {
  showBack?: boolean;
  showSearch?: boolean;
  handleSearch?: (value: string) => void;
  handleBack?: () => void;
  title: string;
  icon?: ItemIconTypesEnum;
  placeholder?: string;
  defaultValue?: string;
};

export function HeaderSearch({
  showBack,
  showSearch,
  handleSearch,
  handleBack,
  title,
  icon,
  placeholder,
  defaultValue,
}: Props) {
  return (
    <div className="tr-flex tr-items-center tr-gap-3 tr-bg-[#F8F9FC] tr-py-3 tr-px-2.5">
      {showBack && (
        <Button onClick={handleBack} variant="ternary">
          <span className="tr-flex tr-items-center tr-gap-1">
            <Icon type="chevronLeft" size="sm" />
            Go back
          </span>
        </Button>
      )}
      {icon && <Icon type={icon} size="md" />}
      <h2 className="tr-mb-0">{title}</h2>
      {showSearch && (
        <TextInput
          defaultValue={defaultValue}
          placeholder={placeholder}
          className="tr-pb-0 tr-max-w-[600px] tr-h-[40px]"
          onInput={(e: any) => {
            handleSearch && handleSearch(e.target.value);
          }}
        />
      )}
    </div>
  );
}
