import {Account} from 'src/stores/domainObjects/Account';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ResourceCategoryEnum} from 'src/types';
import {AccessBranchPropsT} from 'src/views/Resource/TreeView/AccessTable';
import {PermissionBranchPropsT} from 'src/views/Resource/TreeView/PermissionBranch';
import {ResourceBranchPropsT} from 'src/views/Resource/TreeView/ResourceBranch';
import {SystemTreeTabPanelT} from 'src/views/Resource/TreeView/SystemWrapper';

export enum FormFieldTypes {
  TEXT = 'text',
  PASSWORD = 'password',
  TEXTAREA = 'textArea',
  NUMBER = 'number',
  EMAIL = 'email',
  FILE = 'file',
  SELECT = 'select',
}

export enum IntegrationEntitiesViewTypes {
  RESOURCE = 'Resource',
  PERMISSION = 'Permission',
  ACCESSRECORD = 'AccessRecord',
}
export enum PageTemplateName {
  RESOURCES = 'resources',
  ACCOUNTS = 'accounts',
}
export enum ComponentTemplateName {
  TREE = 'tree',
  TABLES = 'tables',
}

//CUSTOMS
export enum ResourceCategoryEnumExtended {
  DEFAULT = 'default',
}

type CategoryManifestEnum = ResourceCategoryEnum | ResourceCategoryEnumExtended;

export type IntegrationEntitiesView<BranchPropsT> = {
  [key in CategoryManifestEnum]?: BranchPropsT;
};

export type AccessIntegrationEntityView = {
  main?: (params: AccessBranchPropsT) => React.ReactElement;
};

export type SystemWrapperEntityView = {
  main?: (params: SystemTreeTabPanelT) => React.ReactElement;
};

export type ResourceIntegrationEntityView = {
  main?: (params: ResourceBranchPropsT) => React.ReactElement;
  actions?: (params: ResourceBranchPropsT) => React.ReactElement;
  header?: (params: ResourceBranchPropsT) => React.ReactElement;
  attributes?: (params: ResourceBranchPropsT) => React.ReactElement;
  children?: (params: ResourceBranchPropsT) => React.ReactElement;
};

export type PermissionIntegrationEntityView = {
  main?: (params: PermissionBranchPropsT) => React.ReactElement;
  actions?: (params: PermissionBranchPropsT) => React.ReactElement;
  header?: (params: PermissionBranchPropsT) => React.ReactElement;
  attributes?: (params: PermissionBranchPropsT) => React.ReactElement;
};

export type ViewEntities = {
  Wrapper?: IntegrationEntitiesView<SystemWrapperEntityView>;
  [IntegrationEntitiesViewTypes.RESOURCE]: IntegrationEntitiesView<ResourceIntegrationEntityView>;
  [IntegrationEntitiesViewTypes.PERMISSION]: IntegrationEntitiesView<PermissionIntegrationEntityView>;
  [IntegrationEntitiesViewTypes.ACCESSRECORD]: IntegrationEntitiesView<AccessIntegrationEntityView>;
};

export type AccountTableParams = {accounts: Account[]; system: Resource};
export type AccountTemplates = {
  Linked?: (params: AccountTableParams) => string[];
  Unlinked?: (params: AccountTableParams) => string[];
  Flagged?: (params: AccountTableParams) => string[];
  Offboarded?: (params: AccountTableParams) => string[];
};
