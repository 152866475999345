import {Icon} from '@trustle/component-library';
import React from 'react';

const Header = () => {
  return (
    <header className="header pt-4 text-white tr-bg-trustle-royal tr-mb-8" style={{height: '135px'}}>
      <div className="container">
        <div className="tr-flex tr-items-center tr-pb-2">
          <Icon type="access" size="xl" className="tr-opacity-100" />
          <div className="tr-flex tr-flex-col ml-4">
            <h1 className="tr-mb-0">Find New Access</h1>
            <div>
              Below is a list of the systems and resources connected with Trustle. Select a system type below to explore
              and request new access.
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
