import {Icon, SystemIcons} from '@trustle/component-library';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {LastSyncLabel} from '../../../../connectors/common/authority/authority-selector/internal/LastSyncLabel';
import {useAuthority} from '../../../../connectors/common/authority/hooks/useAuthority';
import {USER_TYPES} from '../../admin/admin-users-panel/constants';
import {useOrganizationSummary} from '../../admin/admin-users-panel/hooks/useOrganizationSummary';
import {useUsersSummary} from '../../admin/admin-users-panel/hooks/useUsersSummary';
import {UserQueryFiltersAndPagination} from '../../admin/admin-users-panel/UserFiltersContext';
import {getUsersSummaryValue} from '../../admin/admin-users-panel/utils';
import {DataStatus} from '../../common/DataStatus';
import {usePageNavigator} from '../../hooks/usePageNavigator';
import {WidgetHeader} from './shared/WidgetHeader';
import {WidgetHeaderItem} from './shared/WidgetHeaderItem';

export function UsersManagementWidget() {
  const {
    settings: authoritySettings,
    isConfigured: isAuthorityConfigured,
    isDisabled: isAuthorityDisabled,
  } = useAuthority();

  const {data: organizationSummary, loading: loadingOrgSummary, getOrganizationSummary} = useOrganizationSummary();
  const {data: usersSummary, loading: loadingUsersSummary, getUsersSummary} = useUsersSummary();

  const {navigate, to} = usePageNavigator<UserQueryFiltersAndPagination>({
    path: '/admin/users',
    defaultParams: {page: 1, size: 10},
  });

  useEffect(() => {
    void getOrganizationSummary({});
    void getUsersSummary({});
  }, []);

  const authorityIcon = isAuthorityConfigured ? (
    <div className="relative">
      {isAuthorityDisabled && (
        <div className="tr-absolute tr-bg-red-500 tr-w-2 tr-h-2 tr-rounded-full tr-top-[-3px] tr-right-[-3px]" />
      )}
      <SystemIcons name={authoritySettings?.service as SystemIconsTypesEnum} size="md" />
    </div>
  ) : (
    <Icon type="Trustle" title="Trustle" forcedSize={32} />
  );

  if (loadingOrgSummary || !organizationSummary || loadingUsersSummary || !usersSummary) {
    return <DataStatus text={'Loading'} status="loading" />;
  }

  const {users_count, departments_count, roles_count, titles_count} = organizationSummary;

  return (
    <div className="tr-flex tr-flex-col">
      <WidgetHeader
        title="Trustle Users"
        linkTo="/admin/users"
        linkText="Manage Users"
        middleContent={
          <div className="tr-flex tr-items-center tr-gap-3 tr-ml-auto">
            <LastSyncLabel className="tr-text-md tr-text-gray-400" />
            <p className="tr-mb-0">Authority:</p>
            {authorityIcon}
          </div>
        }
      />
      <div className="tr-flex tr-justify-between tr-mt-10">
        <div className="tr-flex tr-gap-6">
          <WidgetHeaderItem
            title={users_count.toLocaleString()}
            subtitle="Trustle Users"
            onClick={() => navigate({groupBy: 'user'})}
          />
          <div className="tr-flex tr-flex-col tr-gap-2 tr-justify-center">
            <span className="tr-flex tr-gap-2 tr-items-center">
              <div className="tr-w-[9px] tr-h-[9px] tr-bg-status-success tr-rounded-full" />
              <Link className="" to={to({status: 'active', groupBy: 'user'})}>
                {usersSummary.activeUserCount.toLocaleString()} Active
              </Link>
            </span>
            <span className="tr-flex tr-gap-2 tr-items-center">
              <div className="tr-w-[9px] tr-h-[9px] tr-bg-status-pending tr-rounded-full" />
              <Link className="" to={to({status: 'inactive', groupBy: 'user'})}>
                {usersSummary.inactiveUserCount.toLocaleString()} Inactive
              </Link>
            </span>
          </div>
        </div>
        <div className="tr-w-[1px] tr-bg-gray-300 tr-my-4" />
        <WidgetHeaderItem
          title={departments_count.toLocaleString()}
          subtitle="Departments"
          onClick={() => navigate({groupBy: 'department'})}
          appendDivider
        />
        <WidgetHeaderItem
          title={roles_count.toLocaleString()}
          subtitle="Roles"
          onClick={() => navigate({groupBy: 'role'})}
          appendDivider
        />
        <WidgetHeaderItem
          title={titles_count.toLocaleString()}
          subtitle="Titles"
          onClick={() => navigate({groupBy: 'title'})}
          appendDivider
        />
        {/*User Types counts*/}
        <div className="tr-flex tr-flex-col tr-gap-2 tr-items-center">
          <div className="tr-grid tr-grid-cols-2 tr-gap-2">
            {USER_TYPES.map((type) => (
              <div key={type}>
                <div className="tr-flex tr-items-center tr-gap-2">
                  <Icon type={`${type}User`} />
                  <Link className="" to={to({type, groupBy: 'user'})}>
                    {getUsersSummaryValue(type, usersSummary)}
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <p>User Types</p>
        </div>
      </div>
    </div>
  );
}
