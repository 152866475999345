import {Table} from '@trustle/component-library';
import React from 'react';
import {useDebounce} from 'src/lib/hooks';
import {DataStatus} from '../../../../common/DataStatus';
import {HeaderSearch} from '../../../../common/HeaderSearch';
import {FilterConfig, useFiltersConfigMap} from '../../hooks/useUserFiltersConfigMap';
import {UserFilterKey} from '../../types';
import {UserQueryFiltersAndPagination, useUserFilters} from '../../UserFiltersContext';
import {useUsersListed} from '../../UsersListedContext';
import {USER_COLUMNS} from './userColumns';

const TABLE_KEY = 'users-table';

export function UsersTable() {
  const {listUsersResponse, loading} = useUsersListed();
  const {filters, setFilter, setFilters} = useUserFilters();
  const filtersConfigMap = useFiltersConfigMap();

  const handleTableChange = async (
    _type: string,
    {page, sortField, sortOrder}: {page: number; sortField: string; sortOrder: 'asc' | 'desc'}
  ) => {
    if (_type === 'pagination') {
      setFilter('page', page);
    }

    if (_type === 'sort') {
      const fieldToSort = sortField === 'manager' ? 'manager_firstname' : sortField;
      const toSet = {orderBy: fieldToSort, sort: sortOrder};
      setFilters({...filters, ...toSet});
    }
  };

  const handleSizePerPageChange = (size: number) => {
    setFilter('size', size);
  };

  const handleDebouncedSearch = useDebounce(
    (value: string) => {
      setFilter('nameOrEmail', value);
    },
    1000,
    [filters]
  );

  if (loading && !listUsersResponse) {
    return <DataStatus text="Loading users..." status="loading" description="Please stand by" />;
  }

  const {users, total} = listUsersResponse;

  const isRemote = total > Number(filters.size);

  const showBack = filters.groupBy && filters.groupBy !== 'user' && filters[filters.groupBy];
  const showSearch = filters.groupBy === 'user' || showBack;
  const title = resolveTitle(filters, filtersConfigMap);

  return (
    <div className={`${loading ? 'tr-animate-pulse' : ''}`}>
      <HeaderSearch
        showBack={showBack}
        showSearch={showSearch}
        handleSearch={handleDebouncedSearch}
        handleBack={() => {
          setFilters({
            status: filters.status,
            groupBy: filters.groupBy,
            page: 1,
            size: 10,
          });
        }}
        title={title}
        icon="groupPeople"
        placeholder="Search for users"
        defaultValue={filters.nameOrEmail}
      />
      <Table
        data={users}
        columns={USER_COLUMNS}
        tableKey={TABLE_KEY}
        totalSize={total}
        sizePerPage={filters.size}
        page={filters.page}
        remote={
          isRemote
            ? {
                filter: true,
                pagination: true,
                sort: false,
                cellEdit: false,
              }
            : false
        }
        onSizePerPageChange={(size) => {
          handleSizePerPageChange(size);
          return {};
        }}
        onTableChange={isRemote ? handleTableChange : () => {}}
        showEmptyElement={
          <DataStatus status="no-data" text="No users found" description="Try with different filter criteria" />
        }
      />
    </div>
  );
}

function resolveTitle(filters: UserQueryFiltersAndPagination, filtersConfigMap: Record<UserFilterKey, FilterConfig>) {
  const {groupBy, team, department, role, title} = filters;

  const getLabel = (key: UserFilterKey) => {
    return filtersConfigMap[key].options.find((o) => o.value === filters[key])?.label || '';
  };

  switch (groupBy) {
    case 'team':
      return team ? `Team: ${getLabel('team')}` : 'Teams';
    case 'department':
      return department ? `Department: ${getLabel('department')}` : 'Departments';
    case 'role':
      return role ? `Role: ${getLabel('role')}` : 'Roles';
    case 'title':
      return title ? `Title: ${getLabel('title')}` : 'Titles';
    default:
      return 'Users';
  }
}
