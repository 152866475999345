import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import _ from 'lodash';
import './CheckoutPage.scss';
import './PlanSettings.scss';
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import {Link, useHistory, useParams} from 'react-router-dom';

import {Field} from 'formik';
import NotFound from '../NotFound';
import {observer} from 'mobx-react';
import {useRootStore} from 'src/lib/hooks';
import {formatAsDollarsAndCents} from 'src/lib';
import {Formik, FormikForm} from 'src/components/design/formik';
import {Button, NumberInput} from '@trustle/component-library';
import {Tab} from '@trustle/component-library';

type PropsT = {
  selectedPackage: any;
  selectedPrice: any;
  setSelectedPrice: (price: any) => void;
};

const PlanSettings = observer(function PlanSettingsCmp(props: PropsT) {
  const {selectedPackage, selectedPrice, setSelectedPrice} = props;
  const {org} = useRootStore();
  const routeParams = useParams() as {step?: string; activeTab?: string};
  const history = useHistory();
  const [data, setData] = useState<{quantity: number}>({quantity: 5});

  if (_.isNil(org)) {
    return <></>;
  }

  const enabledStripe = process.env.NODE_ENV === 'test';
  const step = routeParams.step ?? 'details';
  const stripe = org.stripe;

  if (!selectedPrice && step === 'payment') {
    history.push(
      `${!routeParams.step ? './status' : '.'}/details${selectedPackage ? `?plan=${selectedPackage.id}` : ''}`
    );
  }

  const formContent = (
    <div className="plan-settings tr-flex flex-column mt-2">
      <Formik
        key={`plandetailsform`}
        initialValues={{quantity: data.quantity}}
        onSubmit={(values) => {
          setData(values);
          history.push(
            `${!routeParams.step ? './status' : '.'}/payment${selectedPackage ? `?plan=${selectedPackage.id}` : ''}`
          );
        }}
        validate={(values: any) => {
          const errors: _.Dictionary<string> = {};
          if (!values.quantity) {
            errors.quantity = 'required';
          }
          return errors;
        }}
      >
        {({values, isValid}) => {
          return (
            <FormikForm key={`plandetailsformik`}>
              <div className="tr-flex bb-gray tr-tr-p-4">
                <div className="tr-w-7/12">
                  <Field
                    component={NumberInput}
                    className="text-center"
                    data-testid="seats"
                    label="Organization Size"
                    description="How many users do you want to manage with Trustle?"
                    name="quantity"
                    id="quantity"
                  />
                </div>
                <div className="tr-w-5/12 tr-flex tr-flex-col tr-justify-end tr-content-end tr-items-end">
                  {selectedPrice && (
                    <>
                      <div className="tr-flex">
                        <div className="body3 tr-flex flex-column justify-content-center price-per-seat">
                          <span className={'m-0 mr-1'}>{formatAsDollarsAndCents(selectedPrice.unit_amount / 100)}</span>
                        </div>
                        <div className="tr-flex flex-column tr-justify-center tr-content-center">
                          <span className="body5">{`per User`}</span>
                          {selectedPrice.recurring?.interval && (
                            <span className="body5">{`per ${selectedPrice.recurring?.interval}`}</span>
                          )}
                        </div>
                      </div>
                      <div>
                        <span className="body6 grayed-out">Billing starts after a 14 day free trial</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="tr-flex tr-m-2 gray-bottom-border bb-gray tr-p-4">
                <div className="tr-flex tr-flex-col text-center">
                  <div className={`tr-flex form-control--text-input text-left tr-mb-2`}>
                    <Form.Label>{'Billing Cycle'}</Form.Label>
                  </div>
                  <div>
                    <div className="tr-flex tr-justify-between tr-items-center">
                      {selectedPackage?.prices.map((price: any, index: number) => {
                        return (
                          <div className="tr-flex tr-flex-col" key={price.id + index}>
                            <Button
                              selected={selectedPrice?.id === price.id}
                              data-testid={`selectedPrice${price.id}`}
                              onClick={() => {
                                setSelectedPrice(price);
                              }}
                            >
                              <div className="tr-m-2">
                                <span>{`Per ${_.startCase(price.recurring?.interval)}`}</span>
                              </div>
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="tr-flex tr-justify-between tr-items-center">
                      {selectedPackage?.prices.map((price: any, index: number) => {
                        return (
                          <div className="tr-flex tr-flex-col" key={`${price.id + index}key`}>
                            <div className="body6 grayed-out">
                              {price.recurring?.interval === 'year' ? `*2 months for free` : ''}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {selectedPrice && (
                <div className="tr-mt-4 tr-mb-4 tr-p-4 tr-flex tr-justify-center tr-align-center">
                  <div className="body1 tr-flex tr-flex-col tr-justify-between tr-align-center">
                    <div className="body4 pr-5">Estimated Subtotal</div>
                    <div className="body3 ">
                      {formatAsDollarsAndCents((selectedPrice.unit_amount / 100) * values.quantity)}
                      {selectedPrice.recurring?.interval ? (
                        <span>{`/ ${_.startCase(selectedPrice.recurring?.interval)}`}</span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="tr-flex tr-flex-col">
                <div className="tr-text-right body1 ">
                  <Button disabled={!isValid || !selectedPrice} type="submit" className="p-3">
                    {'Payment Details ->'}
                  </Button>
                </div>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );

  const billingStep = (
    <div className={'tr-flex flex-column mt-3'}>
      <Elements stripe={stripe}>
        <CheckoutForm quantity={data.quantity} price={selectedPrice} />
      </Elements>
    </div>
  );

  if (!enabledStripe) {
    return <NotFound />;
  }

  return (
    <div className="tr-tr-p-4 tr-flex  lex-column align-items-center shadow-lg rounded-xl text-navy min-w-2xs">
      <h2>Upgrade To {selectedPackage?.name} Plan</h2>
      <Tab.Group className="m-5">
        <Tab.List variant="line">
          <Tab
            data-testid={'details'}
            key={'details'}
            as={Link}
            to={`${!routeParams.step ? './status' : '.'}/details${
              selectedPackage ? `?plan=${selectedPackage.id}` : ''
            }`}
          >
            Organization Details
          </Tab>
          <Tab
            data-testid={'payment'}
            key={'payment'}
            as={Link}
            to={`${!routeParams.step ? './status' : '.'}/payment${
              selectedPackage ? `?plan=${selectedPackage.id}` : ''
            }`}
          >
            Payment Details
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key={'details'}>{formContent}</Tab.Panel>
          <Tab.Panel key={'payment'}>{billingStep}</Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
});

export default PlanSettings;
