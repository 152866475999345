import {useState} from 'react';
import axios from 'axios';
import {SortParams, UserType} from '../types';
import {useRootStore} from '../../../../../lib/hooks';
import {parseObjectToQueryString} from 'src/views/v2/utils';

export type UserLightweightResponse = {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  title: string;
  sourceOfTruth: string;
  type: UserType;
};

type ListUsersLightweightResponse = {
  users: UserLightweightResponse[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

type PaginationParams = {
  page?: number;
  size?: number;
};

export type ListUsersLightweightParams = {
  nameOrEmail?: string;
  // userStatus?: UserStatus[]; // not implemented yet
} & PaginationParams &
  SortParams;

export function useUsersLightweight() {
  const [data, setData] = useState<ListUsersLightweightResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {toast} = useRootStore();

  const BASE_URL = '/api/v2/users/lightweight';

  const fetchUsersLightweight = async (params: ListUsersLightweightParams) => {
    const {page, size, ...rest} = params;

    const parsedParams: Record<string, string> = {
      page: (page || 1).toString(),
      size: (size || 50).toString(),
      ...rest,
    };

    const queryString = parseObjectToQueryString(parsedParams);

    setLoading(true);
    try {
      const response = await axios.get<ListUsersLightweightResponse>(`${BASE_URL}?${queryString}`);
      setData(response.data);
    } catch (err: any) {
      setError(true);
      toast.error('There was an error trying to get fetch users');
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, fetchUsersLightweight};
}
