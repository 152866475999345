import React, {useEffect, useState} from 'react';
import {DataStatus} from '../../../../../common/DataStatus';
import {Table} from '@trustle/component-library';
import {useUserCountByColumns} from './useUserCountByColumns';
import {useUsersCountBy} from '../../../hooks/useUsersCountBy';
import {useUserFilters} from '../../../UserFiltersContext';
import {HeaderSearch} from '../../../../../common/HeaderSearch';
import {useDebounce} from 'src/lib/hooks';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';
import _ from 'lodash';

type UserCountBy = 'department' | 'role' | 'title';

type Props = {
  countBy: UserCountBy;
};

const countByToIcon: Record<UserCountBy, ItemIconTypesEnum> = {
  department: 'project',
  role: 'roles',
  title: 'project',
};

export function UsersCountByTable({countBy}: Props) {
  const {data: usersCountedBy, loading, getUsersCountedBy} = useUsersCountBy();
  const {filters} = useUserFilters();

  const COLUMNS = useUserCountByColumns({countBy});

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [search, setSearch] = useState('');

  useEffect(() => {
    void getUsersCountedBy({filter: countBy, status: filters.status, page, size, searchTerm: search});
  }, [page, size, countBy, filters.status, search]);

  useEffect(() => {
    setSearch('');
  }, [countBy]);

  const handleDebouncedSearch = useDebounce(
    (value: string) => {
      setSearch(value);
    },
    1000,
    []
  );

  if (loading || !usersCountedBy) {
    return <DataStatus text={`Loading ${countBy}...`} status="loading" description="Please stand by" />;
  }

  const {items, total} = usersCountedBy;

  return (
    <>
      <HeaderSearch
        showSearch
        handleSearch={handleDebouncedSearch}
        title={_.capitalize(`${countBy}s`)}
        icon={countByToIcon[countBy]}
        placeholder={`Search for ${countBy}`}
        defaultValue={search}
      />
      <Table
        data={items}
        columns={COLUMNS}
        tableKey="teams-summary-table"
        totalSize={total}
        sizePerPage={size}
        page={page}
        remote={{
          filter: true,
          pagination: true,
          sort: false,
          cellEdit: false,
        }}
        onSizePerPageChange={(size) => {
          setSize(size);
          return {};
        }}
        onTableChange={(_type: string, {page}: {page: number}) => {
          setPage(page);
        }}
        showEmptyElement={<DataStatus status="no-data" text={`No ${countBy} found`} />}
      />
    </>
  );
}
