import {useRef, useState} from 'react';
import axios from 'axios';
import {InvitationStatus, SortParams, UserStatus, UserType} from '../types';
import {useRootStore} from '../../../../../lib/hooks';
import {parseObjectToQueryString} from 'src/views/v2/utils';
export type UserResponse = {
  id: string;
  allowLogin: boolean;
  canSwitchSoT: boolean;
  confirmed: boolean;
  created: string;
  createdByUid: string;
  department: string;
  email: string;
  firstname: string;
  lastname: string;
  invitationStatus: InvitationStatus;
  linked: boolean;
  sourceOfTruth: string;
  userStatus: UserStatus;
  type: UserType;
  title: string;
  invitedAt: string;
  isOrgOwner: boolean;
  lastLogin: string;
  remoteRole: string;
  loginMethod: string;
  manager: {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
  };
};

type ListUsersResponse = {
  users: UserResponse[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

type PaginationParams = {
  page?: number;
  size?: number;
};

export type ListUsersParams = {
  status?: UserStatus;
  type?: UserType;
  invitationStatus?: InvitationStatus;
  team?: string;
  nameOrEmail?: string;
  manager?: string;
  role?: string;
  title?: string;
  department?: string;
} & PaginationParams &
  SortParams;

export function useUsers() {
  const [data, setData] = useState<ListUsersResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {toast} = useRootStore();

  const BASE_URL = '/api/v2/users';

  // Ref to store the current request identifier
  // This ensures that we only update the state if the request is the latest one
  const currentRequestId = useRef<number>(0);

  const fetchUsers = async (params: ListUsersParams) => {
    const {page, size, ...rest} = params;

    const parsedParams: Record<string, string> = {
      page: (page || 1).toString(),
      size: (size || 50).toString(),
      ...rest,
    };

    const queryString = parseObjectToQueryString(parsedParams);

    setLoading(true);
    const requestId = ++currentRequestId.current; // Increment and store the request ID
    try {
      const response = await axios.get<ListUsersResponse>(`${BASE_URL}?${queryString}`);
      if (requestId === currentRequestId.current) {
        setData(response.data);
      }
    } catch (err: any) {
      if (requestId === currentRequestId.current) {
        setError(true);
        toast.error('There was an error trying to get fetch users');
      }
    } finally {
      if (requestId === currentRequestId.current) {
        setLoading(false);
      }
    }
  };

  return {data, loading, error, fetchUsers};
}
