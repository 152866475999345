import React from 'react';
import {Link} from 'react-router-dom';

import {SizesEnum} from '@trustle/component-library/dist/types';
import {Icon, Tooltip} from '@trustle/component-library';
import {Account} from '../../../hooks/useAccounts';

type Props = {account: Account; systemId: string; iconSize?: SizesEnum};

// copied from `frontend/src/components/design/tables/common/ShowAuthorizedAccount.tsx`
function AWSAuthorizedAccount(props: Props) {
  const {account, systemId, iconSize = 'xs'} = props;

  if (!account.isAuthzOwner) {
    return <></>;
  }
  const icon = <Icon type={'connection'} className="cursor tr-text-[#F08C00] mr-2" size={iconSize} />;

  return (
    <Tooltip content={'Credentials in use by the Trustle connector.'}>
      {account.userIsOrgOwner ? <Link to={`/resource/manage/${systemId}/settings/edit_connection`}>{icon}</Link> : icon}
    </Tooltip>
  );
}

export default AWSAuthorizedAccount;
