import React, {useEffect} from 'react';
import {OrganizationSummaryResponse, useOrganizationSummary} from '../../hooks/useOrganizationSummary';
import {UserGroupByKey} from '../../types';
import {useUserFilters} from '../../UserFiltersContext';
import _ from 'lodash';
import OverviewItem from 'src/views/v2/common/OverviewItem';

const SUMMARY_GROUP_BY_BUTTONS: {
  groupByKey: UserGroupByKey;
  propName: keyof OrganizationSummaryResponse;
  label: string;
}[] = [
  {
    groupByKey: 'user',
    propName: 'users_count',
    label: 'Users',
  },
  {
    groupByKey: 'team',
    propName: 'teams_count',
    label: 'Teams',
  },
  {
    groupByKey: 'department',
    propName: 'departments_count',
    label: 'Departments',
  },
  {
    groupByKey: 'role',
    propName: 'roles_count',
    label: 'Roles',
  },
  {
    groupByKey: 'title',
    propName: 'titles_count',
    label: 'Titles',
  },
];

export function SummaryGroupBy() {
  const {filters, setFilters} = useUserFilters();
  const {data, loading, getOrganizationSummary} = useOrganizationSummary();

  useEffect(() => {
    void getOrganizationSummary({status: filters.status});
  }, [filters.status]);

  const resolveValue = (data: OrganizationSummaryResponse | undefined, propName: keyof OrganizationSummaryResponse) => {
    return data && !_.isEmpty(data) ? data[propName] : 0;
  };

  return (
    <div className="tr-flex align-items-center rounded-2xl tr-shadow-md tr-border-solid tr-border-[2px] tr-border-gray-100 tr-p-5 tr-gap-4">
      {SUMMARY_GROUP_BY_BUTTONS.map(({groupByKey, propName, label}, index) => (
        <>
          <OverviewItem
            key={propName}
            onClick={() => {
              setFilters({
                status: filters?.status,
                groupBy: groupByKey,
                page: 1,
                size: 10,
              });
            }}
            value={resolveValue(data, propName)}
            label={label}
            selected={filters?.groupBy === groupByKey}
            loading={loading}
          />
          {index === 0 && <div className="tr-w-[1px] tr-h-[96px] relative tr-bg-gray-300" />}
        </>
      ))}
    </div>
  );
}
