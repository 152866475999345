import React, {useEffect, useState} from 'react';
import {LINK_STATUSES, USER_TYPES} from '../../constants';
import {Button, Icon} from '@trustle/component-library';
import _ from 'lodash';
import {useUserFilters} from '../../UserFiltersContext';
import {LinkStatus, UserType} from '../../types';
import {UsersSummaryResponse} from '../../hooks/useUsersSummary';
import {getUsersSummaryValue} from '../../utils';

type Props = {
  usersSummary?: UsersSummaryResponse;
};

export function QuickFilter({usersSummary}: Props) {
  const {filters, setFilter} = useUserFilters();
  const [selectedUserType, setSelectedUserType] = useState<UserType | undefined>();
  const [selectedLinkStatus, setSelectedLinkStatus] = useState<LinkStatus | undefined>();

  useEffect(() => {
    const {type, linkStatus} = filters;
    setSelectedUserType(type);
    setSelectedLinkStatus(linkStatus);
  }, [filters]);

  return (
    <div className="tr-flex tr-items-center tr-py-3">
      {USER_TYPES.map((type) => (
        <div key={type}>
          <Button
            variant="ternary"
            selected={type === selectedUserType}
            onClick={() => setFilter('type', type === selectedUserType ? '' : type)}
            disabled={getUsersSummaryValue(type, usersSummary) === 0}
          >
            <div className="tr-flex tr-items-center tr-gap-2">
              <Icon type={`${type}User`} />
              <span>{_.startCase(type)}</span>
              <span>{getUsersSummaryValue(type, usersSummary)}</span>
            </div>
          </Button>
        </div>
      ))}
      <div className="tr-w-[1px] tr-h-4 tr-bg-gray-300 tr-mx-2" />
      {LINK_STATUSES.map((status) => (
        <div key={status}>
          <Button
            variant="ternary"
            selected={status === selectedLinkStatus}
            onClick={() => setFilter('linkStatus', status === selectedLinkStatus ? '' : status)}
          >
            <span className="tr-flex tr-items-center tr-gap-1">
              <Icon type={`${status}`} />
              {_.startCase(status)}
            </span>
          </Button>
        </div>
      ))}
    </div>
  );
}
