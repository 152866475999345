import React from 'react';
import {observer} from 'mobx-react';
import {ResourceIcon} from '@trustle/component-library';
import {ResourceCategoryEnum} from 'src/types';
import '../PermissionBranch.scss';
import _ from 'lodash';

import {TreeTabPanelT} from '../TreeTabPanel';
import {Resource} from 'src/stores/domainObjects/Resource';
import LockedOrProvision from '../LockedOrProvision';

type TabResourceBranchPropsT = {
  category: ResourceCategoryEnum;
  selected: boolean;
  tab: any;
  filters: any;
} & TreeTabPanelT;

const TabHeader = observer((props: TabResourceBranchPropsT) => {
  const {category, selected, tab, canEditResource} = props;

  const lockedIcon = (cr: Resource) => {
    return <LockedOrProvision target={cr} />;
  };

  if (!tab.resource) {
    return <></>;
  }

  const resource = tab.resource;

  return (
    <div className="tr-flex tr-items-center tr-justify-between">
      <div className="tr-flex tr-items-center">
        <ResourceIcon category={category} size="sm" className="tr-mr-1" />
        <span className="tr-mr-1">{resource.rootSid ? resource.name : 'System Permissions'}</span>
        <span className="tr-text-sm whitespace-nowrap">
          {`(${tab.visible} of ${
            resource.rootSid ? _.size(resource.childResourcesAndPerms) : _.size(resource.permissions)
          })`}
        </span>
      </div>
      {canEditResource && (
        <>
          {selected ? (
            <div className="actions tr-text-black tr-flex tr-items-center">
              <div className="tr-flex ">{lockedIcon(resource)}</div>
            </div>
          ) : (
            lockedIcon(resource)
          )}
        </>
      )}
    </div>
  );
});

export default TabHeader;
