import {Button} from '@trustle/component-library';
import React from 'react';
import {Link, useHistory} from 'react-router-dom';

type Props = {
  title: React.ReactNode;
  linkTo?: string;
  linkText?: string;
  middleContent?: React.ReactNode;
  linkAsButton?: boolean;
};

export function WidgetHeader({title, linkTo, linkText, middleContent, linkAsButton = false}: Props) {
  const history = useHistory();

  const showLink = linkTo && linkText;

  return (
    <div className="tr-flex tr-items-center">
      <p className="tr-mb-0 tr-text-trustle-royal tr-text-2xl tr-font-bold">{title}</p>
      {middleContent}
      {showLink && !linkAsButton && (
        <Link className="tr-ml-auto tr-flex tr-items-center tr-no-underline" to={linkTo}>
          <label className="tr-mb-0 tr-text-trustle-link tr-font-bold tr-cursor-pointer">{linkText}</label>
        </Link>
      )}
      {showLink && linkAsButton && (
        <div className="tr-flex tr-justify-center tr-ml-auto">
          <Button onClick={() => history.push(linkTo)}>{linkText}</Button>
        </div>
      )}
    </div>
  );
}
