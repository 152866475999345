import {Icon, Tooltip} from '@trustle/component-library';
import {formatDateFromNow, formatFullDate} from '../../../../../../../lib';
import React from 'react';
import {useUserFilters} from '../../../UserFiltersContext';
import {TeamResponse} from '../../../hooks/useTeams';

export function useTeamsColumns() {
  const {setFilter} = useUserFilters();

  const handleSelectTeam = (team_id: string) => {
    setFilter('team', team_id);
  };

  return [
    {
      formatter: () => {
        return <Icon type="team" size="sm" />;
      },
      headerStyle: {width: '4em'},
      style: {width: '4em', paddingTop: '20px', paddingBottom: '20px'},
    },
    {
      text: 'Name',
      dataField: 'name',
      formatter: (name: string, team: TeamResponse) => {
        return (
          <div className="tr-cursor-pointer" onClick={() => handleSelectTeam(team.id)}>
            {name}
          </div>
        );
      },
    },
    {
      text: 'Members',
      dataField: 'userCount',
      formatter: (userCount: number) => {
        return <div>{userCount}</div>;
      },
    },
    {
      text: 'Managers',
      dataField: 'managers',
      formatter: (managers: any[]) => {
        return (
          <>
            {(managers || []).map((manager) => (
              <div key={manager.id}>{manager.email}</div>
            ))}
          </>
        );
      },
    },
    {
      text: 'Last Activity',
      dataField: 'lastActivity',
      formatter: (lastActivity: string) => {
        return !lastActivity ? (
          <Icon type="emptyData" title="Never" size="sm" />
        ) : (
          <Tooltip position="top" content={formatFullDate(lastActivity)}>
            {formatDateFromNow(lastActivity)}
          </Tooltip>
        );
      },
    },
    {
      formatter: (_: string, team: TeamResponse) => {
        return (
          <Icon className="tr-cursor-pointer" onClick={() => handleSelectTeam(team.id)} type="chevronRight" size="sm" />
        );
      },
      headerStyle: {width: '4em'},
      style: {width: '4em'},
    },
  ] as any;
}
