import React from 'react';

const Box = ({
  children,
  className,
  ...divProps
}: {children: React.ReactNode; className?: string} & React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className={`tr-flex tr-flex-col rounded-lg bg-white shadow-md tr-border-solid tr-border-[2px] tr-border-gray-100 tr-p-8 ${className}`}
      {...divProps}
    >
      {children}
    </div>
  );
};

export default Box;
