import React from 'react';
import {FilterToApply, UserFilterKey} from '../../../types';
import {Icon} from '@trustle/component-library';
import {SelectSavedFilterView} from './SelectSavedFilterView';
import {Divider} from 'src/views/v2/common/Divider';

type Props = {
  toggleFilterSelection: (key: UserFilterKey) => void;
  filters: FilterToApply[];
  setFilters: (filters: FilterToApply[]) => void;
};

type FilterOption = {
  key: UserFilterKey;
  label: string;
};

type FilterOptionProps = {
  key: UserFilterKey;
  label: string;
  isSelected: boolean;
  onToggle: () => void;
};

type FilterSectionProps = {
  title: string;
  filters: FilterOption[];
  onToggle: (key: UserFilterKey) => void;
  selectedFilters: UserFilterKey[];
};

const FILTER_SECTIONS: Record<string, {title: string; options: FilterOption[]}> = {
  PROPERTIES: {
    title: 'Properties',
    options: [
      {key: 'team', label: 'Team'},
      {key: 'department', label: 'Department'},
      {key: 'role', label: 'Role'},
      {key: 'title', label: 'Title'},
      {key: 'manager', label: 'Manager'},
    ],
  },
  USER_INFORMATION: {
    title: 'User Information',
    options: [
      {key: 'status', label: 'User Status'},
      {key: 'type', label: 'User Type'},
      {key: 'invitationStatus', label: 'Invitation Status'},
      {key: 'linkStatus', label: 'Link Status'},
    ],
  },
};

const FilterOption = ({key, label, isSelected, onToggle}: FilterOptionProps) => (
  <label
    className="tr-flex tr-justify-between tr-items-center tr-py-2.5 tr-px-3 tr-mb-0 hover:tr-bg-[#f2f3f6]"
    key={key}
    onClick={onToggle}
  >
    {label}
    {isSelected && <Icon type="check" />}
  </label>
);

const FilterSection = ({title, filters, onToggle, selectedFilters}: FilterSectionProps) => (
  <>
    <Divider />
    <strong className="tr-py-2.5 tr-px-3">{title}</strong>
    <Divider />
    {filters.map(({key, label}) => (
      <FilterOption key={key} label={label} isSelected={selectedFilters.includes(key)} onToggle={() => onToggle(key)} />
    ))}
  </>
);

export function AddFiltersSection({toggleFilterSelection, filters, setFilters}: Props) {
  return (
    <div className="tr-flex tr-flex-col tr-gap-2 tr-w-[350px] tr-h-full  tr-py-2 tr-px-2.5">
      <h4 className="tr-mb-0 tr-py-2 tr-px-2.5 tr-text-trustle-navy text-lg">Add Filters</h4>
      <div className="tr-flex tr-flex-col tr-text-sm">
        {Object.values(FILTER_SECTIONS).map(({title, options}) => (
          <FilterSection
            key={title}
            title={title}
            filters={options}
            onToggle={toggleFilterSelection}
            selectedFilters={filters.map((filter) => filter.key)}
          />
        ))}
      </div>
      <SelectSavedFilterView setFilters={setFilters} filters={filters} />
    </div>
  );
}
