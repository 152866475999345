import {useState} from 'react';
import axios from 'axios';
import {AccountAccessType} from 'src/types';
import {AccountQueryFiltersAndPagination} from '../AccountsFiltersContext';

export type Account = {
  principalId: string;
  accountType: AccountAccessType;
  account: string;
  uniqueRemoteId: string;
  status: string;
  foreignData: Record<string, string | number | boolean | undefined | null>;
  userId: string; // for linked accounts, id of the linked user
  users: any[]; // for unlinked accounts, list of user's (id, mail) that partially/exactly/none matches with the account
  userName: string; // for linked accounts, name of the linked user
  lastLogin: string;
  usage: number;
  riskScore: number;
  accessesCount: number;
  isAuthzOwner: boolean;
  userIsOrgOwner: boolean; // for linked accounts
  userEmail: string; // for linked accounts, email of the linked user
};

type ListAccountsResponse = {
  principals: Account[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

export function useAccounts() {
  const [data, setData] = useState<ListAccountsResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/principals';

  const fetchAccounts = async (params: AccountQueryFiltersAndPagination) => {
    setLoading(true);
    try {
      const {page, size, ...rest} = params;
      const parsedParams: Record<string, string> = {
        page: (page || 1).toString(),
        size: (size || 10).toString(),
      };

      Object.keys(rest).forEach((key) => {
        const typedKey = key as keyof typeof rest;
        const value = rest[typedKey] as string | number;
        if (rest[typedKey] !== undefined) {
          parsedParams[typedKey] = value.toString();
        }
      });

      const queryString = new URLSearchParams(parsedParams).toString();
      const response = await axios.get<ListAccountsResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, fetchAccounts};
}
