import React from 'react';
import {AccountsFilterProvider, useAccountFilters} from './AccountsFiltersContext';
import {AccountsView} from './internal/accounts-view/AccountsView';
import {Resource} from 'src/stores/domainObjects/Resource';
import {LinkStatusTabs} from './internal/link-status-tabs/LinkStatusTabs';
import {AccountsProvider} from './AccountsContext';
import {ResourceConnectorT} from 'src/types';
import {AccountsSummaryProvider, useAccountsSummaryContext} from './AccountsSummaryContext';
import {useDebounce} from 'src/lib/hooks';
import {HeaderSearch} from 'src/views/v2/common/HeaderSearch';

type Props = {system: Resource; connector?: ResourceConnectorT};

export function SystemAccountsPanel({system, connector}: Props) {
  return (
    <AccountsFilterProvider>
      <AccountsProvider system={system} connector={connector}>
        <AccountsSummaryProvider system={system}>
          <SystemAccountsPanelInternal />
        </AccountsSummaryProvider>
      </AccountsProvider>
    </AccountsFilterProvider>
  );
}

export function SystemAccountsPanelInternal() {
  const {accountsSummary} = useAccountsSummaryContext();
  const {filters, setFilter} = useAccountFilters();

  const handleDebouncedSearch = useDebounce(
    (value: string) => {
      setFilter('searchTerm', value);
    },
    1000,
    [filters]
  );

  return (
    <main className="container tr-mb-[140px]">
      <section id="header-search" className="tr-flex tr-mb-8">
        <div>
          <h1>Accounts</h1>
          <p className="text-muted tr-max-w-[450px]">
            Link your system accounts with Trustle Users to manage, analyze and track on Trustle.
          </p>
        </div>

        <div className="tr-flex tr-items-baseline tr-gap-3 tr-ml-auto">
          <div className="tr-flex tr-gap-2 tr-items-center"></div>
        </div>
      </section>
      <section>
        <HeaderSearch
          showSearch
          title="Accounts"
          placeholder="Search user or account name"
          defaultValue={filters.searchTerm}
          handleSearch={(value: string) => {
            handleDebouncedSearch(value);
          }}
        />
        <LinkStatusTabs accountsSummary={accountsSummary} />
        <div className="tr-flex tr-flex-col tr-gap-4">{<AccountsView />}</div>
      </section>
    </main>
  );
}
