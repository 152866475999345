import React from 'react';
import ReactDOM from 'react-dom';
import axios, {AxiosResponse} from 'axios';
import AppContainer from './AppContainer';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Bugsnag from '@bugsnag/js';
import GlobalInfoContextWrapper from 'src/GlobalInfoContextWrapper';
import {CookiesProvider} from 'react-cookie';
import {BrowserRouter} from 'react-router-dom';
import './styles.scss';
import './styles/bootstrap-overrides.scss';
import {maxAgeSessionMinutes} from './lib/config';
import {logger} from './lib';
import _ from 'lodash';
import './index.css';
axios.defaults.xsrfCookieName = 'xsrf';
axios.defaults.xsrfHeaderName = 'CSRF-TOKEN';

let sessionTimeoutId: number | undefined = undefined;
// reload page after maxAgeSessionMinutes minutes of inactivity, session expires after maxAgeSessionMinutes minutes and reloading the page
// will allow privateRoute logic to redirect user back to login screen and return them back to current url
// after login
function httpErrorHandler(error: any) {
  if (_.isNil(error)) {
    throw new Error('Unrecoverable error! Error not defined');
  }

  if (axios.isAxiosError(error)) {
    const {config, response} = error;
    if (_.isNil(response)) {
      throw new Error('Response is not set');
    }

    if (error.code === 'ERR_NETWORK') {
      logger.error('Error connecting to the app.');
    } else if (error.code === 'ERR_CANCELED') {
      logger.error('Canceled request ');
    }

    const originalRequest = config;
    const statusCode = response?.status;
    if (statusCode === 404) {
      logger.error('The requested resource does not exist or has been deleted');
    } else if (originalRequest && originalRequest.url?.includes('/api') && statusCode === 401) {
      //Reload triggers info call, if token is really outdated or bad, will redirect to login page.
      logger.error('Please login to access this resource');
    }

    //Retry logic for gateway or timeouted requests.
    if (
      originalRequest &&
      originalRequest.headers &&
      originalRequest.url?.includes('/api') &&
      (response.status === 503 || response.status === 504)
    ) {
      if (config.headers && !config.headers['X-Retry']) {
        originalRequest.headers['X-Retry'] = 'true';

        const newRequestConfig = {
          ...config,
          _retry: true,
        };

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(axios(newRequestConfig));
          }, 2000);
        });
      } else {
        logger.error('Connectivity issue:', error.message);
      }
    }

    //After logging it, fails anyway to be handled by the specific endpoint call if any.
    return Promise.reject(error);
  }
}
export class HttpError extends Error {
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    this.name = 'HttpError';
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}
function responseHandler(res: AxiosResponse<any>) {
  switch (res.config.url) {
    // logout cancels reload timeout
    case '/api/auth/logout':
      window.clearTimeout(sessionTimeoutId);
      break;
    // successful /auth/info response starts reload timeout
    case '/api/auth/info':
      window.clearTimeout(sessionTimeoutId);
      sessionTimeoutId = window.setTimeout(() => location.reload(), maxAgeSessionMinutes * 60 * 1000);
      break;
    // every api response after resets reload timeout
    default:
      if (sessionTimeoutId !== undefined) {
        window.clearTimeout(sessionTimeoutId);
        sessionTimeoutId = window.setTimeout(() => location.reload(), maxAgeSessionMinutes * 60 * 1000);
      }
  }

  return res;
}

axios.interceptors.response.use(responseHandler, httpErrorHandler);

Bugsnag.start({
  apiKey: 'd0b2c244888923179f55e01316d001ad',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  autoTrackSessions: false,
  enabledReleaseStages: ['production'],
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

const rootEl = document.getElementById('root');
ReactDOM.render(
  <ErrorBoundary>
    <CookiesProvider>
      <BrowserRouter>
        <GlobalInfoContextWrapper>
          <AppContainer />
        </GlobalInfoContextWrapper>
      </BrowserRouter>
    </CookiesProvider>
  </ErrorBoundary>,
  rootEl
);
