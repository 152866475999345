import {Button, CircleGauge} from '@trustle/component-library';
import _ from 'lodash';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Divider} from 'src/views/v2/common/Divider';
import {DashboardCardWidget} from 'src/views/v2/dashboard/Dashboard';
import {WidgetHeader} from 'src/views/v2/dashboard/widgets/shared/WidgetHeader';

type Props = {
  resourceId: string;
};

export function SystemTrustleScore({resourceId}: Props) {
  const history = useHistory();

  //TODO: Implement hook to get account and systems score from peryton API

  const accountScore = 23;
  const systemsScore = 45;

  const circles = [
    {color: '#32C5FF', percent: accountScore},
    {color: '#FF9500', percent: systemsScore},
  ];

  return (
    <DashboardCardWidget>
      <div className="tr-flex tr-flex-col tr-justify-between tr-h-full">
        <WidgetHeader title="Trustle Score" />
        <div className="tr-w-2/3 tr-flex tr-flex-col tr-mx-auto">
          <CircleGauge className="sm:tr-w-min-30" circles={circles}>
            <span className="lg:tr-text-5xl md:tr-text-3xl sm:tr-text-5xl strong">
              {Math.round(_.mean([accountScore, systemsScore]))}%
            </span>
          </CircleGauge>
        </div>
        <Divider />
        <div className="tr-flex tr-flex-col tr-gap-2">
          {circles.map((circle, i) => {
            return (
              <div key={i} className="tr-flex tr-w-full tr-justify-between tr-text-xs">
                <div className="tr-flex tr-items-center tr-gap-1">
                  <div
                    className="tr-rounded-full tr-w-4 tr-h-4 tr-flex tr-justify-center tr-items-center"
                    style={{backgroundColor: circle.color}}
                  >
                    <div className="tr-rounded-full tr-w-2 tr-h-2" style={{backgroundColor: 'white'}} />
                  </div>
                  <span className="tr-font-bold tr-text-base">{i === 0 ? 'Account' : 'Systems'}</span>
                </div>
                <div
                  className="tr-font-bold tr-p-1.5 tr-rounded-lg tr-text-white tr-text-base"
                  style={{backgroundColor: circle.color}}
                >
                  {circle.percent}%
                </div>
              </div>
            );
          })}
        </div>
        <div className="tr-flex tr-justify-center">
          <Button onClick={() => history.push(`/resource/manage/${resourceId}/recommendations`)}>
            See All Recommendations
          </Button>
        </div>
      </div>
    </DashboardCardWidget>
  );
}
