import React from 'react';

type OverviewItemProps = {
  onClick: () => void;
  value: number | undefined;
  label: string;
  selected: boolean;
  loading?: boolean;
  defaultValue?: number | string;
};

const OverviewItem = (props: OverviewItemProps) => {
  const {onClick, value, label, selected, loading, defaultValue = 0} = props;

  let wrapperClassName = `tr-flex tr-flex-col tr-px-3 tr-py-2.5 hover:tr-bg-[#C2E5FF33] rounded-xl tr-min-w-[150px] tr-text-trustle-navy tr-cursor-pointer ${
    loading ? 'tr-animate-pulse' : ''
  }`;

  if (selected) {
    wrapperClassName += ' tr-bg-[#E9F4FD]';
  }

  return (
    <div className={wrapperClassName} onClick={onClick}>
      {
        <label className="tr-mb-0 tr-font-bold tr-text-sm md:tr-text-xl lg:tr-text-3xl xl:tr-text-6xl tr-cursor-pointer">
          {loading ? defaultValue : value?.toLocaleString()}
        </label>
      }

      <label className="tr-text-md tr-font-medium tr-cursor-pointer">{label}</label>
    </div>
  );
};

export default OverviewItem;
