import React, {useState} from 'react';
import Box from './Box';
import {Button} from '@trustle/component-library';
import {DataStatus} from '../../../common/DataStatus';
import {useRootStore} from 'src/lib/hooks';
import RequestAccessModal from 'src/components/modals/RequestAccessModal';
import {useHistory} from 'react-router-dom';

const NoAvailableSystems = () => {
  const {currentUser} = useRootStore();
  const userIsOrgOwner = currentUser.isOrgOwner;
  const [requestModalShowing, setRequestModalShowing] = useState(false);
  const handleRequestAccess = () => {
    setRequestModalShowing(true);
  };
  const history = useHistory();
  return (
    <Box className="text-center">
      <h2 className="text-left">Available Systems</h2>
      {/* This modal will be replaced with the new route for requesting access */}
      <RequestAccessModal show={requestModalShowing} onHide={() => setRequestModalShowing(false)} />
      <DataStatus
        status="searching"
        text="There are no available systems"
        description="You can try making a General access request or add a new system. However the system owner may still need to add that system."
      >
        <div className="tr-flex tr-justify-center tr-gap-x-110">
          <Button variant="secondary" onClick={() => handleRequestAccess()} size="lg">
            Request Access
          </Button>
          {userIsOrgOwner && (
            <Button onClick={() => history.push('/resources/create')} variant="primary" size="lg">
              Add System
            </Button>
          )}
        </div>
      </DataStatus>
    </Box>
  );
};

export default NoAvailableSystems;
