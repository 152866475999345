import React from 'react';
import {Search} from 'src/components/Search/Search';

const SearchBar = () => {
  return (
    <div className="tr-flex tr-flex-col">
      <h2>Available Systems</h2>
      <p>You’re Organization has many resources connected to Trustle. Let’s help you find what you are looking for.</p>
      <p>
        Select from the available systems below to find and request access to available resources, or you can search for
        something specific.
      </p>
      <div>
        <div className="w-1/2">
          <Search
            placeholder="Search available systems"
            //filterOptions={filterOptions as Record<string, (string | undefined)[]>}
            //initialFilters={{...props.fixedFilters}}
            onChange={(_query, _filters) => {
              //setQuery(query);
              //setFilters(filters);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
