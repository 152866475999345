import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import _ from 'lodash';

import {observer} from 'mobx-react';
import {formatAsDollarsAndCents} from 'src/lib';
import {useRootStore} from 'src/lib/hooks';
import './PlanDetailsForm.scss';
import {Formik, FormikForm} from 'src/components/design/formik';
import {Field} from 'formik';
import {NumberInput, Confirmation, Button} from '@trustle/component-library';
import axios from 'axios';
import {useToasts} from 'react-toast-notifications';

type PropsT = {
  plan: any;
};

const PlanDetailsForm = observer(function PlanDetailsFormCmp(props: PropsT) {
  const {plan} = props;
  const {org} = useRootStore();
  const [submitSubscription, setSubmitSubscription] = useState<{quantity: number} | undefined>();
  const [selectedPrice, setSelectedPrice] = useState<any>();
  const {addToast} = useToasts();

  useEffect(() => setSelectedPrice(org?.subscriptionPrice), [org?.subscriptionPrice]);

  if (_.isNil(org) || org.subscriptionPrice === undefined || org.subscription === undefined) {
    return <></>;
  }

  const quantity = org.subscription ? org.subscription.quantity : 5;

  return (
    <div className="plan-details ml-2 p-4 shadow-lg rounded-xl text-navy">
      <h1 className="bb-gray p-2">Plan Details</h1>
      <Formik
        key={`plandetailsform`}
        initialValues={{quantity: quantity}}
        onSubmit={async (values: {quantity: number}) => {
          setSubmitSubscription(values);
        }}
        validate={(values: any) => {
          const errors: _.Dictionary<string> = {};
          if (!values.quantity) {
            errors.quantity = 'required';
          }
          return errors;
        }}
        className="note-form"
      >
        {({values, isValid}) => {
          return (
            <FormikForm key={`plandetailsformik`}>
              <div className="bb-gray tr-p-4">
                <Field
                  component={NumberInput}
                  className="text-center"
                  data-testid="seats"
                  label="Organization Size"
                  description="How many users do you want to manage with Trustle?"
                  name="quantity"
                  id="quantity"
                />
              </div>
              <div className="tr-m-2 gray-bottom-border bb-gray tr-p-4">
                <div className="text-center">
                  <div className={`tr-flex form-control--text-input tr-text-left tr-mb-2`}>
                    <Form.Label>{'Billing Cycle'}</Form.Label>
                  </div>
                  <div>
                    <div className="tr-flex tr-justify-between tr-items-center">
                      {plan?.prices.map((price: any, index: number) => {
                        return (
                          <div className="tr-w-full" key={price.id + index}>
                            <Button
                              selected={selectedPrice?.id === price.id}
                              onClick={() => {
                                setSelectedPrice(price);
                              }}
                            >
                              <div className="m-2">
                                <span>{`Per ${_.startCase(price.recurring?.interval)}`}</span>
                              </div>
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                    <div className="tr-flex tr-justify-between tr-items-center">
                      {plan?.prices.map((price: any, index: number) => {
                        return (
                          <div key={`${price.id + index}key`}>
                            <div className="body6 grayed-out">
                              {price.recurring?.interval === 'year' ? `*2 months for free` : ''}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {selectedPrice && (
                <div className="tr-mt-4 tr-mb-4 tr-p-4 tr-flex tr-justify-center tr-align-center">
                  <div className="body1 tr-flex tr-justify-between tr-align-center">
                    <div className="body4 tr-pr-5">Estimated Subtotal</div>
                    <div className="body3 ">
                      {formatAsDollarsAndCents((selectedPrice.unit_amount / 100) * values.quantity)}
                      <span>{`/ ${_.startCase(selectedPrice.recurring?.interval)}`}</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="tr-flex">
                <div className="tr-text-right body1 ">
                  <Button disabled={!isValid} type="submit" className="p-3">
                    Update
                  </Button>
                </div>
              </div>
              {submitSubscription && (
                <Confirmation
                  onConfirm={async () => {
                    if (org.subscription.cancel_at) {
                      await axios.post(`/api/payments/subscription/enable`);
                    }

                    try {
                      await org.updateSubscription({...submitSubscription, priceId: selectedPrice.id});
                    } catch (err) {
                      addToast('Error trying to update the subscription.', {
                        appearance: 'error',
                        autoDismiss: false,
                      });
                      throw err;
                    }

                    setSubmitSubscription(undefined);
                  }}
                  onClose={() => {
                    setSubmitSubscription(undefined);
                  }}
                  title={'Confirm plan change'}
                >
                  <span>
                    <div>{`Please, confirm that you want to change your ${plan.name} plan?`}</div>
                    {values.quantity !== quantity && <div>{`Seats: ${values.quantity} instead of ${quantity}`}</div>}
                    <div>{`Billing: per ${_.startCase(selectedPrice.recurring?.interval)}`}</div>
                  </span>
                </Confirmation>
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
});

export default PlanDetailsForm;
