import React, {useEffect, useState} from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import CircleStep from 'src/components/CircleStep';
import {useRootStore} from 'src/lib/hooks';
import _ from 'lodash';
import {Accordion} from '@trustle/component-library';
import Highlight from 'src/components/highlightjs/Highlight';

function ConfigureM365() {
  const {org} = useRootStore();
  const [scriptContent, setScriptContent] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  if (_.isNil(org)) {
    return <></>;
  }

  useEffect(() => {
    const fetchScriptContent = async () => {
      try {
        const response = await fetch('/docs/connectors/m365/RegisterAppForAppOnlyAuth.ps1'); // Adjust the path accordingly
        if (!response.ok) {
          throw new Error('Failed to fetch script');
        }
        const content = await response.text();
        setScriptContent(content);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    void fetchScriptContent();
  }, []);

  const tabs: TabsDocsT = [
    {id: 'm365-overview', label: 'Overview', description: overview(), active: true},
    {id: 'm365-supported-features', label: 'Supported Features', description: supportedFeatures(), active: false},
    {id: 'm365-configuration-steps', label: 'Configuration Steps', description: configurationSteps(), active: false},
  ];

  function Step(props: {stepNumber: number; title: string}): JSX.Element {
    const {stepNumber, title} = props;
    return (
      <p className="pt-3">
        <CircleStep>{stepNumber}</CircleStep>
        <span className="body5">{title}</span>
      </p>
    );
  }

  function Item(props: {attribute?: string; value: string}): JSX.Element {
    const {attribute, value} = props;
    return (
      <li>
        {attribute && `${attribute}:`} <code className="text-info">{value}</code>
      </li>
    );
  }

  function overview(): React.ReactNode {
    return (
      <div className="tr-w-75">
        <p>
          The following guide describes the required steps to configure a connection between Trustle and your tenant in
          Microsoft 365.
        </p>
        <p>This connector will allow Trustle to retrieve the data from Microsoft 365</p>
        <p>
          With this connector, you'll effortlessly handle group memberships, access detailed permissions for each group,
          and analyze licenses, among other functionalities.
        </p>
        <p>
          If you have any questions, please contact us at{' '}
          <a href="mailto:support@trustle.com" rel="noreferrer">
            support@trustle.com
          </a>
          .
        </p>
      </div>
    );
  }

  function supportedFeatures(): React.ReactNode {
    return (
      <>
        <p>Trustle currently supports the following features:</p>
        <div>
          <ul>
            <li>Importing Users and Groups with memberships</li>
            <li>
              Add a user as Owner or Member of a group. This feature allows you to manage{' '}
              <a
                href="https://learn.microsoft.com/en-us/sharepoint/site-permissions?WT.mc_id=365AdminCSH_spo"
                target="_blank"
                rel="noreferrer"
              >
                site membership
              </a>
              :
            </li>
            <ul>
              <li>
                <b>Owners: </b>
                Microsoft 365 group owners can manage group membership, privacy, and classification, as well as the
                associated SharePoint site. If the Microsoft 365 group is associated with a team, then the group owners
                are also team owners.
              </li>
              <li>
                <b>Members: </b>
                Microsoft 365 group members can participate in the group and have access to the associated SharePoint
                site. If the Microsoft 365 group is associated with a team, then the group members are also team members
                and can send messages and participate in channels if allowed by the team owner.
              </li>
            </ul>
            <li>Importing Licenses</li>
            <li>Importing users and groups memberships for licenses</li>
            <li>Importing usage and metadata of entities</li>
          </ul>
        </div>
      </>
    );
  }

  function configurationStepsLegend(): React.ReactNode {
    return (
      <>
        To get started with Trustle, you'll need to register an app in your Microsoft enterprise admin panel. We give
        you two ways to do this.
        <div className="my-2">
          <p>
            <u>IMPORTANT</u>: The following steps need to be performed by an account with{' '}
            <strong>Global Administrator</strong> role with <strong>Read</strong> access to the subscriptions for the
            tenant.
          </p>
        </div>
        <ol className="my-3">
          <li>
            <b>Manual Registration</b>: Follow a set of steps to register the app manually.
          </li>
          <li>
            <b>PowerShell Script</b>: Run a PowerShell script that automates the process for you. Just follow the
            instructions that come with it.
          </li>
        </ol>
        <Accordion
          variant="primary"
          size="sm"
          content={
            <div className="tr-flex items-align-center pl-3 pr-2">
              <span>
                <b>1. Manual Registration</b>.
              </span>
            </div>
          }
        >
          {() => {
            return <div className="p-2">{configurationStepsManual()}</div>;
          }}
        </Accordion>
        <Accordion
          variant="primary"
          size="sm"
          content={
            <div className="tr-flex items-align-center pl-3 pr-2">
              <span>
                <b>2. PowerShell Script</b>.
              </span>
            </div>
          }
        >
          {() => {
            return <div className="p-2">{configurationStepsPowershell()}</div>;
          }}
        </Accordion>
      </>
    );
  }

  function configurationStepsManual(): React.ReactNode {
    return (
      <>
        <div className="tr-w-full tr-mb-5">
          <Step stepNumber={1} title="REGISTER A NEW APP IN Microsoft Entra Admin Center" />
          <div className="mx-5">
            <span>
              Go to <a href="https://entra.microsoft.com/">Microsoft Entra Admin</a> and look for{' '}
              <u>Identity &gt; Applications &gt; App Registrations</u> service.
            </span>
            <p>Register a new application with the following parameters:</p>
            <p className="ml-5 mb-3">
              <Item attribute="Name" value="Trustle App" />
              <Item
                attribute="Supported account types"
                value="Accounts in this organizational directory only (Default Directory only - Single tenant)"
              />
            </p>
            <div className="image-wrap">
              <img src="/docs/connectors/m365/1_app_registration.png" className="vertical-align mb-4 mt-3 shadow" />
            </div>
            <div className="image-wrap">
              <img src="/docs/connectors/m365/2_new_register.png" className="vertical-align mb-4 mt-3 shadow" />
            </div>
            <div className="image-wrap">
              <img src="/docs/connectors/m365/3_new_app.png" className="vertical-align mb-4 mt-3 shadow" />
            </div>
          </div>

          <Step stepNumber={2} title="CONFIGURE YOUR APP CREDENTIALS AND SCOPED PERMISSIONS" />
          <div className="mx-5">
            Inside your app, go to <u>Certificates &amp; secrets</u> section, add a New client secret and save its value
            for later.
            <div className="ml-3">
              <cite>
                Keep in mind the secret value will only be displayed while you are in this page. Once you leave, it
                won't be visible again and you'll need to generate a new one.
              </cite>
            </div>
            <div className="image-wrap">
              <img src="/docs/connectors/m365/4_certificates_secrets.png" className="vertical-align mb-4 mt-3 shadow" />
            </div>
            <div className="image-wrap">
              <img
                src="/docs/connectors/m365/4_1_certificates_secrets.png"
                className="vertical-align mb-4 mt-3 shadow"
              />
            </div>
            <div className="image-wrap">
              <img
                src="/docs/connectors/m365/4_2_certificates_secrets.png"
                className="vertical-align mb-4 mt-3 shadow"
              />
            </div>
            <div className="my-4 dropdown-divider"></div>
            <p>
              Next, go to <u>API permissions</u> and add the following permissions:
            </p>
            <div className="ml-3">
              <li>
                <strong>Microsoft.Graph / Application permissions</strong>
                <ul>
                  <Item value="AuditLog.Read.All"></Item>
                  <Item value="Directory.Read.All"></Item>
                  <Item value="Directory.ReadWrite.All"></Item>
                  <Item value="Group.Read.All"></Item>
                  <Item value="Group.ReadWrite.All"></Item>
                  <Item value="GroupMember.Read.All"></Item>
                  <Item value="GroupMember.ReadWrite.All"></Item>
                  <Item value="Sites.FullControl.All"></Item>
                  <Item value="User.Read"></Item>
                  <Item value="User.Read.All"></Item>
                  <Item value="User.ReadWrite.All"></Item>
                  <Item value="Organization.ReadWrite.All"></Item>
                  <Item value="Reports.Read.All"></Item>
                </ul>
              </li>
              <li>
                <strong>Office 365 Management APIs / Application permissions</strong>
                <ul>
                  <Item value="ActivityFeed.Read"></Item>
                </ul>
              </li>
              <div>Click on "Grant admin consent for Default Directory"</div>
            </div>
            <div className="image-wrap">
              <img src="/docs/connectors/m365/5_api_permissions.png" className="vertical-align mb-4 mt-3 shadow" />
            </div>
            <div className="image-wrap">
              <img src="/docs/connectors/m365/5_1_api_permissions.png" className="vertical-align mb-4 mt-3 shadow" />
            </div>
            <div className="my-4 dropdown-divider"></div>
            <p>
              Go to the <u>Overview</u> tab and copy the following values, they will be required on the next steps:
            </p>
            <div className="ml-3">
              <li>Application (client) ID</li>
              <li>Directory (tenant) ID</li>
            </div>
            <div className="image-wrap">
              <img src="/docs/connectors/m365/6_values.png" className="vertical-align mb-4 mt-3 shadow" />
            </div>
          </div>

          <Step stepNumber={4} title="CREATE A NEW CONNECTOR IN TRUSTLE" />
          <div className="mx-5">
            <li>First, Add a new system selecting Microsoft 365.</li>
            <li>Then, fill the general form providing a Name for your connector and fill all the required values.</li>
            <li>
              During the <u>Authentication setup</u> step, use the values provided in your new app registration to fill
              the form for Microsoft 365 connector.
              <ul>
                <li>Click on Test button to check that the connections succeed</li>
              </ul>
            </li>
            <li>Finally, click Continue and finish setting up the connector</li>
          </div>
        </div>
      </>
    );
  }

  function configurationStepsPowershell(): React.ReactNode {
    return (
      <>
        <div>
          To use PowerShell, you'll need the Microsoft Graph PowerShell SDK. If you do not have it, see{' '}
          <a href="https://learn.microsoft.com/graph/powershell/installation" target="_blank">
            Install the Microsoft Graph PowerShell SDK
          </a>{' '}
          for installation instructions.
        </div>
        <div className="tr-w-full tr-mb-5">
          <Step stepNumber={1} title="DOWNLOAD THE SCRIPT TO REGISTER THE APPLICATION AUTOMATICALLY" />
          <div className="mx-5">
            <a href="/docs/connectors/m365/RegisterAppForAppOnlyAuth.ps1" download>
              Click here
            </a>{' '}
            to download the Register Application for App Only Authentication powershell script or copy and paste the
            following script to a <code>RegisterAppForAppOnlyAuth.ps1</code> file.
          </div>
          <div className="tr-ml-12">
            {!loading && !error && (
              <Highlight language="powershell" showCopy={true}>
                {scriptContent}
              </Highlight>
            )}
          </div>

          <Step stepNumber={2} title="EXECUTE THE SCRIPT" />
          <div className="mx-5">
            Open PowerShell and run the <code>RegisterAppForAppOnlyAuth.ps1</code> file with the following command:{' '}
            <div className="tr-bg-black tr-text-white tr-px-2 tr-py-2">
              <code className="tr-text-white">.\RegisterAppForAppOnlyAuth.ps1</code>
            </div>
            <div className="tr-mt-2">Follow the instructions</div>
          </div>

          <Step stepNumber={3} title="COPY THE VALUES FROM THE SCRIPT OUTPUT" />
          <div className="mx-5">
            <div>The output of the previous script will be something like this:</div>
            <div className="tr-bg-black tr-text-white tr-px-2 tr-py-2">
              <div>
                <code className="tr-text-white">SUCCESS</code>
              </div>
              <div>
                <code className="tr-text-white">Client ID: ae2386e6-799e-4f75-b191-855d7e691c75 </code>
              </div>
              <div>
                <code className="tr-text-white">Tenant ID: 5927c10a-91bd-4408-9c70-c50bce922b71</code>
              </div>
              <div>
                <code className="tr-text-white">Client secret: ... Secret expires: 10/28/2024 5:01:45 PM</code>
              </div>
            </div>
            <div>
              Copy the <b>Client ID</b>, <b>Tenant ID</b>, and <b>Client secret</b> values from the script output. You
              will need these values in the next step.
            </div>
          </div>
          <Step stepNumber={4} title="CREATE A NEW CONNECTOR IN TRUSTLE" />
          <div className="mx-5">
            <li>First, Add a new system selecting Microsoft 365.</li>
            <li>Then, fill the general form providing a Name for your connector and fill all the required values.</li>
            <li>
              During the <u>Authentication setup</u> step, use the values provided in your new app registration to fill
              the form for Microsoft 365 connector.
              <ul>
                <li>Click on Test button to check that the connections succeed</li>
              </ul>
            </li>
            <li>Finally, click Continue and finish setting up the connector</li>
          </div>
        </div>
      </>
    );
  }

  function configurationSteps(): React.ReactNode {
    return <>{configurationStepsLegend()}</>;
  }

  return (
    <>
      <DocSection tabs={tabs} title="How to configure Microsoft 365 connector in Trustle" />
    </>
  );
}

export default ConfigureM365;
