import {Icon} from '@trustle/component-library';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {ConnectionServiceE} from 'src/types';
import {usePageNavigator} from 'src/views/v2/hooks/usePageNavigator';
import {AccountQueryFiltersAndPagination} from '../../accounts/panel/AccountsFiltersContext';

type Item = {
  label: string;
  count: number;
  navigateFunction: () => void;
  icon: React.ReactNode;
};

export function useAccountsResourcesRecomendations(resourceId: string, connectorService?: ConnectionServiceE) {
  const RESOURCE_PATH = `/resource/manage/${resourceId}`;
  const RECOMMENDATIONS_PATH = `${RESOURCE_PATH}/recommendations`;

  const history = useHistory();
  const {navigate} = usePageNavigator<AccountQueryFiltersAndPagination>({
    path: `${RESOURCE_PATH}/accounts`,
    defaultParams: {page: 1, size: 10},
  });
  const ACCOUNTS_ITEMS_COUNTS: Item[] = [
    {
      label: 'Unlinked',
      count: 10,
      navigateFunction: () => navigate({linkStatus: 'unlinked'}),
      icon: <Icon type="unlinked" />,
    },
    {
      label: 'Linked',
      count: 10,
      navigateFunction: () => navigate({linkStatus: 'linked'}),
      icon: <Icon type="linked" />,
    },
    {
      label: 'Flagged',
      count: 10,
      navigateFunction: () => navigate({linkStatus: 'flagged'}),
      icon: <Icon type="flagOff" />,
    },
    {
      label: 'Unused Accounts',
      count: 10,
      navigateFunction: () => history.push(`${RECOMMENDATIONS_PATH}/accounts/Unused Accounts`),
      icon: <Icon type="groupPeople" />,
    },
    {
      label: 'Access Review',
      count: 10,
      navigateFunction: () => history.push(`${RECOMMENDATIONS_PATH}/accounts/Access Review`),
      icon: <Icon type="needsMapping" />,
    },
  ];

  const RESOURCES_ITEMS_COUNTS: Item[] = [
    {
      label: 'Managed By Trustle',
      count: 10,
      navigateFunction: () => {},
      icon: <Icon type="autoProvisionEnabled" />,
    },
    {
      label: 'Unmanaged',
      count: 10,
      navigateFunction: () => {},
      icon: <Icon type="autoProvisionDisabled" />,
    },
    {
      label: 'Imported/Unlinked',
      count: 10,
      navigateFunction: () => {},
      icon: <Icon type="needsMapping" />,
    },
    {
      label: 'System Recommendations',
      count: 10,
      navigateFunction: () => history.push(`${RECOMMENDATIONS_PATH}/system/System Recommendations`),
      icon: <Icon type="resource" />,
    },
  ];

  if (connectorService === ConnectionServiceE.AWS) {
    RESOURCES_ITEMS_COUNTS.push({
      label: 'Tokens And Cert Recommendations',
      count: 10,
      navigateFunction: () => history.push(`${RECOMMENDATIONS_PATH}/system/Tokens and Certificates`),
      icon: <Icon type="autoProvisionEnabled" />,
    });
  }

  return {ACCOUNTS_ITEMS_COUNTS, RESOURCES_ITEMS_COUNTS};
}
