import {useState} from 'react';
import axios from 'axios';

type LinkUsersResponse = {
  success: boolean;
};

export type LinkList = {
  userId: string;
  accountId: string;
  accountType: string;
};

export type LinkUsersParams = {
  systemId: string;
  linkList: LinkList[];
};

export function useLinkUsers() {
  const [success, setSuccess] = useState<boolean>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/principals/link_users';

  const linkUsers = async (params: LinkUsersParams) => {
    setLoading(true);
    try {
      const body = {
        ...params,
      };
      const {data: responseData} = await axios.post<LinkUsersResponse>(BASE_URL, body);
      setSuccess(responseData.success);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return {success, loading, error, linkUsers};
}
