import React, {createContext, PropsWithChildren, useContext, useEffect} from 'react';
import {useTeamFilters} from './TeamsFiltersContext';
import {useTeams} from '../admin-users-panel/hooks/useTeams';

type TeamsListedContextType = {
  listTeamsResponse: any;
  loading: boolean;
  refetchTeams: () => Promise<void>;
};

const TeamsListedContext = createContext<TeamsListedContextType | undefined>(undefined);

type TeamsListedProviderProps = PropsWithChildren<Record<string, unknown>>;

export const TeamsListedProvider: React.FC<TeamsListedProviderProps> = ({children}) => {
  const {data: listTeamsResponse, loading, fetchTeams} = useTeams();
  const {filters} = useTeamFilters();

  useEffect(() => {
    void fetchTeams({...filters});
  }, [filters]);

  const refetchTeams = async () => {
    await fetchTeams({...filters});
  };

  return (
    <TeamsListedContext.Provider
      value={{
        listTeamsResponse,
        loading,
        refetchTeams,
      }}
    >
      {children}
    </TeamsListedContext.Provider>
  );
};

export const useTeamsListed = () => {
  const context = useContext(TeamsListedContext);
  if (context === undefined) {
    throw new Error('useTeamsListed must be used within a TeamsListedProvider');
  }
  return context;
};
