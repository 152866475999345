import axios from 'axios';
import {useState} from 'react';
import {useRootStore} from 'src/lib/hooks';

export type SystemSummaryResponse = {
  system_accounts: number;
  trustle_users: number;
  teams: number;
  departments: number;
  roles: number;
  titles: number;
};

type Props = {
  systemId: string;
};

export function useSystemSummary({systemId}: Props) {
  const [data, setData] = useState<SystemSummaryResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {toast} = useRootStore();

  const BASE_URL = `/api/v2/systems/${systemId}/system_summary`;

  const getUsersSummary = async () => {
    setLoading(true);
    try {
      const response = await axios.get<SystemSummaryResponse>(`${BASE_URL}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      setError(true);
      toast.error('There was an error trying to get system summary');
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, getUsersSummary};
}
