import React, {useEffect, useState} from 'react';
import {Table} from '@trustle/component-library';
import {useTeams} from '../../../hooks/useTeams';
import {DataStatus} from '../../../../../common/DataStatus';
import {useTeamsColumns} from './useTeamsColumns';
import {useUserFilters} from '../../../UserFiltersContext';
import {useDebounce} from 'src/lib/hooks';
import {HeaderSearch} from '../../../../../common/HeaderSearch';

export function TeamsSummaryTable() {
  const {data: listTeamsResponse, loading, fetchTeams} = useTeams();
  const {filters} = useUserFilters();

  const COLUMNS = useTeamsColumns();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);
  const [search, setSearch] = useState('');

  useEffect(() => {
    void fetchTeams({page, size, status: filters.status, searchTerm: search});
  }, [page, size, search, filters.status]);

  const handleDebouncedSearch = useDebounce(
    (value: string) => {
      setSearch(value);
    },
    1000,
    []
  );

  if (loading || !listTeamsResponse) {
    return <DataStatus text="Loading users..." status="loading" description="Please stand by" />;
  }

  const {teams, total} = listTeamsResponse;

  return (
    <>
      <HeaderSearch
        showSearch
        handleSearch={handleDebouncedSearch}
        title="Teams"
        icon="team"
        placeholder="Search for teams"
        defaultValue={search}
      />
      <Table
        data={teams}
        columns={COLUMNS}
        tableKey="teams-summary-table"
        totalSize={total}
        sizePerPage={size}
        page={page}
        remote={{
          filter: true,
          pagination: true,
          sort: false,
          cellEdit: false,
        }}
        onSizePerPageChange={(size) => {
          setSize(size);
          return {};
        }}
        onTableChange={(_type: string, {page}: {page: number}) => {
          setPage(page);
        }}
        showEmptyElement={<DataStatus status="no-data" text="No teams found" />}
      />
    </>
  );
}
