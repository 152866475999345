import {useState} from 'react';
import axios from 'axios';
import {AccountAccessType} from 'src/types';

type LinkUserResponse = {
  success: boolean;
};

type LinkUserParams = {
  userId: string;
  accountId: string;
  systemId: string;
  accountType: AccountAccessType;
};

export function useLinkUser() {
  const [success, setSuccess] = useState<boolean>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/principals/link_user';

  const linkUser = async (params: LinkUserParams) => {
    setLoading(true);
    try {
      const body = {
        ...params,
      };
      const {data: responseData} = await axios.post<LinkUserResponse>(BASE_URL, body);
      setSuccess(responseData.success);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return {success, loading, error, linkUser};
}
