import React from 'react';
import {Badge} from '@trustle/component-library';

export enum BadgeVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  LIGHT = 'light',
  DARK = 'dark',
}

type PropsT = {
  variant: BadgeVariant;
  text: string;
};

export function TrustleBadge(props: PropsT) {
  const {variant, text} = props;

  return (
    <Badge className="text-white" pill variant={variant}>
      {text}
    </Badge>
  );
}

export default {TrustleBadge, BadgeVariant};
