import axios from 'axios';
import {useState} from 'react';
import {parseObjectToQueryString} from 'src/views/v2/utils';
import {PaginationParams, UserStatus} from '../types';
export type UserCountByItem = {
  userDetailsType: string;
  userDetailsValue: string;
  usersCount: number;
  lastActivity: string;
};

type UsersCountByResponse = {
  items: UserCountByItem[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

type UsersCountByParams = {
  filter: 'department' | 'role' | 'title';
  status: UserStatus;
  searchTerm?: string;
} & PaginationParams;

export function useUsersCountBy() {
  const [data, setData] = useState<UsersCountByResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/users/countBy';

  const getUsersCountedBy = async (params: UsersCountByParams) => {
    const {page, size, ...rest} = params;

    const parsedParams: Record<string, string> = {
      page: (page || 1).toString(),
      size: (size || 50).toString(),
      ...rest,
    };

    const queryString = parseObjectToQueryString(parsedParams);

    setLoading(true);
    try {
      const response = await axios.get<UsersCountByResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, getUsersCountedBy};
}
