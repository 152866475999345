import React from 'react';

type Props = {title: string; subtitle: string; onClick?: () => void; appendDivider?: boolean};

export function WidgetHeaderItem({title, subtitle, onClick, appendDivider = false}: Props) {
  return (
    <div className="tr-flex">
      <div className={`tr-flex tr-items-center ${onClick ? 'tr-cursor-pointer' : ''}`}>
        <div
          className={`tr-flex tr-flex-col  tr-rounded-lg tr-p-2 ${onClick ? 'hover:tr-bg-[#C2E5FF33]' : ''}`}
          onClick={onClick}
        >
          <label className="tr-text-trustle-navy tr-mb-0 tr-font-bold tr-text-4xl">{title}</label>
          <strong className="tr-text-gray-400">{subtitle}</strong>
        </div>
      </div>
      {appendDivider && <div className="tr-w-[1px] tr-bg-gray-300 tr-my-4 tr-ml-4" />}
    </div>
  );
}
