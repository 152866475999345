import {
  Alert,
  Button,
  Confirmation,
  Icon,
  IconButton,
  Modal,
  PrefixInput,
  Table,
  UserInfo,
} from '@trustle/component-library';
import {RolesType} from '@trustle/component-library/dist/types';
import axios from 'axios';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Authority, DepartmentRoleTypeCol} from 'src/components/design/tables/SharedColumnDefinitions/UserColumns';
import {formatUserName, useDebounce} from 'src/lib';
import {SourceOfTruthE, TeamT, TeamTypeEnum, UserT} from 'src/types';
import OrgEvents from 'src/views/Events/OrgEvents';
import {DataStatus} from '../../common/DataStatus';
import {WidgetHeaderItem} from '../../dashboard/widgets/shared/WidgetHeaderItem';
import {useUsers} from '../admin-users-panel/hooks/useUsers';
import {QuickFilter} from '../admin-users-panel/internal/quick-filter/QuickFilter';
import {UsersFilterProvider, useUserFilters} from '../admin-users-panel/UserFiltersContext';
import AddMembersModal from './AddMembersModal';
import TeamForm from './TeamForm';

export function TeamMembersPanel({team, users, summary}: {team: TeamT; users: UserT[]; summary: any}) {
  return (
    <UsersFilterProvider
      defaultFilters={{
        team: team.id,
        page: 1,
        size: 10,
      }}
    >
      <TeamMembers team={team} users={users} summary={summary} />
    </UsersFilterProvider>
  );
}

export function TeamMembers({team, users, summary}: {team: TeamT; users: UserT[]; summary: any}) {
  const {data: teamMembersResponse, loading, fetchUsers} = useUsers();

  const [editingTeam, setEditingTeam] = useState<boolean>(false);
  const history = useHistory();
  const [addToTeam, setAddToTeam] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [columnSort, setColumnSort] = useState<string | undefined>(undefined);
  const [error, setError] = useState<any>();
  const {filters, setFilter, setFilters} = useUserFilters();
  const [userToDelete, setUserToDelete] = useState<UserT>();

  useEffect(() => {
    void fetchUsers({...filters});
  }, [filters]);

  const handleDebouncedSearch = useDebounce(
    (value: string) => {
      setFilter('nameOrEmail', value);
    },
    1000,
    [filters]
  );

  const multipleSortHandler = (field: string) => {
    setColumnSort(field);
    setFilters({...filters, sort: field === filters.orderBy ? 'asc' : 'desc', orderBy: field});
  };

  const columns = [
    {
      dataField: 'firstname',
      text: 'Name',
      sort: true,
      formatter: (_firstname: string, user: UserT) => (
        <UserInfo type={user.type as RolesType} isOrgOwner={user.isOrgOwner} className="tr-text-trustle-link">
          <Link className="" to={`/users/${user.id}/current`}>
            {formatUserName(user)}
          </Link>
        </UserInfo>
      ),
    },
    {
      dataField: 'permissionsCount',
      sort: true,
      text: 'Systems / Permissions',
      formatter: (cell: any) => {
        return (
          <div className="tr-flex tr-pl-4 tr-items-center">
            <Icon type="permission" />
            {cell}
          </div>
        );
      },
    },
    {...Authority},
    DepartmentRoleTypeCol({idpFeatureEnabled: true, columnSort, setColumnSort: multipleSortHandler}),
    {
      dataField: 'actions',
      headerStyle: {width: '4rem'},
      style: {width: '4rem'},
      classes: 'show-on-hover',
      text: '',
      formatter: (cell: any, u: UserT) => {
        const items = [];

        items.push(
          <IconButton
            icon="details"
            variant="ternary"
            key={`viewmember${u.id}`}
            title="View member"
            data-testid="view-member"
            onClick={(e: any) => {
              e.stopPropagation();
              history.push(`/users/${u.id}`);
            }}
          />
        );

        const isTrustleSoT = _.defaultTo(u.authority, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE;

        if (isTrustleSoT && team && team.type === TeamTypeEnum.ADHOC) {
          items.push(
            <IconButton
              icon="cancel"
              variant="ternary"
              key={`removemember${u.id}`}
              title="Remove member"
              data-testid={`removemember${u.id}`}
              onClick={(e: any) => {
                e.stopPropagation();
                setUserToDelete(u);
              }}
            />
          );
        }

        return <div className="flex-align tr-justify-center action-buttons">{items}</div>;
      },
    },
  ];

  if (loading && !teamMembersResponse) {
    return <DataStatus text="Loading team members..." status="loading" description="Please stand by" />;
  }

  const members = teamMembersResponse ? teamMembersResponse.users : [];
  const total = teamMembersResponse ? teamMembersResponse.total : 0;
  const isRemote = total > Number(filters.size);

  return (
    <div className={`tr-flex tr-flex-col tr-w-full ${loading ? 'tr-animate-pulse' : ''}`}>
      {summary && summary.usersCount > 0 && (
        <div className="tr-flex tr-p-4 tr-gap-6 tr-w-full">
          <WidgetHeaderItem title={summary.usersCount} subtitle="Members" appendDivider />
          <WidgetHeaderItem title={summary.titlesCount} subtitle="Titles" appendDivider />
          <WidgetHeaderItem title={summary.departmentsCount} subtitle="Departments" appendDivider />
          <WidgetHeaderItem title={summary.rolesCount} subtitle="Roles" />
        </div>
      )}
      {error && (
        <Alert colorVariant={'danger'} onClose={() => setError(undefined)}>
          {error.message}
        </Alert>
      )}
      <div className="tr-flex tr-items-center tr-gap-3 tr-bg-[#F8F9FC] tr-p-2.5 ">
        <Icon type={'team'} size="md" />
        <h2 className="tr-mb-0">{'Members'}</h2>
        <PrefixInput
          prefixIcon="search"
          placeholder="Search for members"
          className="tr-min-w-[300px] tr-max-w-[600px]"
          onInput={(e: any) => {
            handleDebouncedSearch(e.target.value);
          }}
        />
        <Button variant="ternary" onClick={() => setShowHistory(true)}>
          View history
        </Button>
        {team.type === TeamTypeEnum.ADHOC && (
          <Button
            key="editTeam"
            data-testid="editTeamButton"
            id="editTeamButton"
            onClick={() => {
              setAddToTeam(true);
            }}
          >
            {'Add members'}
          </Button>
        )}
      </div>

      <QuickFilter usersSummary={summary} />

      <Table
        data={members}
        columns={columns}
        tableKey="teams-members-table_2"
        sizePerPage={filters.size}
        page={filters.page}
        totalSize={total}
        remote={
          isRemote
            ? {
                filter: true,
                pagination: true,
                sort: true,
                cellEdit: false,
              }
            : false
        }
        onSizePerPageChange={(size) => {
          setFilter('size', size);
          return {};
        }}
        onTableChange={(type: string, props: any) => {
          if (isRemote) {
            if (type === 'sort') {
              setFilters({...filters, sort: props.sortOrder, orderBy: props.sortField});
            } else {
              setFilter('page', props.page);
            }
          }
        }}
        showEmptyElement={<DataStatus status="no-data" text="No members found" />}
      />
      <Modal
        width="lg"
        onClose={() => {
          setEditingTeam(false);
        }}
        visible={editingTeam}
        title={'Edit Team'}
      >
        <TeamForm
          team={team}
          users={users}
          members={members as any}
          onClose={() => {
            setEditingTeam(false);
          }}
          onAfterSubmit={() => {
            setEditingTeam(false);
          }}
        />
      </Modal>
      <Modal
        width="md"
        height="xs"
        visible={addToTeam}
        onClose={() => {
          setAddToTeam(false);
        }}
        title="Add Members to Team"
      >
        <AddMembersModal
          members={members as any}
          team={team}
          afterSubmit={() => {
            setAddToTeam(false);
          }}
        />
      </Modal>
      <Modal visible={!!showHistory} onClose={() => setShowHistory(false)} width="lg" title="History Team">
        <OrgEvents entityType={'teams'} id={team.id} />
      </Modal>
      {userToDelete && (
        <Confirmation
          onConfirm={async () => {
            setUserToDelete(undefined);
            try {
              await axios.delete(`/api/teams/${team.id}/members/${userToDelete.id}`);
              setFilters({...filters});
            } catch (err: any) {
              setError(err);
            }
          }}
          onClose={() => setUserToDelete(undefined)}
          title="Confirm member removal"
        >
          {`Please confirm that you want to remove ${formatUserName(userToDelete)} from this group`}
        </Confirmation>
      )}
    </div>
  );
}
