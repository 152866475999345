import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import React, {useContext, useEffect, useState} from 'react';
import Routes, {OrgInfoT} from './Routes/Component';
import CookiesConsentPopup from './components/design/CookiesConsentPopup';
import axios from 'axios';
import {GlobalInfoContext} from './context';
import {useCookies} from 'react-cookie';
import {loadScript} from './utils';
import {Redirect, Route, Switch} from 'react-router-dom';
import EntryPage from './views/EntryPage';
import Error from 'src/components/Error';
import HomePage from 'src/views/HomePage';
import MagicLinkVerify from './views/MagicLinkVerify';
import {Loading} from '@trustle/component-library';
import NotFound from './views/NotFound';

// Hubspot
declare global {
  interface Window {
    _hsq: any;
    dataLayer: any;
  }
}

const AppContainer: React.FC = () => {
  const {get, set} = useContext(GlobalInfoContext);
  const [cookies] = useCookies();
  const [orgInfo, setOrgInfo] = useState<OrgInfoT | undefined>(undefined);
  const cookiesEnabled = get('cookies-enabled');

  useEffect(() => {
    if (!window?.location) {
      setOrgInfo({found: false});
      return;
    }

    const {host} = window.location;

    if (host.startsWith('app') || host.startsWith('local')) {
      setOrgInfo({found: false});
      return;
    }

    const fetchOrgInfo = async () => {
      const [orgHostname] = host.split('.');
      const response = await axios.get(`/api/orgs/checkhost/${orgHostname}`);
      setOrgInfo(response.data);
    };
    void fetchOrgInfo();
  }, []);

  if (cookiesEnabled === undefined) {
    void axios.get<boolean>('/api/auth/enable_cookies_banner').then((res) => set('cookies-enabled', res.data));
    // avoid additional rendering while we wait for the response
    return <></>;
  }

  if (cookies['cookies-consent']) {
    loadScript({
      id: 'jsd-widget',
      src: 'https://jsd-widget.atlassian.com/assets/embed.js',
      'data-jsd-embedded': '',
      'data-key': '70716556-aa72-4d42-9c04-80a4004244a5',
      'data-base-url': 'https://jsd-widget.atlassian.com',
    });

    if (cookies['cookies-consent'].categories?.includes('analytical')) {
      loadScript({id: 'hs-script-loader', src: '//js.hs-scripts.com/9026568.js'});
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['setPath', window.location.pathname + window.location.search + window.location.hash]);
      _hsq.push(['trackPageView']);

      //Google tag (gtag.js)
      loadScript({id: 'google-tag-manager', src: 'https://www.googletagmanager.com/gtag/js?id=G-1J3MV1LFJJ'});
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push('js', new Date());
      window.dataLayer.push('config', 'G-1J3MV1LFJJ');
    }
  }

  if (!orgInfo) {
    return <Loading />;
  }

  const entryPages = ['/login', '/find_orgs', '/show_orgs', '/forgot_pw', '/signin'];
  if (!orgInfo.found) {
    entryPages.push('/signup');
  }

  return (
    <>
      <Switch>
        <Route path="/magiclink/:token" render={() => <MagicLinkVerify />} />
        <Route path="/" exact={true} render={() => <HomePage />} />
        <Route path={entryPages} component={() => <EntryPage orgInfo={orgInfo} />} />
        {/* -------------------------- Redirects --------------------------  */}
        <Redirect from="/user/show_orgs" to="/show_orgs" />
        <Route
          path={['/user/login', '/user/login?:returnUrl']}
          render={({location}) => <Redirect to={{...location, pathname: '/login'}} />}
        />
        <Redirect from="/user/forgot_pw" to="/forgot_pw" />
        <Redirect from="/user/find_orgs" to="/find_orgs" />
        <Route from={['/mkt/signup', '/mkt/credit_signup']} render={() => <Redirect to="/find_orgs" />} />
        {/* ---------------------------------------------------------------  */}
        <Route path="/error" render={() => <Error />} />
        <Routes orgInfo={orgInfo} />
        {cookiesEnabled && !cookies['cookies-consent'] && <CookiesConsentPopup />}
        {/* default route if none other match */}
        <Route render={() => <NotFound />} />
      </Switch>
    </>
  );
};

export default AppContainer;
