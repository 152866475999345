import {Button} from '@trustle/component-library';
import React, {useState} from 'react';
import RequestAccessModal from 'src/components/modals/RequestAccessModal';

const GeneralAccessRequest = () => {
  const [requestModalShowing, setRequestModalShowing] = useState(false);
  const handleRequestAccess = () => {
    setRequestModalShowing(true);
  };

  return (
    <div className="general-access-request">
      <h2>General Access Request</h2>
      <p className="ml-1">
        Having a hard time locating what you are looking for? You can submit a general request for a system, and the
        system owner will provide you a link to that access or grant that access for you.
      </p>
      {/* This modal will be replaced with the new route for requesting access */}
      <RequestAccessModal show={requestModalShowing} onHide={() => setRequestModalShowing(false)} />
      <Button onClick={() => handleRequestAccess()} size="lg">
        Request Access
      </Button>
    </div>
  );
};

export default GeneralAccessRequest;
