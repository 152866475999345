import {Checkbox, Confirmation, Table} from '@trustle/component-library';
import React, {useEffect, useReducer, useState} from 'react';
import {useAccountFilters} from 'src/views/v2/system/accounts/panel/AccountsFiltersContext';
import {useAccountsColumns} from './useAccountColumns';
import {useAccountsContext} from '../../AccountsContext';
import {Account} from '../../hooks/useAccounts';
import _ from 'lodash';
import {AccountAccessType} from 'src/types';
import {LinkList, LinkUsersParams, useLinkUsers} from '../../hooks/useLinkUsers';
import {useRootStore} from 'src/lib/hooks';
import {useAccountsSummaryContext} from '../../AccountsSummaryContext';
import {DataStatus} from 'src/views/v2/common/DataStatus';

const TABLE_KEY = 'accounts-table';

interface LinkCorrespondencesState {
  accountIdToUserId: Record<string, string>;
  accountIdToAccountType: Record<string, AccountAccessType>;
}

export type TrustleUserAndAccountTypeAction =
  | {type: 'setUser'; accountId: string; userId: string}
  | {type: 'setAccountType'; accountId: string; accountType: AccountAccessType}
  | {type: 'replaceState'; payload: any};

export function AccountsTable() {
  const {listAccountsResponse, loading, system, refetchAccounts} = useAccountsContext();
  const {refetchAccountsSummary} = useAccountsSummaryContext();
  const {filters, setFilter, setFilters} = useAccountFilters();
  const {size, linkStatus, matchType, sort, orderBy} = filters;
  const [selectedAccountIds, setSelectedAccountIds] = useState<string[]>([]);
  const [showConfirmListLinkModal, setShowConfirmListLinkModal] = useState(false);
  const {linkUsers} = useLinkUsers();
  const {toast} = useRootStore();

  const accountSelectionReducer = (
    state: LinkCorrespondencesState,
    action: TrustleUserAndAccountTypeAction
  ): LinkCorrespondencesState => {
    switch (action.type) {
      case 'setUser':
        return {...state, accountIdToUserId: {...state.accountIdToUserId, [action.accountId]: action.userId}};
      case 'setAccountType':
        return {
          ...state,
          accountIdToAccountType: {...state.accountIdToAccountType, [action.accountId]: action.accountType},
        };
      case 'replaceState':
        return {accountIdToUserId: action.payload, accountIdToAccountType: action.payload};
      default:
        return state;
    }
  };

  // use `useReducer` to conditionally update the account-user and account-type correspondences
  // based on what happens on `TrustleUserDropDown.tsx` component
  const [linkCorrespondenceState, dispatch] = useReducer(accountSelectionReducer, {
    accountIdToUserId: {},
    accountIdToAccountType: {},
  });

  const columns = useAccountsColumns({dispatch});

  useEffect(() => {
    // clear the selected rows when you switch tabs,
    // or when a different sort option is chosen,
    // or when a different match type is chosen
    setSelectedAccountIds([]);
  }, [linkStatus, matchType, sort, orderBy]);

  useEffect(() => {
    // clear accountToUserId and accountIdToAccounType when you switch tabs
    // or when a different match type is chosen
    dispatch({type: 'replaceState', payload: {}});
  }, [linkStatus, matchType]);

  const handleTableChange = async (
    _type: string,
    {page, sortField, sortOrder}: {page: number; sortField: string; sortOrder: 'asc' | 'desc'}
  ) => {
    if (_type === 'pagination') {
      setFilter('page', page);
    }

    if (_type === 'sort') {
      const toSet = {orderBy: sortField, sort: sortOrder};
      setFilters({...filters, ...toSet});
    }
  };

  const handleSizePerPageChange = (size: number) => {
    setFilter('size', size);
  };

  if (loading || !listAccountsResponse) {
    return <DataStatus text="Loading accounts..." status="loading" description="Please stand by" />;
  }

  const {principals, total} = listAccountsResponse;

  const isRemote = total > Number(size);

  return (
    <>
      <Table
        data={principals}
        overrideKeyField="principalId"
        columns={columns}
        tableKey={TABLE_KEY}
        totalSize={total}
        sizePerPage={filters.size}
        page={filters.page}
        remote={
          isRemote
            ? {
                filter: true,
                pagination: true,
                sort: false,
                cellEdit: false,
              }
            : false
        }
        onSizePerPageChange={(size) => {
          handleSizePerPageChange(size);
          return {};
        }}
        onTableChange={isRemote ? handleTableChange : () => {}}
        selectRow={{
          mode: 'checkbox',
          hideSelectColumn: matchType === 'none' || !system.userIsOwner,
          style: {background: 'rgba(0,81,155,0.1)'},
          selected: selectedAccountIds,
          onSelect: (row: Account, isSelect: boolean, _rowIndex: number) => {
            setSelectedAccountIds(
              isSelect ? _.union(selectedAccountIds, [row.principalId]) : _.without(selectedAccountIds, row.principalId)
            );
          },
          onSelectAll: (isSelect: boolean, rows: Account[]) =>
            setSelectedAccountIds(isSelect ? rows.map((row) => row.principalId) : []),
          selectionRenderer: ({rowIndex}) => (
            <Checkbox
              id={`activate-${principals[rowIndex].principalId}`}
              checked={selectedAccountIds.includes(principals[rowIndex].principalId)}
              className="tr-w-4 tr-h-4 tr-rounded-[1px] tr-border-2 tr-border-neutral-700 tr-cursor-pointer"
            />
          ),
          selectionHeaderRenderer: ({indeterminate, checked}: {indeterminate: any; checked: boolean}) => {
            return (
              <Checkbox
                id={`activate-accounts-all`}
                checked={checked}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = indeterminate;
                  }
                }}
                className="tr-w-4 tr-h-4 tr-rounded-[1px] tr-border-2 tr-border-neutral-700 tr-cursor-pointer"
              />
            );
          },
        }}
        bulkActionBtnProps={{
          btnLabel: `Link ${selectedAccountIds.length} Account${selectedAccountIds.length !== 1 ? 's' : ''}`,
          onClick: () => setShowConfirmListLinkModal(true),
        }}
        showEmptyElement={
          <DataStatus status="no-data" text="No accounts found" description="Try with different filter criteria" />
        }
      />
      <>
        {showConfirmListLinkModal && (
          <Confirmation
            onConfirm={() => {
              const linkList: LinkList[] = [];
              selectedAccountIds.forEach((accountId) => {
                const userId = linkCorrespondenceState.accountIdToUserId[accountId];
                const accountType = linkCorrespondenceState.accountIdToAccountType[accountId];

                // if we found the correspondence
                if (userId && accountType) {
                  // add to the list we will send to the backend
                  linkList.push({userId, accountId, accountType});
                } // else {
                // this would indicate a bad state management, we should probably abort the whole operation
                //}
              });

              const linkUsersParams: LinkUsersParams = {
                systemId: system.id,
                linkList,
              };

              linkUsers(linkUsersParams)
                .then(() => {
                  // success
                  setSelectedAccountIds([]);
                })
                .catch(() => {
                  toast.error('There was an unexpected error linking Trustle Users.');
                })
                .finally(() => {
                  setShowConfirmListLinkModal(false);
                  void refetchAccounts();
                  void refetchAccountsSummary();
                });
            }}
            onClose={() => {
              setSelectedAccountIds([]);
              setShowConfirmListLinkModal(false);
            }}
            title={'Confirm Linking'}
          >
            <div>{`This action will associate the ${
              selectedAccountIds.length > 1 ? `${selectedAccountIds.length} selected accounts` : 'selected account'
            } with the selected user${selectedAccountIds.length > 1 ? 's' : ''}.`}</div>
          </Confirmation>
        )}
      </>
    </>
  );
}
