import {UserType} from './types';
import {UsersSummaryResponse} from './hooks/useUsersSummary';

export function getUsersSummaryValue(userType: UserType, usersSummary?: UsersSummaryResponse) {
  if (!usersSummary) {
    return 0;
  }
  switch (userType) {
    case 'employee':
      return usersSummary.employeeCount ?? usersSummary.employeeUserCount;
    case 'contractor':
      return usersSummary.contractorCount ?? usersSummary.contractorUserCount;
    case 'customer':
      return usersSummary.customerCount ?? usersSummary.customerUserCount;
    case 'system':
      return usersSummary.systemUserCount;
  }
}
