import React from 'react';
import {Icon} from '@trustle/component-library';

interface OAuthButtonProps {
  onClick?: () => void;
  signup?: boolean;
}

const OAuthButton: React.FC<OAuthButtonProps> = ({onClick, signup = false}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      window.location.href = `/api/auth/oauth/authenticate${window.location.search}`;
    }
  };

  return (
    <div className="tr-flex tr-flex-col tr-items-center tr-my-8 tr-h-8">
      <button
        onClick={handleClick}
        className="tr-bg-[#F2F2F2] disabled:tr-opacity-25 tr-mt-2 tr-p-[1px] tr-text-sm hover:tr-no-underline tr-rounded-lg tr-items-center tr-border-0 tr-w-full tr-text-center"
        data-testid="oauth-button"
      >
        <Icon type="GoogleLogo" title="Google logo" className="tr-p-2" forcedSize={16} />
        <span className="tr-grow tr-text-black">
          {signup ? 'Sign up with Google' : 'Sign in with Google'}
        </span>
      </button>
    </div>
  );
};

export default OAuthButton;
