import {Icon, Tooltip} from '@trustle/component-library';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import {useTeams} from '../../admin/admin-users-panel/hooks/useTeams';
import {DataStatus} from '../../common/DataStatus';
import {WidgetHeader} from './shared/WidgetHeader';
import {WidgetHeaderItem} from './shared/WidgetHeaderItem';

export function TeamsManagementWidget() {
  const {data: teamsResponse, fetchTeams, loading} = useTeams();
  const history = useHistory();

  useEffect(() => {
    void fetchTeams({page: 1, size: 10, orderBy: 'lastActivity', sort: 'desc', status: 'active'});
  }, []);

  if (loading || !teamsResponse) {
    return <DataStatus text={'Loading'} status="loading" />;
  }

  const {teams, total} = teamsResponse;

  return (
    <div className="tr-flex tr-flex-col tr-p-4 tr-gap-2">
      <WidgetHeader title="Teams" linkTo="/teams" linkText="Manage Teams" />
      <WidgetHeaderItem title={total.toLocaleString()} subtitle={'Active'} appendDivider />

      <div className="tr-relative tr-overflow-x-auto tr-overflow-y-auto tr-h-[300px]">
        <table className="tr-w-full tr-text-sm tr-text-left tr-text-gray-500">
          <thead className="tr-text-md tr-text-trustle-navy" style={{borderBottom: '1px solid #e0e0e0'}}>
            <tr>
              <th scope="col" className="tr-p-3">
                Team
              </th>
              <th scope="col" className="tr-p-3">
                Members
              </th>
              <th scope="col" className="tr-p-3 tr-whitespace-nowrap">
                Last Activity
              </th>
            </tr>
          </thead>
          <tbody>
            {teams.slice(0, 5).map(({id, color, name, userCount, lastActivity}) => (
              <tr
                key={id}
                className="tr-bg-white tr-whitespace-nowrap tr-cursor-pointer hover:tr-bg-[#f8f9fc]"
                onClick={() => history.push(`/teams/${id}`)}
                style={{borderBottom: '1px solid #e0e0e0'}}
              >
                <td className="tr-flex tr-items-center tr-gap-2 tr-p-3">
                  <div
                    className={`tr-w-6 tr-h-6 tr-rounded team-gradient-${color}`}
                    style={color.includes('#') ? {backgroundColor: color} : {}}
                  />
                  <span className="tr-whitespace-nowrap">{name}</span>
                </td>
                <td className="tr-p-3">{userCount}</td>
                <td className="tr-p-3">
                  {!lastActivity ? (
                    <Icon type="emptyData" title="Never" size="sm" />
                  ) : (
                    <Tooltip position="top" content={formatFullDate(lastActivity)}>
                      {formatDateFromNow(lastActivity)}
                    </Tooltip>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
