import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import {TileButton} from '@trustle/component-library';
import React, {useState} from 'react';

const SystemTypeList = () => {
  const [selectedType, setSelectedType] = useState<string>('aws');
  const mockedSystems = [
    {type: 'aws', count: 1, data: [{id: 1, name: 'tester'}]},
    {
      type: 'github',
      count: 2,
      data: [
        {id: 2, name: 'tester-github'},
        {id: 3, name: 'tester-github1'},
      ],
    },
  ];

  return (
    <div className="available-systems tr-mt-10">
      {mockedSystems.map((system) => {
        const {type, count} = system;
        return (
          <TileButton
            data-testid={type}
            key={type}
            colorVariant={selectedType === type ? 'primary' : undefined}
            size={'xl'}
            badge={count}
            onClick={() => {
              setSelectedType(type);
              //clickHandler?.(type);
            }}
            role="radio"
            name={type as SystemIconsTypesEnum}
            aria-checked={true} // location.pathname.includes(type)
            className="tr-mx-6"
          />
        );
      })}
    </div>
  );
};

export default SystemTypeList;
