import React, {useEffect, useMemo} from 'react';
import {AuthoritySelector} from '../../../../connectors/common/authority/authority-selector/AuthoritySelector';
import {AddUsersDropdown} from '../../../admin/add-users-dropdown/AddUsersDropdown';
import {useUsersSummary} from './hooks/useUsersSummary';
import {AdvancedSearchButton} from './internal/advanced-search-button/AdvancedSearchButton';
import {GroupByTable} from './internal/group-by-table/GroupByTable';
import {QuickFilter} from './internal/quick-filter/QuickFilter';
import {StatusQuickFilter} from './internal/status-quick-filter/StatusQuickFilter';
import {SummaryGroupBy} from './internal/summary-group-by/SummaryGroupBy';
import {UsersTable} from './internal/users-table/UsersTable';
import {UsersFilterProvider, useUserFilters} from './UserFiltersContext';
import {UsersListedProvider, useUsersListed} from './UsersListedContext';

export function AdminUsersPanel() {
  return (
    <UsersFilterProvider>
      <UsersListedProvider>
        <AdminUsersPanelInternal />
      </UsersListedProvider>
    </UsersFilterProvider>
  );
}

function AdminUsersPanelInternal() {
  const {filters} = useUserFilters();
  const {data: usersSummary, getUsersSummary} = useUsersSummary();
  const {refetchUsers} = useUsersListed();

  const {showGroupByTable, showQuickFilters} = useMemo(() => {
    const {groupBy} = filters;
    return {
      showGroupByTable: groupBy && groupBy !== 'user' && !filters[groupBy],
      showQuickFilters: groupBy === 'user' || (groupBy && filters[groupBy]),
    };
  }, [filters]);

  useEffect(() => {
    const {status, team, role, title, department} = filters;
    void getUsersSummary({
      status,
      team,
      role,
      title,
      department,
    });
  }, [filters.status, filters.team, filters.role, filters.title, filters.department]);

  return (
    <main className="tr-mb-[140px]">
      <section id="filter-and-authority" className="tr-flex tr-mb-8">
        <div>
          <h1>User Management</h1>
          <p className="text-muted tr-max-w-[450px]">
            Setup User Authority. Invite or remove Users. View Users by Teams, Departments, Roles or Titles..
          </p>
        </div>

        <div className="tr-flex tr-items-baseline tr-gap-3 tr-ml-auto">
          <div className="tr-flex tr-gap-2 tr-items-center">
            <strong className="tr-text-trustle-navy">Filter By:</strong>
            <AdvancedSearchButton />
          </div>
          <div className="tr-flex tr-items-center tr-gap-3">
            <AddUsersDropdown />
            <AuthoritySelector refetchUsers={refetchUsers} />
          </div>
        </div>
      </section>
      <section>
        <StatusQuickFilter />
        <div className="tr-flex tr-flex-col tr-gap-4">
          <SummaryGroupBy />
          {showQuickFilters && <QuickFilter usersSummary={usersSummary} />}
          {showGroupByTable ? <GroupByTable /> : <UsersTable />}
        </div>
      </section>
    </main>
  );
}
