import {TeamsManagementWidget} from './TeamsManagementWidget';
import {UsersManagementWidget} from './UsersManagementWidget';
import React from 'react';

type WidgetType =
  | 'overview-widget'
  | 'setup-guide-widget'
  | 'scoreboard-widget'
  | 'users-widget'
  | 'systems-widget'
  | 'teams-widget';

export function resolveWidget(widget: WidgetType) {
  switch (widget) {
    case 'users-widget':
      return <UsersManagementWidget />;
    case 'teams-widget':
      return <TeamsManagementWidget />;
    default:
      return null;
  }
}
