import {Confirmation} from '@trustle/component-library';
import React from 'react';
import {useRootStore} from '../../../lib/hooks';
import {useHistory} from 'react-router-dom';
import {FacetIdEnum} from '../../../types';
import {UploadModal} from '../../../components/UniversalDataUpload/UploadModal';
import {CSVContent} from '../../../components/UniversalDataUpload/helper';

type Props = {
  show: boolean;
  setShow: (show: boolean) => void;
};

export function UserCsvImportModal({show, setShow}: Props) {
  const {usersStore, org} = useRootStore();
  const history = useHistory();
  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;

  const refresh = () => {
    void usersStore.refresh();
    setShow(false);

    if (usersStore.error) {
      usersStore.rootStore.toast.add('Failed to load users.', {appearance: 'error', autoDismiss: true});
    }
  };

  if (!show) {
    return null;
  }

  return isFreePlan && org.billableUsersCount >= 5 ? (
    <Confirmation
      onConfirm={() => {
        history.push('/admin/billing/plan');
      }}
      onClose={() => {
        setShow(false);
      }}
      title="Upgrade to add another user"
      confirmButtonLabel="Upgrade"
    >
      {'To add another user, please upgrade to Teams version. You will get a free 14-day trial'}
    </Confirmation>
  ) : (
    <UploadModal
      csvContent={CSVContent.IMPORT_USERS}
      onClose={refresh}
    />
  );
}
