import React, {createContext, PropsWithChildren, useContext, useEffect} from 'react';
import {Resource} from 'src/stores/domainObjects/Resource';
import {useAccountsSummary} from './hooks/useAccountsSummary';

type AccountsSummaryContextType = {
  accountsSummary: any;
  loading: boolean;
  refetchAccountsSummary: () => Promise<void>;
  system: Resource;
};

const AccountsSummaryContext = createContext<AccountsSummaryContextType | undefined>(undefined);

type AccountsSummaryProviderProps = PropsWithChildren<{system: Resource}>;

export const AccountsSummaryProvider: React.FC<AccountsSummaryProviderProps> = ({children, system}) => {
  const {data: accountsSummary, loading, getAccountsSummary} = useAccountsSummary();

  useEffect(() => {
    void getAccountsSummary({systemId: system.id});
  }, []);

  const refetchAccountsSummary = async () => {
    await getAccountsSummary({systemId: system.id});
  };

  return (
    <AccountsSummaryContext.Provider
      value={{
        accountsSummary,
        loading,
        refetchAccountsSummary,
        system,
      }}
    >
      {children}
    </AccountsSummaryContext.Provider>
  );
};

export const useAccountsSummaryContext = () => {
  const context = useContext(AccountsSummaryContext);
  if (context === undefined) {
    throw new Error('useAccountsSummaryContext must be used within a AccountsSummaryProviderProps');
  }
  return context;
};
