import {Icon} from '@trustle/component-library';
import React, {useState} from 'react';
import DetailModal from '../../details-modal/Details';

export const ActionsColumn = ({account}: {account: any}) => {
  const [showDetails, setShowDetails] = useState<boolean>();
  return (
    <div className="tr-w-full">
      {!showDetails && (
        <>
          <Icon
            type="details"
            onClick={() => setShowDetails(true)}
            title="show details"
            className="tr-mx-1 hideable-button"
            role="button"
          />
        </>
      )}
      {showDetails && <DetailModal closeModal={() => setShowDetails(undefined)} userAccount={account} />}
    </div>
  );
};
