import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import {
  requiredUsersImportColumns,
  optionalImportUsersColumns,
  exampleDataCSVImportUsers
} from 'src/components/UniversalDataUpload/helper';
import {DocumentationTable} from '@trustle/component-library';

function UsersUploadGuide() {
  const tabs: TabsDocsT = [
    {id: 'users-overview', label: 'Overview', description: overview(), active: true},
    {id: 'users-examples', label: 'Examples', description: examples(), active: false},
  ];

  function overview(): JSX.Element {
    return (
      <div>
        <p className="tr-w-75">
          Users can be imported into Trustle through a CSV file upload. This guide explains the required format and provides examples.
        </p>
        <div className="mt-1 ml-4 mb-4 tr-w-75">
          <p>The CSV import allows you to:</p>
          <li className="pb-2">Create new users in bulk</li>
          <li className="pb-2">Update existing users' information</li>
          <li className="pb-2">Set up reporting relationships</li>
          <li className="pb-2">Define user roles and departments</li>
        </div>
      </div>
    );
  }

  function examples(): JSX.Element {
    return (
      <div>
        <p className="pb-3">
          Below are examples of different user types and scenarios that can be imported:
        </p>
        <div className="pl-4 tr-w-75">
          <li>Regular employees with manager assignments</li>
          <li>Contractors with limited access and end dates</li>
          <li>Department heads and team leads</li>
          <li>Users with specific roles and cost centers</li>
        </div>
        <div className={'border-bottom tr-flex tr-justify-between my-4'}>
          <p className={'mb-1'}>
            <strong>Example CSV Structure:</strong>
          </p>
          <a href={`/api/users/download_example`} className="link" download>
            {'Download example'}
          </a>
        </div>
        <DocumentationTable
          requiredColumns={requiredUsersImportColumns}
          optionalColumns={optionalImportUsersColumns}
          data={exampleDataCSVImportUsers}
        />
      </div>
    );
  }

  return (
    <>
      <DocSection tabs={tabs} title="Guide: How to import users through CSV file upload" />
    </>
  );
}

export default UsersUploadGuide;
