import React, {createContext, PropsWithChildren, useContext} from 'react';
import useUrlSyncPaginationAndFilters, {GroupByParams} from './hooks/useUrlSyncPaginationAndFilters';
import _ from 'lodash';
import {PaginationParams, SortParams, UserFilterKey} from './types';

export type UserQueryFiltersAndPagination = {
  [key in UserFilterKey]?: any;
} & GroupByParams &
  SortParams &
  PaginationParams;

type UserFiltersContextType = {
  filters: UserQueryFiltersAndPagination;
  setFilter: (key: keyof UserQueryFiltersAndPagination, value: any) => void;
  setFilters: (filters: UserQueryFiltersAndPagination) => void;
  clearFilters: (filter?: {key: keyof UserQueryFiltersAndPagination; value: any}) => void;
};

const UserFiltersContext = createContext<UserFiltersContextType | undefined>(undefined);

type UsersFilterProviderProps = PropsWithChildren<{
  defaultFilters?: UserQueryFiltersAndPagination;
}>;

const DEFAULT_FILTERS: UserQueryFiltersAndPagination = {
  status: 'active',
  groupBy: 'user',
  page: 1,
  size: 10,
};

export const UsersFilterProvider: React.FC<UsersFilterProviderProps> = ({
  children,
  defaultFilters = DEFAULT_FILTERS,
}) => {
  const [filters, setFilters] = useUrlSyncPaginationAndFilters(defaultFilters);

  // Clear all filters except for page, size, and groupBy
  const clearFilters = (filter?: {key: keyof UserQueryFiltersAndPagination; value: any}) => {
    let toSet: UserQueryFiltersAndPagination;

    if (filter && filter.key === 'groupBy' && filter.value === 'user') {
      toSet = defaultFilters;
    } else {
      toSet = {..._.omit(defaultFilters, ['status']), groupBy: filters.groupBy};
    }

    // Clear with new filter
    if (filter) {
      toSet = {...toSet, [filter.key]: filter.value};
    }

    setFilters(toSet);
  };

  return (
    <UserFiltersContext.Provider
      value={{
        filters, // All filters including groupBy, sort and pagination
        setFilter: (key: keyof UserQueryFiltersAndPagination, value: any) => {
          if (!value) {
            const toSet = {...filters};
            delete toSet[key];
            setFilters(toSet);
          } else {
            setFilters({...filters, [key]: value});
          }
        },
        setFilters: (filters: UserQueryFiltersAndPagination) => {
          const toSet: UserQueryFiltersAndPagination = {...filters};
          Object.keys(filters).forEach((key) => {
            if (!filters[key as keyof UserQueryFiltersAndPagination]) {
              delete toSet[key as keyof UserQueryFiltersAndPagination];
            }
          });
          setFilters(toSet);
        },
        clearFilters,
      }}
    >
      {children}
    </UserFiltersContext.Provider>
  );
};

export const useUserFilters = () => {
  const context = useContext(UserFiltersContext);
  if (context === undefined) {
    throw new Error('useUserFilters must be used within a UsersFilterProvider');
  }
  return context;
};
