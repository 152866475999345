import {useState} from 'react';
import axios from 'axios';
import {OrganizationSummaryParams} from '../types';
import {useRootStore} from '../../../../../lib/hooks';
import {parseObjectToQueryString} from 'src/views/v2/utils';

export type OrganizationSummaryResponse = {
  users_count: number;
  systems_count: number;
  teams_count: number;
  departments_count: number;
  roles_count: number;
  titles_count: number;
};

export function useOrganizationSummary() {
  const [data, setData] = useState<OrganizationSummaryResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {toast} = useRootStore();

  const BASE_URL = '/api/v2/organization_summary';

  const getOrganizationSummary = async (params: OrganizationSummaryParams) => {
    const queryString = parseObjectToQueryString(params);

    setLoading(true);
    try {
      const response = await axios.get<OrganizationSummaryResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      setError(true);
      toast.error('There was an error trying to get organization summary');
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, getOrganizationSummary};
}
