import _ from 'lodash';
import {Tooltip, UserInfo} from '@trustle/component-library';
import React from 'react';
import StatusIndicator, {translateAccountStatus} from 'src/components/StatusIndicator';
import {
  AccountColumnsE,
  sortByAccessCount,
  lastLoginIcon,
  unavailableIcon,
} from 'src/components/design/tables/SharedColumnDefinitions/AccountColumns';
import {NumAccessesLink} from './columns/NumAccesesLink';
import {Risk} from './columns/Risk';
import TrustleUserDropDown from './columns/TrustleUserDropDown';
import moment from 'moment';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import {useAccountFilters} from '../../AccountsFiltersContext';
import {Usage} from './columns/Usage';
import {useAccountsContext} from '../../AccountsContext';
import {ConnectionServiceE} from 'src/types';
import AWSAuthorizedAccount from './columns/AWSAuthorizedAccount';
import TrustleUserAndTooltip from './columns/TrustleUserAndTooltip';
import {TrustleUserAndAccountTypeAction} from './AccountsTable';

import {ActionsColumn} from './columns/Actions';

type Props = {
  dispatch: React.Dispatch<TrustleUserAndAccountTypeAction>;
};

// inherited from `frontend/src/components/design/tables/SharedColumnDefinitions/AccountColumns.tsx`
export function useAccountsColumns({dispatch}: Props) {
  const {filters} = useAccountFilters();
  const {linkStatus} = filters;
  const {system} = useAccountsContext();
  const isAWSSystem = system.type === ConnectionServiceE.AWS;

  return [
    {
      dataField: 'principalId',
      text: '',
      hidden: true,
    },
    {
      dataField: 'status',
      sort: true,
      configTitle: AccountColumnsE.STATUS,
      text: '',
      headerStyle: {width: '1em', paddingLeft: '0', paddingRight: '0'},
      style: {width: '1em', paddingLeft: '0', paddingRight: '0'},
      formatter: (_unused: any, account: any) => (
        <div>
          <StatusIndicator
            status={translateAccountStatus(account.status!)}
            messages={[_.capitalize(account.status!)]}
          />
        </div>
      ),
    },
    {
      dataField: 'account',
      sort: true,
      classes: 'show-on-hover',
      text: AccountColumnsE.ACCOUNT_NAME,
      formatter: (_unused: string, account: any) => (
        <div className="flex">
          <UserInfo
            accountType={account.accountType}
            account={account.account}
            className="tr-text-trustle-link"
            type={account.userId ? account.userType : 'unlinked'}
            isOrgOwner={account.userIsOrgOwner}
            authorizedAccount={<AWSAuthorizedAccount account={account} iconSize={'md'} systemId={system.id} />}
          ></UserInfo>
          <TrustleUserAndTooltip account={account} systemId={system.id} />
        </div>
      ),
      sortValue: (_cell: any, account: any) => {
        return account.account ?? '';
      },
    },
    {
      dataField: 'accessesCount',
      sort: true,
      text: AccountColumnsE.ACCESSES,
      headerStyle: {width: '5em', paddingLeft: '0', paddingRight: '0'},
      style: {width: '5em', paddingLeft: '0', paddingRight: '0'},
      formatter: (_unused: any, account: any) => <NumAccessesLink account={account} systemId={system.id} />,
      sortFunc: sortByAccessCount,
    },
    {
      dataField: 'riskScore',
      text: AccountColumnsE.RISK_SCORE,
      hidden: !isAWSSystem,
      headerStyle: {width: '7em', paddingLeft: '0', paddingRight: '0'},
      style: {width: '7em', paddingLeft: '0', paddingRight: '0'},
      formatter: (_unused: any, account: any) => <Risk account={account} />,
      sort: true,
      sortValue: (_cell: any, account: any) => (_.isNumber(account.riskScore) ? account.riskScore : -1),
    },
    {
      dataField: 'usage',
      text: AccountColumnsE.USAGE,
      hidden: !isAWSSystem,
      headerStyle: {width: '5em', paddingLeft: '0', paddingRight: '0'},
      style: {width: '5em', paddingLeft: '0', paddingRight: '0'},
      formatter: (_unused: any, account: any) => <Usage account={account} system={system} />,
      sort: true,
    },
    {
      dataField: 'type',
      hidden: linkStatus === 'linked',
      text: 'Match Trustle User and Type',
      formatter: (_unused: any, account: any) => {
        return (
          <div className="tr-flex">
            <TrustleUserDropDown systemId={system.id} account={account} dispatch={dispatch} />
          </div>
        );
      },
    },
    {
      dataField: 'lastLogin',
      text: AccountColumnsE.LAST_LOGIN,
      headerStyle: {paddingLeft: '40px'},
      hidden: system?.isUsageLastLoginAvailable,
      style: {paddingLeft: '40px'},
      formatter: (_unused: any, account: any) => {
        if (account.lastLogin) {
          const date = moment(account.lastLogin).toISOString();
          return <Tooltip content={formatFullDate(date)}>{formatDateFromNow(date)}</Tooltip>;
        } else {
          return (
            <span className="text-muted">{system?.isUsageLastLoginAvailable ? lastLoginIcon : unavailableIcon}</span>
          );
        }
      },
      sort: true,
    },
    {
      dataField: 'actions',
      text: '',
      hidden: false,
      headerStyle: {width: '3rem'},
      style: {width: '3rem'},
      classes: 'show-on-hover',
      formatter: (_unused: any, account: any) => <ActionsColumn account={account} />,
    },
  ] as any;
}
