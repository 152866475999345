import React, {createContext, PropsWithChildren, useContext, useEffect} from 'react';
import {useAccounts} from './hooks/useAccounts';
import {useAccountFilters} from './AccountsFiltersContext';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ResourceConnectorT} from 'src/types';

type AccountsContextType = {
  listAccountsResponse: any;
  loading: boolean;
  refetchAccounts: () => Promise<void>;
  system: Resource;
  connector?: ResourceConnectorT;
};

const AccountsContext = createContext<AccountsContextType | undefined>(undefined);

type AccountsProviderProps = PropsWithChildren<{system: Resource; connector?: ResourceConnectorT}>;

export const AccountsProvider: React.FC<AccountsProviderProps> = ({children, system, connector}) => {
  const {data: listAccountsResponse, loading, fetchAccounts} = useAccounts();
  const {filters} = useAccountFilters();

  useEffect(() => {
    void fetchAccounts({systemId: system.id, ...filters});
  }, [filters]);

  const refetchAccounts = async () => {
    await fetchAccounts({systemId: system.id, ...filters});
  };

  return (
    <AccountsContext.Provider
      value={{
        listAccountsResponse,
        loading,
        refetchAccounts,
        system,
        connector,
      }}
    >
      {children}
    </AccountsContext.Provider>
  );
};

export const useAccountsContext = () => {
  const context = useContext(AccountsContext);
  if (context === undefined) {
    throw new Error('useAccountsContext must be used within a AccountsProviderProps');
  }
  return context;
};
