import React from 'react';
import {AccountsSummaryResponse} from '../../hooks/useAccountsSummary';
import {useAccountFilters} from '../../AccountsFiltersContext';
import {UserType} from 'src/views/v2/admin/admin-users-panel/types';
import {USER_TYPES} from 'src/views/v2/admin/admin-users-panel/constants';
import {Button, Icon} from '@trustle/component-library';
import _ from 'lodash';
import {useAccountsSummaryContext} from '../../AccountsSummaryContext';

type UserTypeWUnselected = UserType | 'unselected';

export function LinkedAccountsView() {
  const {filters, setFilter} = useAccountFilters();
  const {accountsSummary} = useAccountsSummaryContext();
  const {userType} = filters;

  return (
    <div>
      <h2 className="tr-mb-0">{'Linked Accounts'}</h2>
      <div className="tr-flex tr-items-center tr-py-3">
        {/* formerly TrustleUserTypeFilter */}
        {USER_TYPES.map((aUserType) => (
          <div key={aUserType}>
            <Button
              variant="ternary"
              selected={aUserType === userType}
              onClick={() => setFilter('userType', aUserType === userType ? '' : aUserType)}
            >
              <span className="tr-flex tr-items-center tr-gap-1">
                <Icon type={`${aUserType}User`} />
                {_.startCase(aUserType)} {getAccountsSummaryValue(aUserType, accountsSummary)}
              </span>
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

function getAccountsSummaryValue(userType: UserTypeWUnselected, accountsSummary?: AccountsSummaryResponse) {
  if (!accountsSummary || !userType) {
    return '';
  }
  switch (userType) {
    case 'customer':
      return accountsSummary.customerUserCount;
    case 'system':
      return accountsSummary.systemUserCount;
    case 'contractor':
      return accountsSummary.contractorUserCount;
    case 'employee':
      return accountsSummary.employeeUserCount;
  }
}
