import React, {useEffect, useState} from 'react';
import {LINK_STATUSES} from 'src/views/v2/admin/admin-users-panel/constants';
import _ from 'lodash';
import {DEFAULT_FILTERS, useAccountFilters} from '../../AccountsFiltersContext';
import {Tab} from '@trustle/component-library';
import {AccountsSummaryResponse} from '../../hooks/useAccountsSummary';
import {LinkStatus} from 'src/views/v2/admin/admin-users-panel/types';

type Props = {accountsSummary?: AccountsSummaryResponse};

export function LinkStatusTabs({accountsSummary}: Props) {
  const {filters, setFilters} = useAccountFilters();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const {linkStatus} = filters;
    setSelectedIndex(LINK_STATUSES.indexOf(linkStatus)); // keep current link status when the page refreshes
  }, [filters]);

  const changeSelectedIndex = (number: number) => {
    const status = LINK_STATUSES[number];
    setFilters({...DEFAULT_FILTERS, linkStatus: status}); // set default filters when linked/unlinked tabs changes, keeping the current link status
    setSelectedIndex(number);
  };

  return (
    <div className="tr-flex tr-items-center tr-py-3">
      <Tab.Group selectedIndex={selectedIndex} onChange={changeSelectedIndex}>
        <Tab.List variant={'line'}>
          {LINK_STATUSES.map((aLinkStatus, index) => (
            <Tab key={aLinkStatus} onClick={() => changeSelectedIndex(index)}>
              <span>
                {_.startCase(aLinkStatus)} {getAccountsSummaryValue(aLinkStatus, accountsSummary)}
              </span>
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
    </div>
  );
}

function getAccountsSummaryValue(status: LinkStatus, accountsSummary?: AccountsSummaryResponse) {
  if (!accountsSummary) {
    return '';
  }
  switch (status) {
    case 'linked':
      return accountsSummary.linkedAccountCount;
    case 'unlinked':
      return accountsSummary.unlinkedAccountCount;
  }
}
