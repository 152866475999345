import React, {useEffect} from 'react';
import {M365VisibilityCard} from 'src/dashboard/m365-visibility/M365VisibilityCard';
import {useFeatureFlags} from 'src/lib/hooks';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ConnectionServiceE} from 'src/types';
import {SystemTrustleScore} from './internal/SystemTrustleScore';
import {AccountResourcesOverview} from './internal/AccountResourcesOverview';
import {useSystemSummary} from './hooks/useSystemSummary';
import {DataStatus} from '../../common/DataStatus';

type Props = {
  resource: Resource;
};

export function SystemOverviewPanel({resource}: Props) {
  const featureFlagViewer = useFeatureFlags();
  const m365LicensesUsage = featureFlagViewer.isEnabled('m365_licenses_usage');
  const {data, loading, error, getUsersSummary} = useSystemSummary({systemId: resource.id});

  const connectorService = resource.connector?.service;

  useEffect(() => {
    void getUsersSummary();
  }, []);

  if (loading) {
    return <DataStatus text={'Loading'} status="loading" />;
  }

  if (error || !data) {
    return <DataStatus text={'There was an error trying to get system overview'} status="error" />;
  }

  const showM365LicenseUsage = m365LicensesUsage && resource.connector?.service === ConnectionServiceE.M365;

  return (
    <main className="tr-mb-[140px] tr-mt-[80px] container tr-grid tr-grid-cols-12 tr-gap-4">
      {/* Trustle Score */}
      <section className="tr-col-span-3">
        <SystemTrustleScore resourceId={resource.id} />
      </section>
      {/* Accounts and Resources */}
      <section className="tr-col-span-9">
        <AccountResourcesOverview resourceId={resource.id} summary={data} connectorService={connectorService} />
      </section>
      {/* Trustle Score FAQ */}
      <section className="tr-col-span-12"></section>
      {showM365LicenseUsage && (
        <section className="tr-col-span-12">
          <M365VisibilityCard showHeatmap resourceId={resource.id} />
        </section>
      )}
    </main>
  );
}
