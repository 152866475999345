import axios from 'axios';
import {useState} from 'react';
import {parseObjectToQueryString} from 'src/views/v2/utils';
import {useRootStore} from '../../../../../lib/hooks';
import {PaginationParams, SortParams, UserStatus} from '../types';

export type TeamResponse = {
  id: string;
  oid: string;
  created: string;
  name: string;
  description: string;
  tombstone: boolean;
  type: string;
  userCount: number;
  lastActivity: string;
  managers: {
    id: string;
    title: string;
    email: string;
    firstname: string;
    lastname: string;
    source_of_truth: string;
  }[];
  color: string;
};

type TeamsResponse = {
  teams: TeamResponse[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

type ListTeamsParams = {status?: UserStatus; searchTerm?: string} & PaginationParams & SortParams;

export function useTeams() {
  const [data, setData] = useState<TeamsResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const {toast} = useRootStore();

  const BASE_URL = '/api/v2/teams';

  const fetchTeams = async (params: ListTeamsParams) => {
    const {page, size, ...rest} = params;

    const parsedParams: any = {
      page: page || 1,
      size: size || 50,
      ...rest,
    };

    const queryString = parseObjectToQueryString(parsedParams);

    setLoading(true);
    try {
      const response = await axios.get<TeamsResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      setError(true);
      toast.error('There was an error fetching teams');
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, fetchTeams};
}
