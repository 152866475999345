import {Button, Icon, TextInput} from '@trustle/component-library';
import axios from 'axios';
import {Field, Form, Formik} from 'formik';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {object, string} from 'yup';
import {SAMLLoginButton} from '../User/Login/SamlLoginButton';
import {useQueryParams} from 'src/lib';
import useConfig from '../../lib/hooks/useConfig';
import {OrgInfoT} from 'src/Routes/Component';
import ErrorMessage from './loginPage/ErrorMessage';

const LoginForm = observer(({history, location}: RouteComponentProps) => {
  const config = useConfig();
  const [orgInfo, setOrgInfo] = useState<OrgInfoT | undefined>(undefined);
  const {host} = window.location;

  useEffect(() => {
    const fetchOrgInfo = async () => {
      const [orgHostname] = host.split('.');
      const response = await axios.get(`/api/orgs/checkhost/${orgHostname}`);
      setOrgInfo(response.data);
    };
    void fetchOrgInfo();
  }, []);

  const newLoginFlowEnabled = config?.config?.featureFlags.includes('show_new_login_flow');

  const [error] = useQueryParams(['error']);

  if (orgInfo === undefined) {
    return <></>;
  }

  return (
    <>
      <div data-testid="loginForm" className="tr-border-solid tr-border-gray-300 tr-border-0 tr-border-b-2">
        <h2 className="tr-text-3xl ">Login to {host}</h2>
        {!newLoginFlowEnabled && (
          <Link to="/find_orgs" className="tr-block tr-font-bold tr-text-sm tr-pb-2">
            Not your organization?
          </Link>
        )}
      </div>

      {error && <ErrorMessage error={error} />}
      {orgInfo.samlLoginUrl ? (
        <SAMLLoginButton url={orgInfo.samlLoginUrl} />
      ) : (
        <>
          {orgInfo.isPwdAllowed && (
            <Formik
              initialValues={{email: '', password: ''}}
              validationSchema={object().shape({
                email: string()
                  .email('Please enter a valid email address')
                  .matches(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    'Please enter a valid email address'
                  )
                  .required('Email is required'),
                password: string()
                  .required()
                  .min(8, 'Password should be at least 8 characters long'),
              })}
              onSubmit={({email, password}, {setErrors}) =>
                axios
                  .post('/api/auth/login', {email, password})
                  .then(() => history.push((location.state as {from?: {pathname: string}})?.from ?? '/home'))
                  .catch(() => setErrors({password: 'Incorrect email address or password'}))
              }
            >
              {({isValid, isSubmitting}) => (
                <Form className="tr-my-8">
                  <Field component={TextInput} label="Email Address" name="email" placeholder="user@example.com" />
                  <Field
                    component={TextInput}
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="password"
                  />
                  <div className="tr-flex tr-justify-between tr-items-center">
                    <Button type="submit" disabled={!isValid || isSubmitting}>
                      Login
                    </Button>
                    <Link to="/forgot_pw" className="tr-font-bold">
                      Forgot Password?
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {orgInfo.isOauthAllowed && (
            <div className="tr-flex tr-flex-col tr-items-center tr-my-8 tr-h-8">
              Sign-in with an identity provider
              <a
                className="tr-bg-[#F2F2F2] disabled:tr-opacity-25 tr-mt-2 tr-p-[1px] tr-text-sm hover:tr-no-underline tr-rounded-lg tr-items-center tr-border-0 tr-w-full tr-text-center"
                href={`/api/auth/oauth/authenticate${window.location.search}`}
              >
                <Icon type="GoogleLogo" title="Google logo" className="tr-p-2" forcedSize={16} />
                <span className="tr-grow tr-text-black">Sign in with Google</span>
              </a>
            </div>
          )}
        </>
      )}
    </>
  );
});

export default LoginForm;
