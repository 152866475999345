import _ from 'lodash';
import React from 'react';
import {
  fixPlurality,
  formatUserNameAndEmail,
  formatUserName,
  formatDateFromNow,
  formatFullDate,
  useFeatureFlags,
} from 'src/lib';
import {Task} from 'src/stores/domainObjects/Task';
import {
  AccessRequestType,
  MinSensitivitySettingT,
  ProvisionOptions,
  TaskActionsEnum,
  TaskTypesEnum,
  TicketType,
} from 'src/types';
import {getDeprovisionTaskConfiguration, getProvisionTaskConfiguration} from '../ProvisionConfig';
import {Link} from 'react-router-dom';
import {
  Badge,
  DurationInput,
  Icon,
  Select,
  SensitivityIcon,
  TextAreaInput,
  Tooltip,
  UserAvatar,
  UserAvatarAndName,
} from '@trustle/component-library';
import TaskTypeIcon from './TaskTypeIcon';
import RequestorHeading from './RequestorHeading';
import ApprovalHeading from './ApprovalHeading';
import {object, string} from 'yup';
import {Field} from 'formik';
import {AccountTabTypeE} from 'src/views/Resource/Account/ResourceAccounts';
import {UserStatus} from 'src/stores/usersStore';
export type actionButtonType = {
  action: TaskActionsEnum;
  label: string;
  type?: string;
  disabled?: boolean;
  hide?: boolean;
  ignoreValidity?: boolean;
};

export type TaskStructureDefinition = {
  text: (accessRequest: Task, setShowHistory?: (view: boolean) => void) => React.ReactElement;
  heading: ({
    accessRequest,
    className,
    prependTag,
  }: {
    accessRequest: Task;
    className?: string;
    prependTag?: React.ReactElement;
  }) => React.ReactElement;
  headingTitle: ({accessRequest}: {accessRequest: Task}) => React.ReactElement;
  details: ({accessRequest}: {accessRequest: Task}) => React.ReactElement;
  getForm?: ({accessRequest, isAllowedToAct}: {accessRequest: Task; isAllowedToAct: boolean}) => {
    initialValues: any;
    validationSchema: any;
    fields: React.ReactElement;
  };
  confirmation: ({
    accessRequest,
    selectedAction,
    values,
  }: {
    accessRequest: Task;
    selectedAction?: TaskActionsEnum;
    values?: any;
  }) => React.ReactElement;
  getActions: (accessRequest: Task) => actionButtonType[];
};

export type TaskTypesDefinition = {
  [key in TaskTypesEnum]?: TaskStructureDefinition;
};

const heading = ({
  accessRequest,
  className,
  prependTag,
}: {
  accessRequest: Task;
  className?: string;
  prependTag?: React.ReactElement;
}) => {
  const featureFlagViewer = useFeatureFlags();
  const enabledTaskExpiration = featureFlagViewer.isEnabled('enable_expiration_days_for_tasks');
  const headingClasses = `tr-w-full tr-p-2 tr-rounded-md  ${className || ''}`;
  return (
    <div key={`access-request-${accessRequest.id}`} className={headingClasses}>
      {_.size(accessRequest.accumIds) > 1 && (
        <Badge variant="dark" pill={true} className="tr-mx-1 rounded-full">
          {_.size(accessRequest.accumIds)}
        </Badge>
      )}
      {enabledTaskExpiration ? (
        <div className="tr-flex tr-justify-between">
          <div className="tr-flex tr-justify-start tr-items-center tr-gap-0.5">
            <TaskTypeIcon accessRequest={accessRequest} />
            <span className="tr-flex tr-mx-1 tr-text-xs tr-text-gray-400">
              {formatDateFromNow(accessRequest.createdAt)}
            </span>
          </div>
          <div className="tr-flex tr-justify-start tr-items-center tr-gap-0.5">
            {prependTag}
            <SensitivityIcon size="sm" level={accessRequest.sensitivity?.level ?? ''} />
            <Tooltip content={`This task will expire ${formatFullDate(accessRequest.expiresIn)}`}>
              <Badge className="tr-flex tr-items-center tr-text-black cursor tr-ml-1" variant={'light'}>
                <Icon type="time" size="sm" className="tr-mr-1" />
                {formatDateFromNow(accessRequest.expiresIn)}
              </Badge>
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="tr-flex tr-justify-between">
          <TaskTypeIcon accessRequest={accessRequest} />
          <div className="tr-flex tr-justify-start tr-items-center tr-gap-0.5">
            {prependTag}
            <SensitivityIcon size="sm" level={accessRequest.sensitivity?.level ?? ''} />
            <Badge className="tr-flex tr-items-center  tr-text-black cursor tr-ml-1" variant={'light'}>
              <Icon type="time" size="sm" className="tr-mr-1" />
              {formatDateFromNow(accessRequest.createdAt)}
            </Badge>
          </div>
        </div>
      )}
    </div>
  );
};

const details = ({accessRequest}: {accessRequest: Task}) => {
  const forUsers =
    _.size(accessRequest.accumUser) > 1
      ? accessRequest.accumUser
      : accessRequest.forUser
      ? [accessRequest.forUser]
      : [];

  const {forPermission, forResource} = accessRequest;

  const durationValue = accessRequest.params?.durationValue;
  const durationUnit = accessRequest.params?.durationUnit;

  return (
    <div className="tr-flex tr-mt-3 tr-border-0 tr-p-1 tr-border-gray-200 tr-border-t-[1px] tr-border-b-[1px] tr-border-solid">
      <div key={`access-request-${accessRequest.id}`} className="tr-w-full tr-px-1 tr-py-3">
        <span className="tr-text-xs tr-font-bold">Principal User(s):</span>
        {forUsers.map((user) => (
          <UserAvatarAndName key={`access-request-user-${user.id}`} size="xs" displayName={formatUserName(user)} />
        ))}
        {accessRequest.message && <div className="tr-text-xs tr-italic tr-py-w">{`"${accessRequest.message}"`}</div>}
        <div className="tr-text-xs tr-font-bold tr-py-3">
          Duration: {durationValue ?? accessRequest.durationValue ?? accessRequest.accessDurationValue}{' '}
          {durationUnit ?? accessRequest.durationUnit ?? accessRequest.accessDurationUnit}s
        </div>
      </div>
      <div key={`access-request-access-to-${accessRequest.id}`} className="tr-w-full tr-px-1 tr-py-2">
        <span className="tr-text-xs tr-font-bold">Access To:</span>

        {accessRequest.forSystem && (
          <Link
            to={`/resource/manage/${forResource?.rootSid}/resources?Opened=${[
              forResource?.id,
              forResource?.rootSid,
              forPermission?.id,
            ].join(',')}`}
            className={` tr-px-1 tr-text-xs tr-flex-align tr-flex tr-flex-row tr-items-center `}
          >
            <img src={accessRequest.forSystem?.icon} height="20px" className="tr-mb-1 tr-mw-50" />
            <span className="tr-px-1" title={accessRequest.forSystem?.name}>
              {_.truncate(accessRequest.forSystem?.name, {length: 15})}
            </span>
            <span>{`>`}</span>
            {accessRequest.forSystem.id !== accessRequest.forResource?.id && (
              <>
                <span className="tr-px-1" title={accessRequest.forResource?.name}>
                  {_.truncate(accessRequest.forResource?.name, {length: 15})}
                </span>
                <span>{`>`}</span>
              </>
            )}
            <span className="tr-px-1" title={accessRequest.forPermission?.label}>
              {_.truncate(accessRequest.forPermission?.label, {length: 15})}
            </span>
          </Link>
        )}
      </div>
    </div>
  );
};

const confirmationApprovalText = ({
  accessRequest,
  selectedAction,
}: {
  accessRequest: Task;
  selectedAction?: TaskActionsEnum;
  values?: any;
}): React.ReactElement => {
  const {forPermission} = accessRequest;

  return (
    <div>
      {`Do you want to ${selectedAction} access request to `}
      <span className="body4">{forPermission!.label} </span>?
    </div>
  );
};

const approvalFormConfig = ({accessRequest, isAllowedToAct}: {accessRequest: Task; isAllowedToAct: boolean}) => {
  const sensitivity = accessRequest.sensitivity;
  return {
    validationSchema: object().shape({
      comment: !isAllowedToAct ? string().max(150).required() : string().max(150),
    }),
    initialValues: {
      comment: '',
      duration: {
        durationValue: accessRequest.durationValue || sensitivity?.maxApprovalDurationValue,
        durationUnit: accessRequest.durationUnit || sensitivity?.maxApprovalDurationUnit,
      },
    },
    fields: (
      <div className="tr-my-2">
        <Field
          component={DurationInput}
          label="Approval Duration"
          description="Time until re-approval is required"
          name="duration"
          required
        />
        <Field
          component={TextAreaInput}
          name="comment"
          label="Comment"
          className="tr-mt-4"
          data-testid="comment-textarea"
          description={isAllowedToAct ? '' : "Comment is mandatory since you weren't originally assigned to this task"}
          placeholder={'Add comment...'}
        />
      </div>
    ),
  };
};

const taskTypeSchemaEnum: TaskTypesDefinition = {
  [TicketType.WF_MANAGER_APPROVAL]: {
    heading,
    details,
    headingTitle: RequestorHeading,
    text: ApprovalHeading,
    confirmation: confirmationApprovalText,
    getActions: (accessRequest: Task): actionButtonType[] => {
      const disabled =
        accessRequest.processing ||
        accessRequest.processed ||
        accessRequest.connector?.disabled ||
        accessRequest.targetUser?.status === UserStatus.OFFBOARDED;
      return [
        // {action: TaskActionsEnum.CANCEL, label: 'Cancel'},
        {action: TaskActionsEnum.DENY, label: 'Deny', disabled, type: 'submit'},
        {
          action: TaskActionsEnum.APPROVE,
          label: 'Approve',
          type: 'submit',
          disabled,
        },
      ];
    },
    getForm: approvalFormConfig,
  },
  [TicketType.WF_MGR_RENEWAL]: {
    headingTitle: ({accessRequest}) => {
      const {forPermission, forUserAccount} = accessRequest;

      return (
        <div>
          <div className="tr-text-sm">
            <span className="tr-font-bold">{forUserAccount?.account}</span> access to
            <span className="tr-font-bold"> {forPermission?.label}</span> is expiring, and you have the option to decide
            if renew the access, or continue deprovisioning.
          </div>
        </div>
      );
    },
    heading,
    details,
    confirmation: ({
      selectedAction,
    }: {
      accessRequest: Task;
      selectedAction?: TaskActionsEnum;
      values?: any;
    }): React.ReactElement => {
      const messageMap: _.Dictionary<string> = {
        complete: `Are you sure you want to continue deprovisioning?`,
        cancel: `Are you sure ?`,
        renew: `Are you sure you want to renew access for ${name}?`,
      };

      return <div>{messageMap[selectedAction!]}</div>;
    },
    text: (accessRequest: Task) => {
      const {forPermission, forUserAccount} = accessRequest;
      return (
        <div>
          <div className="tr-text-sm">
            <span className="tr-font-bold">{forUserAccount?.account}</span> access to
            <span className="tr-font-bold"> {forPermission?.label}</span> is expiring, and you have the option to decide
            if renew the access, or continue deprovisioning.
          </div>
        </div>
      );
    },
    getActions: (accessRequest: Task): actionButtonType[] => {
      const disabled = accessRequest.processing || accessRequest.processed || accessRequest.connector?.disabled;

      const buttons: actionButtonType[] = [];

      if (accessRequest.provisionDetails?.provisionMode !== ProvisionOptions.off) {
        buttons.push(
          {
            action: TaskActionsEnum.RENEW,
            label: 'Renew',
            disabled,
            type: 'submit',
          },

          {
            action: TaskActionsEnum.DEPROVISION,
            label: 'Continue Deprovisioning',
            type: 'submit',
            disabled,
          }
        );
      }
      return buttons;
    },
    getForm: ({accessRequest, isAllowedToAct}: {accessRequest: Task; isAllowedToAct: boolean}) => {
      const accessRequestSensitivity = accessRequest.sensitivity;
      return {
        validationSchema: object().shape({
          duration: object().test(
            'approval duration allowed by sensitivity',
            ({durationUnit: unit, durationValue: value}, {createError}) => {
              const sensitivity: MinSensitivitySettingT = accessRequestSensitivity!;
              const {maxApprovalDurationUnit: maxUnit, maxApprovalDurationValue: maxValue} = sensitivity;
              const maxAllowedDurationInHours = (maxUnit === 'month' ? 720 : maxUnit === 'day' ? 24 : 1) * maxValue;
              const durationInHours = (unit === 'month' ? 720 : unit === 'day' ? 24 : 1) * (value ?? 0);

              if (durationInHours <= 0) {
                return createError({message: `Duration value must be greater than 0`});
              }
              if (durationInHours > maxAllowedDurationInHours) {
                return createError({
                  message: `Max duration: ${maxValue} ${maxUnit}(s). Limited by the sensitivity definition at organization level.`,
                });
              }
              return true;
            }
          ),
          comment: !isAllowedToAct ? string().max(150).required() : string().max(150),
        }),
        initialValues: {
          comment: '',
          duration: {
            durationUnit:
              accessRequest.durationUnit ??
              accessRequest.accessDurationUnit ??
              accessRequestSensitivity?.maxAccessDurationUnit,
            durationValue:
              accessRequest.durationValue ??
              accessRequest.accessDurationValue ??
              accessRequestSensitivity?.maxAccessDurationValue,
          },
        },
        fields: (
          <div className="tr-m-2">
            <Field
              component={DurationInput}
              label="Renew Access Duration"
              description="How much time should we extend access for this user?."
              name="duration"
              required
            />
            <Field
              component={TextAreaInput}
              name="comment"
              label="Comment"
              className="tr-mt-4"
              data-testid="comment-textarea"
              description={
                isAllowedToAct ? '' : "Comment is mandatory since you weren't originally assigned to this task"
              }
              placeholder={'Add comment...'}
            />
          </div>
        ),
      };
    },
  },
  [TicketType.WF_OWNER_APPROVAL]: {
    heading,
    headingTitle: RequestorHeading,
    details,
    text: ApprovalHeading,
    confirmation: confirmationApprovalText,
    getForm: approvalFormConfig,
    getActions: (accessRequest: Task): actionButtonType[] => {
      const disabled =
        accessRequest.processing ||
        accessRequest.processed ||
        accessRequest.connector?.disabled ||
        accessRequest.targetUser?.status === UserStatus.OFFBOARDED;
      return [
        // {action: TaskActionsEnum.CANCEL, label: 'Cancel'},
        {action: TaskActionsEnum.DENY, label: 'Deny', disabled, type: 'submit'},
        {
          action: TaskActionsEnum.APPROVE,
          label: 'Approve',
          type: 'submit',
          disabled,
        },
      ];
    },
  },
  [TicketType.WF_PROVISION]: {
    heading,
    headingTitle: RequestorHeading,
    details,
    getForm: ({accessRequest, isAllowedToAct}: {accessRequest: Task; isAllowedToAct: boolean}) => {
      const {forUserAccount} = accessRequest;

      const accounts = (accessRequest.system?.activeAccounts ?? []).map(({account, forUser, id}) => ({
        id,
        value: account,
        label: `${account} ${forUser ? `(${forUser.email})` : `(${AccountTabTypeE.UNASSIGNED})`}`,
      }));

      const defaultAccount =
        !forUserAccount?.placeholder && forUserAccount?.account
          ? forUserAccount?.account
          : _.first(accounts)
          ? _.first(accounts)?.value
          : undefined;

      return {
        validationSchema: object().shape({
          comment: !isAllowedToAct ? string().max(150).required() : string().max(150),
        }),
        initialValues: {
          comment: '',
          accountName: defaultAccount ? defaultAccount : '',
          accessDuration: {
            durationValue: accessRequest.accessDurationValue || '',
            durationUnit: accessRequest.accessDurationUnit || '',
          },
        },
        fields: (
          <div className="tr-my-4">
            <Field component={Select} name="accountName" label="Account Name" options={accounts} />
            <Field
              component={DurationInput}
              className="tr-mt-4"
              label="Access Duration"
              description="This is the amount of time a user will be provisioned for."
              name="accessDuration"
            />
            <Field
              component={TextAreaInput}
              name="comment"
              label="Comment"
              className="tr-mt-4"
              description={
                isAllowedToAct ? '' : "Comment is mandatory since you weren't originally assigned to this task"
              }
              data-testid="comment-textarea"
              placeholder={'Add comment...'}
            />
          </div>
        ),
      };
    },
    confirmation: ({
      selectedAction,
    }: {
      accessRequest: Task;
      selectedAction?: TaskActionsEnum;
      values?: any;
    }): React.ReactElement => {
      const messageMap: _.Dictionary<string> = {
        provision: `Do you confirm provision action?`,
        cancel: `Are you sure you want to cancel this task?`,
        deny: `Are you sure you want deny this task?`,
      };

      return <div>{messageMap[selectedAction!]}</div>;
    },
    text: (accessRequest: Task, setShowHistory?: (view: boolean) => void) => {
      const {forResource} = accessRequest;
      const provisionConfig = getProvisionTaskConfiguration(accessRequest);
      const featureFlagViewer = useFeatureFlags();
      const enabledTaskExpiration = featureFlagViewer.isEnabled('enable_expiration_days_for_tasks');
      return (
        <div className="tr-text-sm">
          <RequestorHeading accessRequest={accessRequest} />
          {forResource!.provisionInstructions && (
            <div>
              <p>Provision instructions:</p>
              <blockquote className="blockquote">{forResource!.provisionInstructions}</blockquote>
            </div>
          )}
          <span className="tr-text-sm tr-text-gray-600">{provisionConfig.description}</span>
          {provisionConfig.info && (
            <Tooltip
              className="tr-text-xs"
              content={
                <>
                  {provisionConfig.info},
                  {provisionConfig.infoInHistory && setShowHistory ? (
                    <>
                      If you need more details please
                      <strong
                        className="tr-cursor-pointer"
                        onClick={() => {
                          setShowHistory(true);
                        }}
                      >
                        {` open `}
                      </strong>
                      the history below.
                    </>
                  ) : (
                    <></>
                  )}
                </>
              }
            >
              <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
            </Tooltip>
          )}
          {enabledTaskExpiration && (
            <div className="font-weight-lighter">
              This access request will be implicitly denied if tasks asignees cancel, or take no action on it within 7
              days
            </div>
          )}
        </div>
      );
    },
    getActions: (accessRequest: Task): actionButtonType[] => {
      const provisionConfig = getProvisionTaskConfiguration(accessRequest);
      const disabled =
        accessRequest.processing ||
        accessRequest.processed ||
        accessRequest.connector?.disabled ||
        accessRequest.targetUser?.status === UserStatus.OFFBOARDED;

      const buttons: actionButtonType[] = [
        // {action: TaskActionsEnum.CANCEL, label: 'Cancel'}
      ];

      if (accessRequest.provisionDetails?.provisionMode !== ProvisionOptions.off) {
        buttons.push(
          {action: TaskActionsEnum.DENY, label: 'Deny', disabled, type: 'submit'},
          {
            action: TaskActionsEnum.PROVISION,
            label: provisionConfig.completeLabel,
            type: 'submit',
            disabled,
          }
        );
      }
      return buttons;
    },
  },
  [TicketType.WF_DEPROVISION]: {
    headingTitle: ({accessRequest}) => {
      const {forPermission, forUser, forUserAccount, startedBy} = accessRequest;
      const wasReapprovalRequest = /Reapproval request.*denied/.test(accessRequest.message ?? '');
      const username = formatUserNameAndEmail(forUser);
      if (wasReapprovalRequest) {
        return (
          <span className="tr-text-sm">
            <b>{accessRequest.message}</b>
          </span>
        );
      }

      if (accessRequest.isApprovalExpired) {
        return (
          <span className="tr-text-sm">
            <span className="tr-font-bold">{username} </span> {forUserAccount?.account}
            {` least-privilege access to `}
            <span className="tr-font-bold">{forPermission!.label}</span> {` has expired. `}
          </span>
        );
      }

      if (accessRequest.isAccessExpired) {
        return (
          <span className="tr-text-sm">
            <span className="tr-font-bold">{username} </span>
            {` least-privilege access to `}
            <span className="tr-font-bold">{forPermission!.label}</span> {` has expired. `}
            {` Because manager approval to this permission has not yet expired, you have the option to either extend
              their, or remove their access to this permission.`}
          </span>
        );
      }

      if (accessRequest.wasManualRequest) {
        return (
          <span className="tr-text-sm">
            <span className="tr-font-bold">{username} </span>
            {` access to `}
            <span className="tr-font-bold">{forPermission!.label}</span> {` has been revoked by `}
            <span className="tr-font-bold">{!_.isEmpty(startedBy) ? formatUserNameAndEmail(startedBy) : 'System'}</span>
            {` This request was initiated manually. Access is still approved and unexpired.`}
          </span>
        );
      }

      return (
        <span className="tr-text-sm">
          <span className="tr-font-bold">{username} </span>
          {` access to `}
          <span className="tr-font-bold">{forPermission!.label}</span> {` has been revoked by `}
          <span className="tr-font-bold">{!_.isEmpty(startedBy) ? formatUserNameAndEmail(startedBy) : 'System'}</span>
        </span>
      );
    },
    heading,
    details,
    confirmation: ({
      selectedAction,
    }: {
      accessRequest: Task;
      selectedAction?: TaskActionsEnum;
      values?: any;
    }): React.ReactElement => {
      const messageMap: _.Dictionary<string> = {
        complete: `Are you sure you want to mark this deprovision task as complete?`,
        cancel: `Are you sure you want to cancel deprovisioning for ${name}?`,
        reapprove: `Are you sure you want to request reapproval for ${name}?`,
        extend: `Are you sure you want to extend access for ${name}?`,
      };

      return <div>{messageMap[selectedAction!]}</div>;
    },
    text: (accessRequest: Task, setShowHistory?: (view: boolean) => void) => {
      const {forResource, forPermission, forUser, forUserAccount, startedBy} = accessRequest;
      const deprovisionConfig = getDeprovisionTaskConfiguration(accessRequest);
      const wasReapprovalRequest = /Reapproval request.*denied/.test(accessRequest.message ?? '');
      const username = formatUserNameAndEmail(forUser);
      const extraRequestInformation = (() => {
        if (wasReapprovalRequest) {
          return (
            <span>
              <b>{accessRequest.message}</b>
            </span>
          );
        }

        if (accessRequest.isApprovalExpired) {
          return (
            <span>
              <span className="tr-font-bold">{username} </span> {forUserAccount?.account}
              {` least-privilege access to `}
              <span className="tr-font-bold">{forPermission!.label}</span> {` has expired. `}
            </span>
          );
        }

        if (accessRequest.isAccessExpired) {
          return (
            <span>
              <span className="tr-font-bold">{username} </span>
              {` least-privilege access to `}
              <span className="tr-font-bold">{forPermission!.label}</span> {` has expired. `}
              {` Because manager approval to this permission has not yet expired, you have the option to either extend
              their, or remove their access to this permission.`}
            </span>
          );
        }

        if (accessRequest.wasManualRequest) {
          return (
            <span>
              <span className="tr-font-bold">{username} </span>
              {` access to `}
              <span className="tr-font-bold">{forPermission!.label}</span> {` has been revoked by `}
              <span className="tr-font-bold">
                {!_.isEmpty(startedBy) ? formatUserNameAndEmail(startedBy) : 'System'}
              </span>
              {` This request was initiated manually. Access is still approved and unexpired.`}
            </span>
          );
        }

        return (
          <span>
            <span className="tr-font-bold">{username} </span>
            {` access to `}
            <span className="tr-font-bold">{forPermission!.label}</span> {` has been revoked by `}
            <span className="tr-font-bold">{!_.isEmpty(startedBy) ? formatUserNameAndEmail(startedBy) : 'System'}</span>
          </span>
        );
      })();

      return (
        <div>
          <div className="tr-text-sm">
            {extraRequestInformation}
            {forResource!.provisionInstructions && (
              <div>
                <p>Provision instructions:</p>
                <blockquote className="blockquote">{forResource!.provisionInstructions}</blockquote>
              </div>
            )}
            <div className="grayed-out">{deprovisionConfig.description}</div>
            {deprovisionConfig.info && (
              <Tooltip
                className="tr-text-xs"
                content={
                  <>
                    {deprovisionConfig.info},
                    {deprovisionConfig.infoInHistory && setShowHistory ? (
                      <>
                        If you need more details please
                        <strong
                          className="tr-cursor-pointer"
                          onClick={() => {
                            setShowHistory(true);
                          }}
                        >
                          {` open `}
                        </strong>
                        the history below.
                      </>
                    ) : (
                      <></>
                    )}{' '}
                  </>
                }
              >
                <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
    getActions: (accessRequest: Task): actionButtonType[] => {
      const deprovisionConfig = getDeprovisionTaskConfiguration(accessRequest);
      const disabled = accessRequest.processing || accessRequest.processed || accessRequest.connector?.disabled;
      const wasReapprovalRequest = /Reapproval request.*denied/.test(accessRequest.message ?? '');

      const buttons: actionButtonType[] = [];

      if (accessRequest.provisionDetails?.provisionMode !== ProvisionOptions.off) {
        buttons.push(
          {action: TaskActionsEnum.DENY, label: 'Deny', type: 'submit'},

          {
            action: TaskActionsEnum.REAPPROVE,
            label: 'Reapprove',
            disabled,
            type: 'submit',
            hide:
              (!wasReapprovalRequest && accessRequest.isApprovalExpired) ||
              accessRequest.targetUser?.status === UserStatus.OFFBOARDED,
          },
          {
            action: TaskActionsEnum.EXTEND_ACCESS,
            label: 'Extend Access',
            disabled,
            type: 'submit',
            hide:
              (!accessRequest.isApprovalExpired && accessRequest.isAccessExpired) ||
              accessRequest.targetUser?.status === UserStatus.OFFBOARDED,
          },
          {
            action: TaskActionsEnum.DEPROVISION,
            label: deprovisionConfig.completeLabel,
            type: 'submit',
            disabled,
            hide: !wasReapprovalRequest && accessRequest.isApprovalExpired,
          }
        );
      }
      return buttons;
    },
    getForm: ({accessRequest, isAllowedToAct}: {accessRequest: Task; isAllowedToAct: boolean}) => {
      const accessRequestSensitivity = accessRequest.sensitivity;
      return {
        validationSchema: object().shape({
          comment: !isAllowedToAct ? string().max(150).required() : string().max(150),
        }),
        initialValues: {
          comment: '',
          duration: {
            durationUnit: accessRequestSensitivity?.maxAccessDurationUnit ?? '',
            durationValue: accessRequestSensitivity?.maxAccessDurationValue ?? '',
          },
        },
        fields: (
          <div className="tr-m-2">
            {!accessRequest.isApprovalExpired && accessRequest.isAccessExpired && (
              <Field
                component={DurationInput}
                label="Extend Access Duration"
                description="How much time should we extend access for this user?."
                name="duration"
                required
              />
            )}
            <Field
              component={TextAreaInput}
              name="comment"
              label="Comment"
              className="tr-mt-4"
              data-testid="comment-textarea"
              description={
                isAllowedToAct ? '' : "Comment is mandatory since you weren't originally assigned to this task"
              }
              placeholder={'Add comment...'}
            />
          </div>
        ),
      };
    },
  },
  [TicketType.WF_DESIGNATE_APPROVER]: {
    heading,
    headingTitle: RequestorHeading,
    details,
    confirmation: confirmationApprovalText,
    text: ApprovalHeading,
    getForm: approvalFormConfig,
    getActions: (accessRequest: Task): actionButtonType[] => {
      const disabled =
        accessRequest.processing ||
        accessRequest.processed ||
        accessRequest.connector?.disabled ||
        accessRequest.targetUser?.status === UserStatus.OFFBOARDED;
      return [
        // {action: TaskActionsEnum.CANCEL, label: 'Cancel'},
        {action: TaskActionsEnum.DENY, label: 'Deny', disabled, type: 'submit'},
        {
          action: TaskActionsEnum.APPROVE,
          label: 'Approve',
          type: 'submit',
          disabled,
        },
      ];
    },
  },
  [TicketType.WF_EXECUTIVE_APPROVAL]: {
    heading,
    confirmation: confirmationApprovalText,
    details,
    headingTitle: RequestorHeading,
    getForm: approvalFormConfig,
    text: ApprovalHeading,
    getActions: (accessRequest: Task): actionButtonType[] => {
      const disabled =
        accessRequest.processing ||
        accessRequest.processed ||
        accessRequest.connector?.disabled ||
        accessRequest.targetUser?.status === UserStatus.OFFBOARDED;
      return [
        // {action: TaskActionsEnum.CANCEL, label: 'Cancel'},
        {action: TaskActionsEnum.DENY, label: 'Deny', disabled, type: 'submit'},
        {
          action: TaskActionsEnum.APPROVE,
          label: 'Approve',
          type: 'submit',
          disabled,
        },
      ];
    },
  },
  [TicketType.DIRECTED_ACCESS_REQUEST]: {
    heading,
    headingTitle: RequestorHeading,
    confirmation: confirmationApprovalText,
    details,
    getForm: approvalFormConfig,
    text: ApprovalHeading,
    getActions: (accessRequest: Task): actionButtonType[] => {
      const disabled =
        accessRequest.processing ||
        accessRequest.processed ||
        accessRequest.connector?.disabled ||
        accessRequest.targetUser?.status === UserStatus.OFFBOARDED;
      return [
        // {action: TaskActionsEnum.CANCEL, label: 'Cancel'},
        {action: TaskActionsEnum.DENY, label: 'Deny', disabled, type: 'submit'},
        {
          action: TaskActionsEnum.APPROVE,
          label: 'Approve',
          type: 'submit',
          disabled,
        },
      ];
    },
  },
  [TicketType.WF_LINK_USER]: {
    heading,
    headingTitle: RequestorHeading,
    confirmation: ({
      accessRequest,
      selectedAction,
      values,
    }: {
      accessRequest: Task;
      selectedAction?: TaskActionsEnum;
      values?: any;
    }): React.ReactElement => {
      const {forPermission} = accessRequest;
      const {accountName} = values;

      return (
        <div>
          {selectedAction === TaskActionsEnum.APPROVE
            ? `Do you want proceed linking ${accountName} in order to give access to `
            : 'By canceling this task you´ll be canceling the access request to '}
          <span className="body4">{forPermission!.label} </span>?
        </div>
      );
    },
    details,
    getForm: ({accessRequest, isAllowedToAct}: {accessRequest: Task; isAllowedToAct: boolean}) => {
      const {forSystem, forUser} = accessRequest;
      const forUserName = formatUserName(forUser);

      const accountList = accessRequest.system.accounts ?? [];
      const accounts = accountList
        .filter((a) => {
          return !a.refId && !a.isOffboarded && !a.isUserOffboarded;
        })
        .map(({account, forUser, id}) => ({
          id,
          value: account,
          label: `${account} ${forUser ? `(${forUser.email})` : `(${AccountTabTypeE.UNASSIGNED})`}`,
        }));
      return {
        validationSchema: object().shape({
          comment: !isAllowedToAct ? string().max(150).required() : string().max(150),
        }),
        initialValues: {
          comment: '',
          duration: {durationValue: accessRequest.durationValue!, durationUnit: accessRequest.durationUnit!},
        },
        fields: (
          <div className="tr-my-2" data-cy={`static-select-account`}>
            <Field
              component={Select}
              name="accountName"
              label="Account Name"
              description={`Select ${forUserName}'s corresponding account on ${
                forSystem!.name
              } in the below dropdown, and click Link. If an account for ${forUserName} does not already exist on ${
                forSystem!.name
              }, cancel this request, add an account for ${forUserName} to ${
                forSystem!.name
              }, and ask ${forUserName} to try again.`}
              options={accounts}
            />
            <Field
              component={TextAreaInput}
              name="comment"
              label="Comment"
              className="tr-mt-4"
              data-testid="comment-textarea"
              description={
                isAllowedToAct ? '' : "Comment is mandatory since you weren't originally assigned to this task"
              }
              placeholder={'Add comment...'}
            />
          </div>
        ),
      };
    },
    text: (accessRequest: Task) => {
      const {forResource, forPermission, forUser, forSystem} = accessRequest;
      const featureFlagViewer = useFeatureFlags();
      const enabledTaskExpiration = featureFlagViewer.isEnabled('enable_expiration_days_for_tasks');
      const forUserNameAndEmail = formatUserNameAndEmail(forUser);
      const forUserName = formatUserName(forUser);

      return (
        <div className="tr-text-sm tr-ml-2">
          <span className="">{`${forUserNameAndEmail} `}</span>
          <span className="font-weight-lighter">
            {`has requested `} {/*todo: For principal_user */}
            <span className="tr-font-bold">{forPermission!.label}</span> {` access to `}
          </span>
          <Link
            to={`/resource/manage/${forResource?.rootSid}/resources?Opened=${[
              forResource?.id,
              forResource?.rootSid,
              forPermission?.id,
            ].join(',')}`}
          >
            <strong>
              {forSystem!.name} - {forResource!.name}
            </strong>
          </Link>
          <span className="font-weight-lighter">
            {` for ${accessRequest.durationValue} ${fixPlurality(
              accessRequest.durationValue!,
              accessRequest.durationUnit!
            )}.`}
          </span>
          <div>
            {`Because ${forUserName}'s account on ${
              forSystem!.name
            } has not yet been linked with the ${forUserName} Trustle User, Trustle doesn't know which account on `}
            <span className="tr-font-bold">{forSystem!.name}</span>{' '}
            {`
             should be given `}
            <span className="tr-font-bold">{forResource!.name}</span>
            {` access. `}
          </div>
          <div>
            {`To remedy this, you must first link ${forUserName}´s Trustle User to their associated account on `}
            <span className="tr-font-bold">{forSystem!.name}</span>
            {`.`}
          </div>
          <div>
            {`As Owner of `} <span className="tr-font-bold">{forSystem!.name}</span>
            {`, you must perform this linking.`}
            {enabledTaskExpiration && (
              <div className="font-weight-lighter">
                This access request will be implicitly denied if tasks asignees cancel, or take no action on it within 7
                days
              </div>
            )}
          </div>
        </div>
      );
    },
    getActions: (accessRequest: Task): actionButtonType[] => {
      const disabled =
        accessRequest.processing ||
        accessRequest.processed ||
        accessRequest.connector?.disabled ||
        accessRequest.targetUser?.status === UserStatus.OFFBOARDED;
      return [
        // {action: TaskActionsEnum.CANCEL, label: 'Cancel'},
        {action: TaskActionsEnum.DENY, label: 'Deny', disabled, type: 'submit'},
        {
          action: TaskActionsEnum.APPROVE,
          label: 'Link',
          type: 'submit',
          disabled,
        },
      ];
    },
  },
  [AccessRequestType.GENERIC]: {
    heading,
    details,
    headingTitle: ({accessRequest}: {accessRequest: Task}) => {
      const {forUser} = accessRequest;
      const forUserName = formatUserNameAndEmail(forUser);

      return (
        <div className="tr-text-sm">
          <span className="tr-font-bold">{`${forUserName}`}</span>
          {` has requested access to the following:`}
          <span className="tr-italic">{`"${accessRequest.message}"`}</span>
        </div>
      );
    },
    text: (accessRequest: Task) => {
      const forUserName = formatUserName(accessRequest.forUser);
      return (
        <>
          <div className=" tr-mt-1">
            <Tooltip content={forUserName}>
              <UserAvatar title={forUserName} text={forUserName} size="sm" />
            </Tooltip>
          </div>
          <div className="tr-text-sm tr-ml-3">
            <span className="tr-font-bold">{`${forUserName}`}</span>
            {` has requested access to the following, presumably because they weren't able to find it as an available access option:`}
            <span className="tr-italic">{`"${accessRequest.message}"`}</span>
            <div>
              {` If you know the existing available access `} <span className="tr-font-bold">{forUserName}</span>
              {` is looking for, please select and submit for it
              below. Otherwise, please Deny the request and chat with  `}
              <span className="tr-font-bold">{forUserName}</span> {`for additional details.`}
            </div>
          </div>
        </>
      );
    },
    confirmation: ({
      selectedAction,
    }: {
      accessRequest: Task;
      selectedAction?: TaskActionsEnum;
      values?: any;
    }): React.ReactElement => {
      return <div>{`Do you want to ${selectedAction} the request?`}</div>;
    },
    getActions: (accessRequest: Task): actionButtonType[] => {
      const disabled =
        accessRequest.processing ||
        accessRequest.processed ||
        accessRequest.connector?.disabled ||
        accessRequest.targetUser?.status === UserStatus.OFFBOARDED;
      return [
        // {action: TaskActionsEnum.CANCEL, label: 'Cancel'},
        {action: TaskActionsEnum.REASSIGN, label: 'Reassign'},
        {action: TaskActionsEnum.DENY, label: 'Deny', ignoreValidity: true, disabled, type: 'submit'},
        {
          action: TaskActionsEnum.APPROVE,
          label: 'Approve',
          type: 'submit',
          disabled,
        },
      ];
    },
  },
};

export {taskTypeSchemaEnum};
