import React, {PropsWithChildren} from 'react';

type Props = PropsWithChildren<{
  src?: string;
  className?: string;
  text: string;
  description?: string;
  status: keyof typeof TABLE_STATUS_TO_RUSSEL;
}>;

const TABLE_STATUS_TO_RUSSEL = {
  'no-data': '/russel-confused.svg',
  loading: '/russel-thinking.svg',
  error: '/russel-confused.svg',
  searching: '/russel-magnifying-glass.svg',
};

export const DataStatus = ({children, src, text, description, status}: Props) => {
  const imageSrc = src || TABLE_STATUS_TO_RUSSEL[status];

  return (
    <div className="tr-text-center">
      <img src={imageSrc} className="my-4 mx-auto" alt="russel image" width={250} />
      {text && <h3>{text}</h3>}
      {description && <p className="tr-w-5/12 tr-text-center mx-auto">{description}</p>}
      {children}
    </div>
  );
};
