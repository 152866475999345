import {Search} from 'src/components/Search/Search';
import {DateRangePicker, FocusedInputShape} from 'react-dates';
import ReportDropdown from 'src/views/admin/reports/ReportDropdown';
import moment from 'moment';
import React, {Dispatch, useState} from 'react';
import {Button} from '@trustle/component-library';

type PropsT = {
  reportUrl: string;
  queryParams?: URLSearchParams;
  setQuery: Dispatch<React.SetStateAction<string>>;
  setFilters?: (newValues: Record<string, string | undefined>) => void;
  filterOptions?: Record<string, (string | undefined)[]>;
};

export const SearchAndReportToggle = (props: PropsT) => {
  const {queryParams = new URLSearchParams(), reportUrl, setQuery, setFilters = () => {}, filterOptions = {}} = props;

  const [from, setFrom] = useState<moment.Moment | null>(null);
  const [to, setTo] = useState<moment.Moment | null>(null);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  if (from) {
    queryParams.set('from', from.format('MM/DD/YYYY'));
  }

  if (to) {
    queryParams.set('to', to.format('MM/DD/YYYY'));
  }

  function isOutsideRange(day: moment.Moment): boolean {
    return day.isAfter(moment());
  }

  const handleDatesChange = ({startDate, endDate}: any) => {
    setFrom(startDate);
    setTo(endDate);
  };

  return (
    <div className="mb-4 tr-flex align-items-center tr-justify-between tr-w-full search-report-toggle">
      {!generatingReport && (
        <Search
          placeholder="Search"
          filterOptions={filterOptions}
          onChange={(query, filters) => {
            setQuery(query);
            setFilters(filters);
          }}
        />
      )}
      {generatingReport && (
        <div className="flex-align align-items-center">
          <span className="body5">{'Date range: '}</span>
          <div className="mx-4 tr-relative" style={{zIndex: 2}}>
            <DateRangePicker
              showClearDates
              showDefaultInputIcon
              startDate={from}
              endDate={to}
              startDateId="account-report-start-date"
              endDateId="account-report-end-date"
              inputIconPosition="after"
              isOutsideRange={isOutsideRange}
              onDatesChange={handleDatesChange}
              customArrowIcon={<>{'>>'}</>}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            />
          </div>
          <div className="generate-report-button-wrapper">
            <ReportDropdown
              title="Download Report"
              url={reportUrl}
              queryParams={queryParams}
              showPDF={false}
              showPreview={false}
            />
          </div>
        </div>
      )}
      <Button
        variant="primary"
        size="sm"
        onClick={() => setGeneratingReport(!generatingReport)}
        selected={generatingReport}
        label="Generate Report"
      />
    </div>
  );
};
