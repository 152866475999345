import {Button, Icon, Select, Tooltip} from '@trustle/component-library';
import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {useDebounce} from 'src/lib';
import {useRootStore} from 'src/lib/hooks';
import {AccountAccessType} from 'src/types';
import {OptionProps} from 'react-select';
import {OptionType} from 'src/components/design/tables/SharedColumnDefinitions/AccountColumns';
import {SelectItem} from 'src/views/v2/admin/admin-users-panel/types';
import {Account} from '../../../hooks/useAccounts';
import {useAccountsContext} from '../../../AccountsContext';
import {useLinkUser} from '../../../hooks/useLinkUser';
import {TrustleUserAndAccountTypeAction} from '../AccountsTable';
import {useAccountFilters} from '../../../AccountsFiltersContext';
import {useAccountsSummaryContext} from '../../../AccountsSummaryContext';
import {
  UserLightweightResponse,
  useUsersLightweight,
} from 'src/views/v2/admin/admin-users-panel/hooks/useUsersLightweight';

type Props = {
  account: Account;
  systemId: string;
  // dispatch fn of `useReducer` to reflect dropdown state outside of this component
  dispatch: React.Dispatch<TrustleUserAndAccountTypeAction>;
};

const accountTypeOptions = [
  {
    label: (
      <Tooltip content="Personal Account" position="bottom">
        <Icon type="user" forcedSize={20} />
      </Tooltip>
    ),
    value: AccountAccessType.PERSONAL,
  },
  {
    label: (
      <Tooltip content="Service Account" position="bottom">
        <Icon type="service" forcedSize={20} />
      </Tooltip>
    ),
    value: AccountAccessType.SERVICE,
  },
];

export type SelectAccountTypeItem = {
  value: AccountAccessType;
  label: JSX.Element;
};

const CustomOption = ({innerProps, data}: OptionProps<OptionType, boolean>) => (
  <div {...innerProps} className={'mx-2 my-1 account-select-item'}>
    {data.label}
  </div>
);

// this component is meant to do a similar work as `frontend/src/views/Resource/Account/MapUser.tsx`
function TrustleUserDropDown({systemId, account, dispatch}: Props) {
  const {filters} = useAccountFilters();
  const {matchType} = filters;
  const [selectedUserOption, setSelectedUserOption] = useState<SelectItem | null>(
    matchType !== 'none' && account.users && account.users.length > 0
      ? {
          value: account.users[0]?.id,
          label: account.users[0]?.email,
        }
      : null
  );
  const [selectedAccountTypeOption, setSelectedAccountTypeOption] = useState<SelectAccountTypeItem | undefined>(
    _.find(accountTypeOptions, {value: account.accountType ?? AccountAccessType.PERSONAL})
  );
  const {data, fetchUsersLightweight} = useUsersLightweight();
  const {refetchAccounts} = useAccountsContext();
  const {refetchAccountsSummary} = useAccountsSummaryContext();
  const {toast} = useRootStore();

  const [options, setOptions] = useState<UserLightweightResponse[]>([]);
  const {linkUser} = useLinkUser(); // ToDo use `success` to show a message?

  // pre-populate trustle user drop down
  useEffect(() => {
    if (!data && account.users) {
      // if user didn't type anything yet,
      // then set the options to the Trustle Users that comes from `/principals` endpoint
      setOptions(account.users);
    } else if (data) {
      // once the user types something,
      // then set the options to the results
      // of such search
      setOptions(data.users);
    }
  }, [data]);

  const handleDebouncedSearch = useDebounce((value: string) => {
    void fetchUsersLightweight({page: 1, size: 20, nameOrEmail: value});
  }, 1000);

  // fn to handle click on "Link" button on a row
  async function handleOnClick(userId: string, accountId: string, systemId: string, accountType: AccountAccessType) {
    try {
      await linkUser({userId, accountId, systemId, accountType});
    } catch (err: any) {
      toast.error('There was an unexpected error linking the Trustle User.');
    } finally {
      void refetchAccounts();
      void refetchAccountsSummary();
    }
  }

  // side effect to set the initial state of the account-user and account-type correspondences
  useEffect(() => {
    if (selectedUserOption) {
      dispatch({type: 'setUser', accountId: account.principalId, userId: selectedUserOption.value});
    }

    if (selectedAccountTypeOption) {
      dispatch({type: 'setAccountType', accountId: account.principalId, accountType: selectedAccountTypeOption.value});
    }
  }, [selectedUserOption, selectedAccountTypeOption, dispatch]);

  const handleUserChange = (userOption: SelectItem | null) => {
    if (userOption) {
      // update current state of drop down
      setSelectedUserOption(userOption);
      // and also reflect this change back to components relying on this state (`AccountsTable.tsx` mainly)
      dispatch({type: 'setUser', accountId: account.principalId, userId: userOption.value});
    }
  };

  const handleAccountTypeChange = (accountTypeOption: SelectAccountTypeItem | null) => {
    if (accountTypeOption) {
      // same explanation as in `handleUserChange` applies here,
      // but this time for the account type
      setSelectedAccountTypeOption(accountTypeOption);
      dispatch({type: 'setAccountType', accountId: account.principalId, accountType: accountTypeOption.value});
    }
  };

  return (
    <div className="tr-py-1 tr-flex tr-items-center tr-gap-2">
      <Select
        aria-label={'select-account-type'}
        options={accountTypeOptions}
        name="select-account-type"
        data-testid={`testselect-account-type}`}
        components={_.pickBy({Option: CustomOption}, _.identity)}
        menuPortalTarget={document.querySelector('body')}
        defaultValue={selectedAccountTypeOption}
        onChange={(accountTypeOption) => {
          handleAccountTypeChange(accountTypeOption);
        }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: '12px',
            paddingLeft: '4px',
            width: '70px',
          }),
        }}
      />
      <Select
        options={
          options.map((user) => ({
            value: user.id,
            label: user.email,
          })) || []
        }
        id={`account-map-${account.account}`}
        placeholder="Trustle user's name"
        onInputChange={(input) => {
          if (input) {
            handleDebouncedSearch(input);
          }
        }}
        onChange={(userOption) => {
          handleUserChange(userOption);
        }}
        menuPortalTarget={document.querySelector('body')}
        defaultValue={selectedUserOption}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: '12px',
            paddingLeft: '4px',
            width: '200px',
          }),
        }}
      />
      {!_.isEmpty(selectedUserOption) && !_.isEmpty(selectedAccountTypeOption) && (
        <Button
          style={{height: '2.4rem'}}
          variant="primary"
          id={`account-map-${account.account}-button`}
          key="complete"
          className={'tr-p-2 tr-ml-1'}
          onClick={() =>
            handleOnClick(selectedUserOption.value, account.principalId, systemId, selectedAccountTypeOption.value)
          }
        >
          <Icon type="linked" title="Link User" forcedSize={20} />
        </Button>
      )}
    </div>
  );
}

export default TrustleUserDropDown;
