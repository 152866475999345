import React, {useEffect, useMemo, useState} from 'react';
import {Button, IconButton} from '@trustle/component-library';
import {FiltersSection} from './internal/FiltersSection';
import {AddFiltersSection} from './internal/AddFiltersSection';
import {FilterToApply, SelectItem, UserFilterKey} from '../../types';
import {UserQueryFiltersAndPagination, useUserFilters} from '../../UserFiltersContext';
import _ from 'lodash';
import {useFiltersConfigMap} from '../../hooks/useUserFiltersConfigMap';
import {SaveFilterButtonModal} from './internal/SaveFilterButtonModal';
import {useSaveFilterView} from './internal/useSaveFilterView';
import {useRootStore} from '../../../../../../lib/hooks';
import {Divider} from 'src/views/v2/common/Divider';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export function FiltersSidePanel({isOpen, onClose}: Props) {
  const {filters, setFilters: setAppliedFilters} = useUserFilters();
  const {matchedFilterView, deleteFilterView} = useSaveFilterView();
  const {toast} = useRootStore();
  const [filtersToApply, setFiltersToApply] = useState<FilterToApply[]>([]);
  const FILTERS_CONFIG_MAP = useFiltersConfigMap();

  // Initialize filtersToApply
  useEffect(() => {
    const availableAdvancedFilters = _.omit(filters, ['groupBy', 'sort', 'page', 'size', 'nameOrEmail', 'orderBy']);
    const initialFilters = Object.entries(availableAdvancedFilters).map(([key, value]) => {
      const isMulti = FILTERS_CONFIG_MAP[key as UserFilterKey].isMulti;

      if (isMulti) {
        const filterValues = (value as string).split(',');
        return {
          key: key as UserFilterKey,
          value: filterValues.map((value) => ({
            value,
            label: '', // Label should be resolved based on filter options
          })),
        };
      }

      return {
        key: key as UserFilterKey,
        value: {
          value: value as string,
          label: '', // Label should be resolved based on filter options
        },
      };
    });

    setFiltersToApply(initialFilters);
  }, [filters, isOpen]);

  const handleAddFilter = (key: UserFilterKey) => {
    setFiltersToApply([
      ...filtersToApply,
      {
        key,
        value: undefined,
      },
    ]);
  };

  const handleRemoveFilter = (key: UserFilterKey) => {
    setFiltersToApply(filtersToApply.filter((filter) => filter.key !== key));
  };

  const toggleFilterSelection = (key: UserFilterKey) => {
    if (filtersToApply.map((filter) => filter.key).includes(key)) {
      handleRemoveFilter(key);
    } else {
      handleAddFilter(key);
    }
  };

  const updateFilters = (key: UserFilterKey, selected: SelectItem[] | SelectItem) => {
    const filterToUpdateIndex = filtersToApply.findIndex((filter) => filter.key === key);
    const newFilters = [...filtersToApply];

    newFilters[filterToUpdateIndex] = {
      key,
      value: selected,
    };

    setFiltersToApply(newFilters);
  };

  const filtersHaveChanged = useMemo(() => {
    const toCompare = convertToApplyToFilters(filtersToApply);
    return !_.isEqual(toCompare, _.omit(filters, ['groupBy', 'sort', 'page', 'size', 'nameOrEmail', 'orderBy']));
  }, [filtersToApply, filters]);

  const selectedViewFilter = useMemo(() => {
    return matchedFilterView(filtersToApply);
  }, [filtersToApply]);

  return (
    <>
      {/* Overlay */}
      {isOpen && <div className="tr-fixed tr-inset-0 tr-bg-black tr-bg-opacity-50 tr-z-50" onClick={onClose} />}

      {/* Drawer Panel */}
      <div
        className={`tr-fixed tr-top-0 tr-right-0 tr-h-full tr-bg-white tr-shadow-lg tr-z-50 tr-transform tr-transition-transform ${
          isOpen ? 'tr-translate-x-0' : 'tr-translate-x-full'
        }`}
      >
        <div className="tr-flex tr-justify-between tr-py-3 tr-px-3.5 tr-items-center tr-bg-[#F8F9FC]">
          <h2 className="tr-text-trustle-navy tr-mb-0">Advanced Filters</h2>
          <IconButton
            onClick={onClose}
            variant="ternary"
            icon="cancel"
            className="tr-text-black"
            ignoreDefaultColor={true}
          />
        </div>
        <Divider />
        <div className="tr-h-full">
          <div className="tr-flex tr-gap-4 tr-h-full">
            <div className="tr-flex tr-flex-col tr-bg-[#F1F3F9] tr-h-full">
              <div className="tr-flex tr-flex-col tr-w-[500px] tr-bg-[#F1F3F9] tr-py-3 tr-px-3.5 tr-max-h-[85%]">
                <FiltersSection
                  filters={filtersToApply}
                  setFilters={setFiltersToApply}
                  removeFilter={handleRemoveFilter}
                  updateFilters={updateFilters}
                />
                {!filtersToApply.length && (
                  <p className="tr-p-16 tr-text-center tr-text-md tr-font-normal">
                    This view doesn't have any advanced filters. Select a filter to begin.
                  </p>
                )}
                <div className="tr-flex tr-ml-auto tr-gap-1 tr-py-2">
                  {Boolean(filtersToApply.length) && (
                    <SaveFilterButtonModal filters={filtersToApply} setFilters={setFiltersToApply} />
                  )}
                  {selectedViewFilter && (
                    <Button
                      variant="primary"
                      colorVariant="danger"
                      onClick={() => {
                        deleteFilterView(selectedViewFilter?.name);
                        setFiltersToApply([]);
                        toast.success('Filter View deleted successfully');
                      }}
                    >
                      Delete Filter View
                    </Button>
                  )}
                  {filtersHaveChanged && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        const toApply = convertToApplyToFilters(filtersToApply);

                        const {page, size, groupBy} = filters;
                        setAppliedFilters({page, size, groupBy, ...toApply});
                      }}
                    >
                      Apply
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <AddFiltersSection
              toggleFilterSelection={toggleFilterSelection}
              filters={filtersToApply}
              setFilters={setFiltersToApply}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function convertToApplyToFilters(filtersToApply: FilterToApply[]) {
  return filtersToApply.reduce((acc, curr) => {
    const {key, value} = curr;

    if (!value || (_.isArray(value) && value.length === 0)) {
      return acc;
    }

    acc[key] = _.isArray(value) ? value.map((item) => item.value).join(',') : value.value;

    return acc;
  }, {} as UserQueryFiltersAndPagination);
}
